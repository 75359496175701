<template>
  <el-row>
    <el-col :span="8" :offset="1">
      <el-row>
        <el-radio-group v-model="projectType" size="small">
          <el-radio-button label="电解水制氢" value="电解水制氢" />
          <el-radio-button label="化石能源制氢" value="化石能源制氢" />
          <el-radio-button label="工业副产氢" value="工业副产氢" />
          <el-radio-button label="加氢站" value="加氢站" />
        </el-radio-group>
      </el-row>
      <el-col :span="24" style="height: 20px"></el-col>
      <div id="chinaChart" style="width: 300px; height: 300px"></div>
    </el-col>
    <el-col :span="13" :offset="1">
      <el-row class="littleContainer">
        <span style="width: 100%; font-size: larger; font-weight: 500">项目数(个)</span>
        <el-col :span="8">
          <el-statistic title="建设" value="2" />
        </el-col>
        <el-col :span="8">
          <el-statistic title="建成" value="2" />
        </el-col>
        <el-col :span="8">
          <el-statistic title="运营" value="3" />
        </el-col>
      </el-row>
      <el-col :span="24" style="height: 20px"></el-col>
      <el-row class="littleContainer">
        <span style="width: 100%; font-size: larger; font-weight: 500">供给能力（座）</span>
          <el-col :span="12">
            <el-statistic title="小于500公斤/天" value="1" />
          </el-col>
          <el-col :span="12">
            <el-statistic title="500-1000公斤/天" value="5" />
          </el-col>
      </el-row>
      <el-row style="margin-top: 3%">
        <el-col :span="8">
          <div class="pieChart" id="firstChart" style="height: 150px; width: 200px"></div>
        </el-col>
        <el-col :span="8">
          <div class="pieChart" id="secondChart" style="height: 150px; width: 200px"></div>
        </el-col>
        <el-col :span="8">
          <div class="pieChart" id="thirdChart" style="height: 150px; width: 200px"></div>
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="22" :offset="1">
      <el-table stripe :data="projectList" border max-height="400" style="width: 100%;font-size: 11px; ">
        <el-table-column align="center" label="序号" prop="companyId" width="50"/>
        <el-table-column align="center" label="项目名称" prop="companyName">
          <template #default="{ row }">
            <span class="blue-text">{{ row.companyName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="项目类型"
          prop="companyPrivilliageCount"
        />
        <el-table-column align="center" label="项目地址" prop="companyArea" />
      </el-table>
    </el-col>
    <el-col :span="24" style="height: 20px"></el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { get } from '@/utils/request';
import { ref, onMounted, getCurrentInstance } from 'vue';
let internalInstance = getCurrentInstance();

let echarts = internalInstance.appContext.config.globalProperties.$echarts;

const projectList = ref([
  {
    companyId: '1',
    companyName: '圣圆能源1000kg加氢站',
    companyPrivilliageCount: '固定站',
    companyArea: '具体位置4号104街道',
    mainCategory: 'xxxxxxxxx',
    subCategory: 'xxxxxxxxx',
  },
  {
    companyId: '2',
    companyName: '伊政新能源科技发展有限公司城区撬装加氢站',
    companyPrivilliageCount: '撬装站',
    companyArea: '具体位置1号102街道',
    mainCategory: 'xxxxxxxxx',
    subCategory: 'xxxxxxxxx',
  },
  {
    companyId: '3',
    companyName: '伊金霍洛旗撬装加氢站（公交车用）',
    companyPrivilliageCount: '撬装站',
    companyArea: '具体位置2号103街道',
    mainCategory: 'xxxxxxxxx',
    subCategory: 'xxxxxxxxx',
  },
  {
    companyId: '4',
    companyName: '吉电股份加氢站',
    companyPrivilliageCount: '撬装站',
    companyArea: '具体位置4号106街道',
    mainCategory: 'xxxxxxxxx',
    subCategory: 'xxxxxxxxx',
  },
  {
    companyId: '5',
    companyName: '伊金霍洛旗风光氢能产业发展有限公司加氢站',
    companyPrivilliageCount: '撬装站',
    companyArea: '具体位置4号109街道',
    mainCategory: 'xxxxxxxxx',
    subCategory: 'xxxxxxxxx',
  },
  {
    companyId: '6',
    companyName: '圣圆能源集团海勒斯壕撬装加氢站',
    companyPrivilliageCount: '撬装站',
    companyArea: '具体位置1号107街道',
    mainCategory: 'xxxxxxxxx',
    subCategory: 'xxxxxxxxx',
  },
]);

onMounted(() => {
  initCharts();
});

function initCharts() {
  var chartDom = document.getElementById('firstChart');
  var chart = echarts.init(chartDom);
  const option = {
    title: {
      text: '加氢站类型',
    },
    legend: {
      top: 20,
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      left: 'center', // 可选，如果希望水平居中显示图例
    },
    series: {
      top: 40,
      name: '访问来源',
      type: 'pie',
      radius: '70%', // 半径大小
      data: [
        {
          value: 1,
          name: '固定站',
          itemStyle: { color: '#87CEFA' }, // 轻蓝
        },
        {
          value: 5,
          name: '撬装站',
          itemStyle: { color: '#4682B4' }, // 中蓝
        },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'inside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  chart.setOption(option);
  var chartDom2 = document.getElementById('secondChart');
  var chart2 = echarts.init(chartDom2);
  const option2 = {
    title: {
      text: '压力等级',
    },
    legend: {
      top: 20,
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      left: 'center', // 可选，如果希望水平居中显示图例
    },
    series: {
      name: '访问来源',
      type: 'pie',
      top: 40,
      radius: '70%', // 半径大小
      data: [
        {
          value: 4,
          name: '35MPa',
          itemStyle: { color: '#87CEFA' }, // 轻蓝
        },
        {
          value: 0,
          name: '70Mpa',
          itemStyle: { color: '#4682B4' }, // 中蓝
        },
        {
          value: 2,
          name: '35/70MPa',
          itemStyle: { color: '#1E90FF' }, // 宝石蓝
        },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'inside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  chart2.setOption(option2);
  var chartDom3 = document.getElementById('thirdChart');
  var chart3 = echarts.init(chartDom3);
  const option3 = {
    title: {
      text: '供给能力',
    },
    legend: {
      top: 20,
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      left: 'center', // 可选，如果希望水平居中显示图例
    },
    series: {
      name: '访问来源',
      top: 40,
      type: 'pie',
      radius: '70%', // 半径大小
      data: [
        {
          value: 1,
          name: '小于500kg/d',
          itemStyle: { color: '#87CEFA' }, // 轻蓝
        },
        {
          value: 5,
          name: '500-1000kg/d',
          itemStyle: { color: '#4682B4' }, // 中蓝
        },
        {
          value: 0,
          name: '大于1000kg/d',
          itemStyle: { color: '#1E90FF' }, // 宝石蓝
        },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'inside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  chart3.setOption(option3);

  var chartDom6 = document.getElementById('sixthChart');
  var chart6 = echarts.init(chartDom6);

  let fullColorData = [
    { name: '北京市', value: 3 },
    { name: '天津市', value: 0 },
    { name: '河北省', value: 0 },
    { name: '山西省', value: 0 },
    { name: '内蒙古自治区', value: 0 },
    { name: '辽宁省', value: 0 },
    { name: '吉林省', value: 0 },
    { name: '黑龙江省', value: 0 },
    { name: '上海市', value: 0 },
    { name: '江苏省', value: 2 },
    { name: '浙江省', value: 0 },
    { name: '安徽省', value: 0 },
    { name: '福建省', value: 0 },
    { name: '江西省', value: 0 },
    { name: '山东省', value: 0 },
    { name: '河南省', value: 0 },
    { name: '湖北省', value: 2 },
    { name: '湖南省', value: 0 },
    { name: '广东省', value: 2 },
    { name: '广西壮族自治区', value: 0 },
    { name: '海南省', value: 0 },
    { name: '重庆市', value: 1 },
    { name: '四川省', value: 0 },
    { name: '贵州省', value: 0 },
    { name: '云南省', value: 0 },
    { name: '西藏自治区', value: 0 },
    { name: '陕西省', value: 0 },
    { name: '甘肃省', value: 0 },
    { name: '青海省', value: 0 },
    { name: '宁夏回族自治区', value: 0 },
    { name: '新疆维吾尔自治区', value: 0 },
    { name: '台湾省', value: 0 },
    { name: '香港特别行政区', value: 0 },
    { name: '澳门特别行政区', value: 0 },
  ];

  const option6 = {
    legend: {
      orient: 'horizontal', // 图例布局的方向，默认水平，可选值：'horizontal', 'vertical'
      left: 'center', // 图例在左侧的位置，默认为全图居中，可选值：'left', 'right', 'top', 'bottom'
      data: ['氢能制取', '氢能存储', '氢能运输', '氢能加注', '氢能应用'], // 需要在图例中显示的系列名称列表
    },
    xAxis: {
      type: 'category',
      data: ['2019年', '2020年', '2021年', '2022年', '2023年'], // 对应数据的类别
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: '氢能制取',
        type: 'bar',
        stack: 'accessSource',
        data: [0, 0, 0, 0, 0],
        itemStyle: { color: '#003366' }, // 深蓝
      },
      {
        name: '氢能存储',
        type: 'bar',
        stack: 'accessSource',
        data: [0, 0, 0, 0, 0],
        itemStyle: { color: '#0066CC' }, // 亮蓝
      },
      {
        name: '氢能运输',
        type: 'bar',
        stack: 'accessSource',
        data: [0, 0, 0, 0, 0],
        itemStyle: { color: '#4DA8FF' }, // 浅天蓝
      },
      {
        name: '氢能加注',
        type: 'bar',
        stack: 'accessSource',
        data: [0, 0, 0, 0, 7],
        itemStyle: { color: '#0099CC' }, // 强饱和蓝
      },
      {
        name: '氢能应用',
        type: 'bar',
        stack: 'accessSource',
        data: [0, 0, 0, 0, 3],
        itemStyle: { color: '#ADD8E6' }, // 淡蓝
      },
    ],
  };

  chart6.setOption(option6);
  var chinaChartDom = document.getElementById('chinaChart');
  var chinaChart = echarts.init(chinaChartDom);
  var chinaJson = null;
  get('https://www.lxjwzl.cn/json/china.json', {}, true).then((res: any) => {
  // get('http://localhost:8080/json/china.json', {}, true).then((res: any) => {
    chinaJson = res;
    echarts.registerMap('全国', chinaJson);
    let dataValue = [
      {
        name: '深圳',
        value: [114.271522, 22.753644],
        sales: '2个',
        text: '机器人1',
      },
      {
        name: '南京',
        value: [118.46, 32.02],
        sales: '2个',
        text: '机器人1',
      },
      {
        name: '重庆',
        value: [106.54, 29.59],
        sales: '1个',
        text: '机器人1',
      },
      {
        name: '北京',
        value: [116.24, 39.55],
        sales: '3个',
        text: '机器人1',
      },
      {
        name: '荆州',
        value: [113.41, 29.59],
        sales: '2个',
        text: '机器人1',
      },
    ];
    const chinaChartOption = {
      //设置一个标题
      title: {
        text: '项目个数',
        x: 'center',
        textStyle: {
          color: '#fff',
          fontSize: '20',
        },
      },
      //鼠标划过省份下弹框
      tooltip: {
        show: true,
        triggerOn: 'click', //点击生效
      },
      geo: {
        map: '全国', //必须写
        roam: false, // 拖拽功能；自选关闭开启
        zoom: 1.3, //地图缩放比例
        center: [105, 36], //地图位置
        //地图省份的样式；包括板块颜色和边框颜色
        itemStyle: {
          areaColor: '#87CEFA',
          borderColor: '#835f5f',
        },
        //省份字体样式；包括是否展示，字体大小和颜色
        label: {
          normal: {
            show: true,
            fontSize: '11.5',
            color: 'rgb(107,102,102)',
          },
        },
        regions: generateRegionsConfig(fullColorData),
        //鼠标划过的高亮设置；包括省份板块颜色和字体等
        emphasis: {
          itemStyle: {
            areaColor: '#d0a3a3',
          },
          label: {
            show: true,
            color: 'rgb(255,255,255)',
          },
        },
      },

      series: [
        //我们的散点
        {
          name: '个数',
          type: 'scatter',
          coordinateSystem: 'geo', //坐标系统
          data: dataValue,
          //地图点的样式；颜色，大小
          symbolSize: 12,
          itemStyle: {
            color: '#f13434',
            shadowBlur: 2,
            shadowColor: '#333',
          },
          //鼠标点击散点的下弹框
          tooltip: {
            show: true,
            triggerOn: 'click',
            formatter: function (data1) {
              let data2 = data1.data;
              return '<b>项目个数<b><br>' + data2.name + data2.sales;
            },
          },
        },

        //涟漪特效
        {
          name: '项目个数',
          type: 'effectScatter',
          coordinateSystem: 'geo',
          data: dataValue, //传入的地图点数据
          symbolSize: 6, //涟漪大小
          showEffectOn: 'render',
          //涟漪效应
          rippleEffect: {
            brushType: 'stroke',
            color: '#f13434',
            period: 10, //周期
            scale: 10, //规模
          },
          hoverAnimation: true, //悬停动画
          //地图点样式
          label: {
            formatter: '{b}',
            position: 'top',
            show: true,
            fontSize: '10',
          },
          itemStyle: {
            color: '#f13434',
            shadowBlur: 2,
            shadowColor: '#333',
          },
          //鼠标点击散点的下弹框
          tooltip: {
            show: true,
            triggerOn: 'click',
            formatter: function (data1) {
              console.log(data1);
              let data2 = data1.data;
              return '<b>个数<b><br>' + data2.name + data2.sales;
            },
          },
          zlevel: 1,
        },
      ],
    };

    chinaChart.setOption(chinaChartOption);
  });
}

function generateRegionsConfig(data) {

  return data.map((item) => ({
    name: item.name,
    label: {
      normal: {
        show: item.value > 0,
        fontSize: '11.5',
        color: 'black', // 动态计算颜色
      },
    },
    itemStyle: {
      areaColor: calculateColor(item.value),
      borderColor: '#835f5f',
    },
  }));
}

function calculateColor(value) {
    // 当value <= 0时，颜色为白色
    if (value <= 0) {
      // 浅蓝色 rgb
      return '#87CEFA'; // 直
    }
    
    // 确保value至少为5，以便于计算
    value = Math.max(value, 5);
    
    // 蓝色的色相（Hue）保持在220，属于蓝色范围
    // 饱和度（Saturation）随value增加而增加，使颜色更鲜艳，最大饱和度设为100%
    // 亮度（Lightness）保持在中间值，比如50%，确保颜色既不太深也不太浅
    const hue = 220;
    const maxSaturation = 100; // 最大饱和度
    const lightness = 60; // 中间亮度值
    // 每增加5，饱和度增加一定比例，这里为了效果明显，直接按比例增加
    const saturation = Math.min(maxSaturation, (value / 5) * maxSaturation);

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}
</script>

<style scoped>
.littleContainer {
  border: 0.1px solid;
  border-color: #d4e6f6;
  border-radius: 10px;
}

.custom-size .el-radio-button__inner {
  font-size: 8px; /* 调整字体大小 */
  padding: 3px 6px; /* 调整内边距 */
}
.blue-text {
  color: blue;
}
</style>
