<template>
  <el-row
    style="
      background-color: #316ccb;
      width: 100%;
      height: 50px;
      align-items: center;
      z-index: 99;
      position: fixed;
      top: 0;
    "
  >
    <el-col style="color: white" :span="5"> {{ h2Company.name }}</el-col>
    <span style="margin-left: 50px"></span>
    <span v-for="(anchor, index) in anchors" :key="index" style="margin-right: 5px">
      <el-button
        text
        style="color: white; font-size: midium; font-weight: 600"
        @click="scrollToAnchor(anchor)"
      >
        {{ anchor.label }}
      </el-button>
    </span>
  </el-row>
  <div class="gradient-bg"></div>
  <el-container class="mainContainer" style="margin-top: 50px">
    <el-button
      style="position: fixed; bottom: 20px; right: 0px; z-index: 9999; width: 50px; font-size: 10px"
      round
      type="primary"
      @click="scrollToTopDiv"
      >返回顶部</el-button
    >
    <el-button
      style="position: fixed; bottom: 60px; right: 0px; z-index: 9999; width: 50px; font-size: 10px"
      round
      type="primary"
      @click="$router.push('/city')"
      >返回城市</el-button
    >
    <el-row class="subContainer" id="companyBasic">
      <el-col :span="24" style="height: 20px"></el-col>
      <el-col :span="10" style="font-size: 20px; font-weight: 600">
        {{ h2Company.name }}
      </el-col>
      <el-col :span="5" :offset="9">
        <el-button
          type="primary"
          style="width: 70%; background-color: #316ccb; border-radius: 5px"
          @click=""
        >
          微尽调报告
        </el-button>
      </el-col>
      <el-col :span="24" style="height: 10px"></el-col>
      <el-col
        :span="23"
        :offset="1"
        style="display: flex; flex-direction: row; justify-content: start; align-items: start"
      >
        <el-tag type="primary">氢能标注</el-tag>
        <el-tag type="success">加氢站</el-tag>
      </el-col>
      <el-col :span="24" style="height: 20px"></el-col>
      <Description />
      <el-col :span="24" style="height: 20px"></el-col>
    </el-row>

    <el-row class="subContainer">
      <el-col :span="24" style="height: 20px"></el-col>
      <el-col :span="23" :offset="1">
        <div class="citySubTitle" id="companyProjects">项目信息</div>
      </el-col>
      <el-col :span="24" style="height: 20px"></el-col>
      <projects />
      <el-col :span="24" style="height: 20px"></el-col>
    </el-row>
    <el-row class="subContainer">
      <el-col :span="24" style="height: 20px"></el-col>
      <el-col :span="23" :offset="1">
        <div class="citySubTitle" id="companyPriviligy">知识产权</div>
      </el-col>
      <el-col :span="24" style="height: 20px"></el-col>
      <Patients />
      <el-col :span="24" style="height: 20px"></el-col>
    </el-row>
    <el-row class="subContainer">
      <el-col :span="24" style="height: 20px"></el-col>
      <el-col :span="23" :offset="1">
        <div class="citySubTitle" id="companyPower">企业穿透</div>
      </el-col>
      <el-col :span="24" style="height: 20px"></el-col>
      <Entity />
      <el-col :span="24" style="height: 20px"></el-col>
      <el-col :span="23" :offset="1">
        <div class="citySubTitle">投融资列表</div>
      </el-col>
      <el-col :span="24" style="height: 20px"></el-col>
      <el-col :span="22" :offset="1">
        <el-table
          stripe
          border
          :data="companyList"
          max-height="500"
          style="width: 100%; font-size: 11px"
        >
          <el-table-column align="center" label="序号" prop="companyId" width="50" />
          <el-table-column align="center" label="投资企业名称" sortable prop="companyName">
            <template #default="{ row }">
              <span class="blue-text">{{ row.companyName }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="轮次" sortable prop="companyPrivilliageCount" />
          <el-table-column align="center" label="投资时间" sortable prop="companyArea" />
          <el-table-column align="center" label="被投企业名称" sortable prop="companyArea" />
          <el-table-column align="center" label="投资金额" sortable prop="companyArea" />
          <el-table-column align="center" label="持股比例" sortable prop="companyArea" />
        </el-table>
      </el-col>
      <el-col :span="24" style="height: 35px"></el-col>
    </el-row>
    <el-row class="subContainer" style="margin-bottom: 30px">
      <el-col :span="24" style="height: 20px"></el-col>
      <el-col :span="23" :offset="1">
        <div class="citySubTitle" id="companyNews">企业资讯</div>
      </el-col>
      <el-col :span="24" style="height: 20px"></el-col>
      <News />
      <el-col :span="24" style="height: 20px"></el-col>
    </el-row>
  </el-container>
  <el-row style="background-color: black; width: 100%; height: 60px; align-items: center">
    <el-col style="color: white" :span="24">@ 2020-2024 氢能产业大数据平台 </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import Entity from './CompanyEntities.vue';
import News from './CompanyNews.vue';
import Projects from './CompanyProjects.vue';
import Description from './CompanyDescription.vue';
import Patients from './Patients.vue';

const h2Company = ref({
  name: '内蒙古圣圆氢能源科技有限公司',
});

const anchors = ref([
  { id: 'companyBasic', label: '基本信息' },
  { id: 'companyProjects', label: '项目信息' },
  { id: 'companyPower', label: '企业穿透' },
  { id: 'companyPriviligy', label: '知识产权' },
  { id: 'companyNews', label: '企业资讯' },
]);

const companyList = ref([
  {
    companyId: 'xxx',
    companyName: 'xxxxxxxxx',
    companyPrivilliageCount: 'xxxxxxxxx',
    companyArea: 'xxxxxxxxx',
    mainCategory: 'xxxxxxxxx',
    subCategory: 'xxxxxxxxx',
  },
  {
    companyId: 'xxx',
    companyName: 'xxxxxxxxx',
    companyPrivilliageCount: 'xxxxxxxxx',
    companyArea: 'xxxxxxxxx',
    mainCategory: 'xxxxxxxxx',
    subCategory: 'xxxxxxxxx',
  },
  {
    companyId: 'xxx',
    companyName: 'xxxxxxxxx',
    companyPrivilliageCount: 'xxxxxxxxx',
    companyArea: 'xxxxxxxxx',
    mainCategory: 'xxxxxxxxx',
    subCategory: 'xxxxxxxxx',
  },
]);

// 跳转到指定锚点的方法
const scrollToAnchor = (anchor) => {
  document.getElementById(anchor.id)?.scrollIntoView({ behavior: 'smooth' });
};

// 跳转到指定锚点的方法
const scrollToTopDiv = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  // document.getElementById('topDiv')?.scrollIntoView({ behavior: 'smooth' });
};
</script>

<style scoped>
.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eeeff9;
  z-index: -1;
}

.gradient-bg {
  background: linear-gradient(to bottom, #a8d2fb, #eeeff9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 110px;
  z-index: 0;
}

.subContainer {
  border: 1px solid;
  border-radius: 10px;
  border-color: white;
  background-color: white;
  width: 63%;
  align-items: top;
  margin-top: 20px;
  box-shadow: 0 3px 3px 0 rgba(141, 141, 141, 0.2);
}

.littleContainer {
  border: 0.1px solid;
  border-color: #d4e6f6;
  border-radius: 10px;
}

.el-tag {
  margin-right: 10px;
}

.citySubTitle {
  margin: 0;
  width: 7vw;
  font-size: medium;
  font-weight: 550;
}

.el-table th {
  display: table-cell !important;
}

.blue-text {
  color: blue;
}
</style>
