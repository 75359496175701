<template>
    <el-row style="height: 925px; width: 100%; box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);margin-bottom: 20px;">
        <el-col :span="5" :offset="17" style="margin-top: 80px">2024年7月</el-col>
        <el-col :span="16" :offset="4" style="">
            <div style="font-size: 50px; color:blueviolet">
                企业微尽调报告
            </div>
            <div style="font-size: 30px">
                {{ h2Company.name }}
            </div>
        </el-col>
        <el-col :span="5" :offset="3"></el-col>
    </el-row>
</template>

<script setup lang='ts'>
import { onMounted, ref } from 'vue';
const h2Company = ref({
  name: '山西鹏飞集团',
});

</script>

<style></style>