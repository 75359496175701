<template>
  <el-row
    style="
      height: 925px;
      width: 100%;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
      margin-bottom: 20px;
      padding-top: 50px;
    "
  >
    <el-col :span="6" :offset="1" style="height: 50px; font-size: 20px; font-weight: 500">
      三、创新能力
    </el-col>
    <el-col :span="17"> </el-col>
    <el-col :span="20" :offset="2">
      <div style="word-wrap: break-word; text-align: center">暂无数据</div>
    </el-col>
    <el-col
      :span="6"
      :offset="1"
      style="height: 50px; font-size: 20px; font-weight: 500; margin-top: 20px"
    >
      四、市场发展
    </el-col>
    <el-col :span="17"> </el-col>
    <el-col :span="6" style="height: 30px; font-size: 15px; font-weight: 500"> 1.投资情况 </el-col>
    <el-col :span="18"> </el-col>
    <el-col :span="20" :offset="2" style="height: 700px">
      <el-table size="small" border stripe :data="investList1" max-height="700" style="width: 100%">
        <el-table-column align="center" label="序号" prop="investId" width="40" />
        <el-table-column align="center" label="投资企业名称" prop="companyName"/>
        <el-table-column
          align="center"
          label="被投企业名称"
          prop="investedCompanyName"
          width="240"
        />
        <el-table-column align="center" label="投资金额/万元" prop="investMoney" width="80" />
        <el-table-column align="center" label="持股比例/%" prop="investRatio" width="60" />
      </el-table>
    </el-col>
  </el-row>
  <el-row
    style="
      height: 925px;
      width: 100%;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
      margin-bottom: 20px;
      padding-top: 50px;
    "
  >
  
  <el-col :span="6" style="height: 30px; font-size: 15px; font-weight: 500"> 2.融资情况 </el-col>
  <el-col :span="18"> </el-col>
  <el-col :span="20" :offset="2" style="height: 400px">
    <el-table size="small" border stripe :data="investList2" max-height="400" style="width: 100%">
      <el-table-column align="center" label="序号" prop="investId" width="50" />
      <el-table-column align="center" label="投资企业名称" prop="companyName"/>
      <el-table-column align="center" label="被投企业名称" prop="investedCompanyName" />
      <el-table-column align="center" label="投资金额/万元" prop="investMoney" width="80" />
      <el-table-column align="center" label="持股比例/%" prop="investRatio" width="80" />
    </el-table>
  </el-col>
  <el-col :span="20" :offset="2" style="height: 400px">
    
  </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { get } from '@/utils/request';
import { ref, onMounted, getCurrentInstance } from 'vue';
let internalInstance = getCurrentInstance();

const investList1 = ref([
  {
    investId: '1',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞集团置业投资有限公司',
    investMoney: '5000',
    investRatio: '100',
  },
  {
    investId: '2',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞绿色能源投资有限公司',
    investMoney: '1500',
    investRatio: '30',
  },
  {
    investId: '3',
    companyName: '山西鹏飞集团',
    investedCompanyName: '北京三晋鹏飞产业运营管理有限公司',
    investMoney: '1000',
    investRatio: '80',
  },
  {
    investId: '4',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞水务有限公司',
    investMoney: '5000',
    investRatio: '100',
  },
  {
    investId: '5',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞蓝能新能源科技有限公司',
    investMoney: '5100',
    investRatio: '51',
  },
  {
    investId: '6',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞氢晨新能源科技有限公司',
    investMoney: '1275',
    investRatio: '51',
  },
  {
    investId: '7',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西大华通新能源物流有限公司',
    investMoney: '10000',
    investRatio: '100',
  },
  {
    investId: '8',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞资源综合利用有限公司',
    investMoney: '2000',
    investRatio: '100',
  },
  {
    investId: '9',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鲲鹏氢能源科技有限公司',
    investMoney: '1275',
    investRatio: '51',
  },
  {
    investId: '10',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞智创汽车制造有限公司',
    investMoney: '20000',
    investRatio: '100',
  },
  {
    investId: '11',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西华美投资有限公司',
    investMoney: '50000',
    investRatio: '100',
  },
  {
    investId: '12',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞申能氢能发展有限公司',
    investMoney: '5000',
    investRatio: '50',
  },
  {
    investId: '13',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西盛和矿机新材料科技发展有限公司',
    investMoney: '5100',
    investRatio: '51',
  },
  {
    investId: '14',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西美酒制造有限公司',
    investMoney: '1530',
    investRatio: '51',
  },
  {
    investId: '15',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞焦化绿色发展有限公司',
    investMoney: '5000',
    investRatio: '100',
  },
  {
    investId: '16',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西普宏科技发展有限公司',
    investMoney: '2100',
    investRatio: '70',
  },
  {
    investId: '17',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西郑好能源科技有限公司',
    investMoney: '9000',
    investRatio: '100',
  },
  {
    investId: '18',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西富鸿源能源科技有限公司',
    investMoney: '5000',
    investRatio: '100',
  },
  {
    investId: '19',
    companyName: '山西鹏飞集团',
    investedCompanyName: '孝义市鹏飞国宾友谊酒店有限公司',
    investMoney: '11000',
    investRatio: '55',
  },
]);

const investList2 = ref([
  {
    investId: '1',
    companyName: '孝义市鹏飞新能源有限公司',
    investedCompanyName: '山西鹏飞集团',
    investMoney: '6000',
    investRatio: '37.5',
  },
  {
    investId: '2',
    companyName: '孝义市聚慧科技有限公司',
    investedCompanyName: '山西鹏飞集团',
    investMoney: '6000',
    investRatio: '37.5',
  },
  {
    investId: '3',
    companyName: '孝义市聚慧科技有限公司',
    investedCompanyName: '山西鹏飞集团',
    investMoney: '4000',
    investRatio: '25',
  },
]);

onMounted(() => {});
</script>

<style scoped></style>
