<template>
  <el-col class="littleContainer" :span="10" :offset="1">
  <div style="height: 280px; text-align: left; padding: 5px">
    <p v-for="policy in policyList">
      <a href="https://www.chinah2data.com/#/client/database/PolicyBaseList" target="_blank">
        <u style="font-size: 10px;">{{ policy.policyTitle }}</u>
      </a>
    </p>
  </div>
</el-col>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';

const policyList = ref([
  {
    policyTitle: '鄂尔多斯市人民政府关于国有资本支持风光氢储产业的指导意见',
    policyUuid: '1',
  },
  {
    policyTitle: '鄂尔多斯市支持上海城市群燃料电池汽车示范应用奖补政策的通知',
    policyUuid: '2',
  },
  {
    policyTitle: '鄂尔多斯市氢能产业发展三年行动方案(2022年-2024年)',
    policyUuid: '3',
  },
  {
    policyTitle:
      '鄂尔多斯市氢能产业发展规划和鄂尔多斯市打造“北疆绿氢城”行动方案(伊金霍洛旗先行先试)',
    policyUuid: '4',
  },
  {
    policyTitle: '支持氢能产业发展若干措施的通知',
    policyUuid: '5',
  },
]);

onMounted(() => {});
</script>

<style scoped>
.littleContainer {
  border: 0.1px solid;
  border-color: #d4e6f6;
  border-radius: 10px;
}
</style>
