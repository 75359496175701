<template>
  <el-col :span="8" :offset="1">
    <el-row class="littleContainer">
      <el-radio-group v-model="radio1" size="small">
        <el-radio-button label="电解水制氢" value="电解水制氢" />
        <el-radio-button label="化石能源制氢" value="化石能源制氢" />
        <el-radio-button label="工业副产氢" value="工业副产氢" />
        <el-radio-button label="加氢站" value="加氢站" />
      </el-radio-group>
    </el-row>
    <el-col :span="24" style="height: 20px"></el-col>
    <el-row class="littleContainer">
      <img style="width: 300px; height: 300px" :src="require('@/assets/city/cityTemplate.png')" />
    </el-row>
  </el-col>
  <el-col :span="13" :offset="1">
    <el-row class="littleContainer">
      <span style="width: 100%; font-size: larger; font-weight: 500">项目数(个)</span>
      <el-col :span="8">
        <el-statistic title="建设" value="5" />
      </el-col>
      <el-col :span="8">
        <el-statistic title="建成" value="2" />
      </el-col>
      <el-col :span="8">
        <el-statistic title="运营" value="2" />
      </el-col>
    </el-row>
    <el-col :span="24" style="height: 20px"></el-col>
    <el-row class="littleContainer">
      <span style="width: 100%; font-size: larger; font-weight: 500">项目产能(万吨/年)</span>
      <el-col :span="8">
        <el-statistic title="建设" value="7428.57" />
      </el-col>
      <el-col :span="8">
        <el-statistic title="建成" value="1" />
      </el-col>
      <el-col :span="8">
        <el-statistic title="运营" value="20.85" />
      </el-col>
    </el-row>
    <el-col :span="24" style="height: 20px"></el-col>
    <el-row >
      <el-col :span="8">
        <div class="pieChart" id="firstChart" style="height: 150px; width: 200px"></div>
      </el-col>
      <el-col :span="8">
        <div class="pieChart" id="secondChart" style="height: 150px; width: 200px"></div>
      </el-col>
      <el-col :span="8">
        <div class="pieChart" id="thirdChart" style="height: 150px; width: 200px"></div>
      </el-col>
    </el-row>
  </el-col>
</template>

<script lang="ts" setup>
import { onMounted, getCurrentInstance, computed, ref } from 'vue';
let internalInstance = getCurrentInstance();
let echarts = internalInstance.appContext.config.globalProperties.$echarts;

onMounted(() => {
  initCharts();
});

const radio1 = ref('电解水制氢');

function initCharts() {
  var chartDom = document.getElementById('firstChart');
  var chart = echarts.init(chartDom);
  const option = {
    title: {
      text: '电力来源',
    },
    legend: {
      top: 30,
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      left: 'center', // 可选，如果希望水平居中显示图例
    },
    series: {
      top: 40,
      name: '访问来源',
      type: 'pie',
      radius: '70%', // 半径大小
      data: [
        {
          value: 700,
          name: '光伏',
          itemStyle: { color: '#87CEFA' }, // 轻蓝
        },
        {
          value: 0,
          name: '风电',
          itemStyle: { color: '#4682B4' }, // 中蓝
        },
        {
          value: 1449.5,
          name: '多种类型',
          itemStyle: { color: '#1E90FF' }, // 宝石蓝
        },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'inside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  chart.setOption(option);
  var chartDom2 = document.getElementById('secondChart');
  var chart2 = echarts.init(chartDom2);
  const option2 = {
    title: {
      text: '技术路线',
    },
    legend: {
      top: 30,
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      left: 'center', // 可选，如果希望水平居中显示图例
    },
    series: {
      name: '访问来源',
      type: 'pie',
      top: 40,
      radius: '70%', // 半径大小
      data: [
        {
          value: 0,
          name: 'PEM',
          itemStyle: { color: '#87CEFA' }, // 轻蓝
        },
        {
          value: 0,
          name: 'SOEC',
          itemStyle: { color: '#4682B4' }, // 中蓝
        },
        {
          value: 22.59,
          name: 'ALK',
          itemStyle: { color: '#1E90FF' }, // 宝石蓝
        },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'inside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  chart2.setOption(option2);
  var chartDom3 = document.getElementById('thirdChart');
  var chart3 = echarts.init(chartDom3);
  const option3 = {
    title: {
      text: '应用方向',
    },
    legend: {
      top: 30,
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      left: 'center', // 可选，如果希望水平居中显示图例
    },
    series: {
      name: '访问来源',
      top: 40,
      type: 'pie',
      radius: '70%', // 半径大小
      data: [
        {
          value: 177.5,
          name: '交通',
          itemStyle: { color: '#87CEFA' }, // 轻蓝
        },
        {
          value: 0,
          name: '合成氨',
          itemStyle: { color: '#4682B4' }, // 中蓝
        },
        {
          value: 0,
          name: '储能',
          itemStyle: { color: '#1E90FF' }, // 宝石蓝
        },
        {
          value: 0,
          name: '科研',
          itemStyle: { color: '#00BFFF' }, // 深天蓝
        },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'inside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  chart3.setOption(option3);
}
</script>

<style scoped>
.littleContainer {
  border: 0.1px solid;
  border-color: #d4e6f6;
  border-radius: 10px;
}
</style>
