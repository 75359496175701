<template>
  <el-container class="layout-container">
    <my-aside></my-aside>
    <el-main
      style="height: 100vh; display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <el-row style="height: 10%; width: 100%;">
        <el-col :span="6" style="display: flex; flex-direction: row;justify-content: center; align-items: center;">
          <img src="../assets/basic-icons/data-inside.png" style="height: 35px;  margin-right: 5px;" />
          <span style="font-size: 30px; color: #2091F6;">上传数据</span>
        </el-col>
      </el-row>
      <el-tabs style="height: 90%; width: 90%;">
        <el-tab-pane label="上传风光曲线">
          <el-row>
            <el-col :span="8" :offset="2">
              <el-form :model="curveForm" style="margin-top: 70px;" size="large" label-width="auto">
                <el-form-item label="曲线名称：">
                  <el-input style="width: 250px;" v-model="curveForm.curveName" placeholder="请输入曲线名称" />
                </el-form-item>
                <el-form-item label="曲线类型：">
                  <el-radio v-model="curveForm.energyType" label="风"> 风 </el-radio>
                  <el-radio v-model="curveForm.energyType" label="光"> 光 </el-radio>
                </el-form-item>
                <el-form-item label="省份：">
                  <el-select v-model="curveForm.province" style="width: 250px;" placeholder="请选择项目所在地区">
                    <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="时间类型：">
                  <el-radio v-model="curveForm.hoursType" label="8760"> 8760 小时 </el-radio>
                  <el-radio v-model="curveForm.hoursType" label="24"> 24 小时 </el-radio>
                </el-form-item>
                <el-form-item label="请上传：">
                  <el-upload ref="uploadRefs" :limit="1" :auto-upload="true" accept=".xlsx, .xls" 
                    :before-upload="beforeUPload" :show-file-list="true" v-model:file-list="form.fileList">
                    <template #trigger>
                      <el-button type="primary"> 选择文件 </el-button>
                    </template>
                    <el-button type="info" style="margin-left: 20px;" @click="downloadTemplate">下载模板</el-button>
                  </el-upload>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="4">
              <el-divider direction="vertical" style="width: 2%; height: 400px;" />
            </el-col>
            <el-col :span="8">
              <div v-show="curveData.length > 0" style="height: 400px; width: 400px;">
                <div id="curvechart" style="height: 400px; width: 400px;"></div>
                <el-input v-model="curveForm.utilizationHours" style="width: 80%;">
                  <template #prepend>年利用小时数：</template>
                </el-input>
              </div>
              <el-empty v-show="curveData.length == 0" :image-size="200" description="请上传曲线后预览"/>
            </el-col>
          </el-row>
          <el-button type="primary" style="width: 180px; margin-top: 50px;" size="large" @click="insertCurve">上传</el-button>
        </el-tab-pane>
        <el-tab-pane label="上传土地价格" disabled>
        </el-tab-pane>
        <el-tab-pane label="上传峰谷电价" disabled>
          开发中...
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>

<script lang="ts" setup>
import MyAside from '../components/Aside.vue'
import { reactive, onMounted, getCurrentInstance, ref } from 'vue'
let internalInstance = getCurrentInstance();
let echarts = internalInstance.appContext.config.globalProperties.$echarts;
import { get, post } from '@/utils/request'
import * as XLSX from 'xlsx';
import { ElMessage, ElUpload, ElButton } from 'element-plus'
import type { UploadInstance } from 'element-plus'
import { toNumber } from 'lodash';
const uploadRefs = ref<UploadInstance>()

let curveChart = null;
let curveData = ref([]);
let hourData = ref([]);

const form = reactive<any>({
  fileList: [],
  files: []
})

onMounted(() => {
  setTimeout(() => {  // 这里为啥要用定时器在0.2s后再画图呢，其实我也不能确定是不是这个问题，但是这么写后，确实解决了问题,
    curveChart = echarts.init(document.getElementById('curvechart'));
  }, 200)
})

const downloadTemplate = () => {
  const templatePath =  'http://' + window.location.host + '/excel/模板.xlsx';
  window.location.href = templatePath;
};

// 数据导入
const beforeUPload = (file: any) => {
  if (!curveForm.value.hoursType) {
    ElMessage.error('请先选择曲线时间类型');
    return;
  }
  if (!file.type.includes('application/vnd.ms-excel') && !file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
    alert('只支持上传.xlsx或.xls文件');
    return false;
  }
  // 使用FileReader读取文件内容
  const reader = new FileReader();
  reader.onload = (e) => {
    const binaryString = e.target.result;
    // 解析二进制数据为工作簿对象
    const workbook = XLSX.read(binaryString, { type: 'binary' });
    // 获取第一个工作表的数据
    const sheet_name_list = workbook.SheetNames;
    const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]], { header: 1 });
    if (data.length != toNumber(curveForm.value.hoursType)) {
      ElMessage.error('数据行数与时间类型不匹配,请检查格式后重新上传');
      return;
    }
    data.forEach((row, index) => {
      curveData.value.push(toNumber(row[0]).toFixed(3));
      hourData.value.push(index);
    });
    calculateutilizationHours();
    updateChart(); // ！！！这个一定要放在 onload 里面！ 不然会关闭数据然后清零！就找不到数据了，这应该也属于并发问题呀
    return false;
  };
  reader.readAsBinaryString(file);
  return false; // 阻止上传到服务器
};

function calculateutilizationHours() {
    let utilizationHours = 0;

    for (let i = 1; i < curveData.value.length; i++) {
        const prevValue = curveData.value[i - 1];
        const curValue = curveData.value[i];
        utilizationHours += prevValue / 2;
        utilizationHours += curValue / 2;
    }
    if (curveForm.value.hoursType == '8760') {
      curveForm.value.utilizationHours = parseInt(utilizationHours);
    } else {
      curveForm.value.utilizationHours =  parseInt(utilizationHours * 365);
    }
}

function updateChart() {
  const option = {
    xAxis: {
      type: 'category',
      name: '日内时间',
      data: hourData.value,
      nameLocation: 'center',
      nameGap: 20
    },
    yAxis: {
      type: 'value',
      name: '风机满发比例',
    },
    series: [
      {
        type: 'line',
        name: '',
        data: curveData.value,
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 2,
        },
      },
    ],
    title: {
      text: curveForm.value.hoursType == '8760' ? '典型年曲线' : '典型日曲线',
      left: 'center',
      top: 'top',
      textStyle: {
        color: 'rgb(148, 148, 148)',
        fontSize: 12,
      },
    },
  };
  curveChart.setOption(option);
}


const curveForm = ref({
  curveName: '',
  energyType: '',
  hoursType: '',
  province: '',
  utilizationHours: 0,
  curveData: ''
})

function insertCurve() {
  if (!curveForm.value.curveName) {
    ElMessage.error('请输入曲线名称');
    return;
  }
  if (!curveForm.value.energyType) {
    ElMessage.error('请选择能源类型');
    return;
  }
  if (!curveForm.value.province) {
    ElMessage.error('请选择省份');
    return;
  }
  if (!curveForm.value.hoursType) {
    ElMessage.error('请选择时间类型');
    return;
  }
  curveForm.value.curveData = '[' + curveData.value.join(',') + ']';
  if (!curveForm.value.curveData) {
    ElMessage.error('请上传曲线');
    return;
  }
  post('/data/curve', curveForm.value).then((res: any) => {
    if (res.code == 200) {
      ElMessage.success('上传成功');
      window.location.href = '/data';
    } else {
      ElMessage.error(res.msg);
    }
  });
}


const provinceList = [
  {
    name: "黑龙江",
    id: "黑龙江"
  },
  {
    name: "甘肃",
    id: "甘肃"
  },
  {
    name: "陕西",
    id: "陕西"
  },
  {
    name: "青海",
    id: "青海"
  },
  {
    name: "吉林",
    id: "吉林"
  },
  {
    name: "辽宁",
    id: "辽宁"
  },
  {
    name: "上海",
    id: "上海"
  },
  {
    name: "山西",
    id: "山西"
  },
  {
    name: "广东",
    id: "广东"
  },
  {
    name: "广西",
    id: "广西"
  },
  {
    name: "贵州",
    id: "贵州"
  },
  {
    name: "河南",
    id: "河南"
  },
  {
    name: "福建",
    id: "福建"
  },
  {
    name: "山东",
    id: "山东"
  },
  {
    name: "内蒙古",
    id: "内蒙古"
  },
  {
    name: "宁夏",
    id: "宁夏"
  },
  {
    name: "新疆",
    id: "新疆"
  },
  {
    name: "四川",
    id: "四川"
  },
  {
    name: "云南",
    id: "云南"
  },
  {
    name: "湖北",
    id: "湖北"
  }
]

</script>

<style scoped></style>