<template>
  <el-container class="layout-container">
    <my-aside></my-aside>
    <el-main>
      <el-steps :active="1" align-center finish-status="success">
        <el-step title="基本信息" description="" />
        <el-step title="模式选择" description="" />
        <el-step title="参数设置" description="" />
      </el-steps>
      <el-container class="model-container">
        <div class="model-options">
          <div class="model-option">
            <h2 class="model-potion-title">下游产品</h2>
            <el-divider />
            <el-radio-group v-model="product" class="model-ratio-group">
              <div class="model-ratio">
                <el-radio label="纯制氢" style="height: 45px; " border>纯制氢
                </el-radio>
              </div>
              <div class="model-ratio">
                <el-radio label="合成氨"  disabled  style="height: 45px; " border>合成氨</el-radio>
              </div>
              <div class="model-ratio">
                <el-radio label="合成甲醇"  disabled  style="height: 45px; " border>合成甲醇</el-radio>
              </div>
              <div class="model-ratio">
                <el-radio label="合成可持续航煤" disabled style="height: 45px; " border>合成可持续航煤</el-radio>
              </div>
              <div class="model-ratio">
                <el-radio label="煤制油" disabled style="height: 45px; " border>煤制油</el-radio>
              </div>
            </el-radio-group>
          </div>
          <div class="model-option">
            <h2 class="model-potion-title">优化目标</h2>
            <el-divider />
            <el-radio-group v-model="target" class="model-ratio-group">
              <div class="model-ratio">
                <el-radio label="最大收益" style="height: 45px; " border>最大收益
                  <el-tooltip content="<span><strong> 计算项目最大收益<br/>
                    输入参数：氢气价格，制氢规模<br/>
                    输出参数：最大收益以及对应的装备容量</strong></span>" raw-content>
                    <img :src="require('@/assets/basic-icons/question.png')" style="height: 11px; margin-left: 6px;" />
                  </el-tooltip>
                </el-radio>

              </div>
              <div class="model-ratio">
                <el-radio label="边缘价格" style="height: 45px;" border> {{ target_price }}
                  <el-tooltip content="<span><strong> 计算项目边缘氢价<br/>
                    输入参数：收益率约束，制氢规模<br/>
                    输出参数：边缘氢价以及对应的装备容量</strong></span>" raw-content>
                    <img :src="require('@/assets/basic-icons/question.png')" style="height: 11px; margin-left: 6px;" />
                  </el-tooltip>
                </el-radio>

              </div>
              <div class="model-ratio">
                <el-radio label="最大规模" style="height: 45px;" border> {{ target_scale }}
                  <el-tooltip content="<span><strong> 计算项目最大制氢规模<br/>
                    输入参数：氢气价格，收益率约束<br/>
                    输出参数：最大制氢规模以及对应的装备容量</strong></span>" raw-content>
                    <img :src="require('@/assets/basic-icons/question.png')" style="height: 11px; margin-left: 6px;" />
                  </el-tooltip>
                </el-radio>

              </div>
              <div class="model-ratio">
                <el-radio label="边缘装备容量" style="height: 45px; " border>装备容量
                  <el-tooltip content="<span><strong> 计算项目边缘装备容量<br/>
                    输入参数：氢气价格，制氢规模，收益率约束<br/>
                    输出参数：边缘项目装备容量</strong></span>" raw-content>
                    <img :src="require('@/assets/basic-icons/question.png')" style="height: 11px; margin-left: 6px;" />
                  </el-tooltip>
                </el-radio>
              </div>
            </el-radio-group>
          </div>
        </div>
        <div class="choose-btns">
          <el-button class="choose-btn" type="success" @click="goToCreate()">上一步</el-button>
          <el-button class="choose-btn" type="primary" @click="goToModify()">下一步</el-button>
        </div>
      </el-container>
    </el-main>
  </el-container>
</template>

<script lang="ts" setup>
import { ElNotification } from 'element-plus'
import { ref, watch, onMounted } from 'vue'
import MyAside from '../components/Aside.vue'
import { get, post } from '@/utils/request'

import { useRoute } from 'vue-router';

const route = useRoute();
const projectUuid = route.params.projectUuid as string;

// do not use same name with ref
let product = ref('');

let target = ref('');
let projectId = "1";

let target_price = ref('边缘价格');

let target_scale = ref('产量规模');

const emit = defineEmits(['goToModify', 'goToCreate'])

onMounted(() => {
  get('/project/tp', {
    projectUuid: projectUuid,
  }).then((res: any) => {
    product.value = res.data.product
    target.value = res.data.target
    projectId = res.data.projectId
  })
})

watch(product, (newValue, OldValue) => {
  if (newValue == '合成氨') {
    target_price.value = '边缘氨价';
    target_scale.value = '制氨规模';
  } else if (newValue == '纯制氢') {
    target_price.value = '边缘氢价';
    target_scale.value = '制氢规模';
  } else if (newValue == '合成甲醇') {
    target_price.value = '边缘醇价';
    target_scale.value = '制醇规模';
  } else if (newValue == '合成可持续航煤') {
    target_price.value = '边缘煤价';
    target_scale.value = '制煤规模';
  } else if (newValue == '煤制油') {
    target_price.value = '边缘油价';
    target_scale.value = '制油规模';
  }
  sendRequest();
});

watch(target, (newValue, OldValue) => {
  sendRequest();
});

async function sendRequest() {
  post("/project/update/tp", {
    target: target.value,
    product: product.value,
    projectId: projectId,
  });
}

function goToCreate(this: any) {
  location.href = '/project/edit/np/' + projectUuid ;
}

function goToModify() {
  if (product.value == '') {
    ElNotification({
      title: '提示',
      message: '请选择下游产品',
      type: 'warning',
      offset: 100,
    })
  } else if (target.value == '') {
    ElNotification({
      title: '提示',
      type: 'warning',
      message: '请选择优化目标',
      offset: 100,
    })
  } else {
    location.href = '/project/edit/modules/' + projectUuid ;
  }
}


</script>

<style scoped>
.model-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.model-options {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 70vh;
  width: 60vw;
}

.model-option {
  background-color: #2091F6;
  border-radius: 7%;
  margin-top: 50px;
  height: 85%;
  width: 40%;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.30);
}

.model-potion-title {
  color: white;
}

.model-ratio-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
}

.choose-btns {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2%
}

.choose-btn {
  width: 150px;
  height: 40px;
  font-size: 16px;
  font-weight: 1000;
}
</style>

<style lang="less">
.model-ratio-group {
  .el-radio__input.is-checked+.el-radio__label {
    color: white;
    font-weight: 1000;
  }

  .el-radio.is-bordered.is-checked {
    border-color: white;
    border: 4px solid;
  }

  .el-radio {
    text-align: center;
    padding: 6px 15px 0 15px;
    color: rgb(208, 217, 253);
    border-radius: var(--el-border-radius-base);
    height: 28px;
    width: 250px;
    font-size: 16px;
  }
}
</style>