import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import ECharts from 'vue-echarts'
import * as echarts from 'echarts'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createStore } from 'vuex'
import { createPinia } from 'pinia'
import 'dayjs/locale/zh-cn';//中文
import locale from 'element-plus/es/locale/lang/zh-cn';//中文

// 创建一个新的 store 实例
const store = createStore({
  state: {
    bestResult: [[]]
  },
  mutations: {
    convey (state, payload) {
      for(var i = 0; i < 1; i++) {
        state.bestResult[i] = payload.bestResult[i];
      }
    }
  }
})
  

// axios.defaults.baseURL = "http://localhost:8888/"
const app = createApp(App).component('ECharts',ECharts)
const pinia = createPinia();
app.config.globalProperties.$echarts = echarts
app.use(ElementPlus,{ locale })
app.use(VueAxios,axios)
app.use(store)
app.use(pinia)
app.provide('axios', app.config.globalProperties.axios) 
app.use(router).mount('#app')

const debounce = (fn, delay) => {
  let timer
   return (...args) => {
     if (timer) {
       clearTimeout(timer)
     }
     timer = setTimeout(() => {
       fn(...args)
     }, delay)
   }
}
  
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
   constructor(callback) {
     callback = debounce(callback, 200);
     super(callback);
   }
}
