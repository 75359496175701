<template>
  <div>
    <div style="height: 600px; width: 1000px">
      <RelationGraph
        ref="graphRef"
        :options="graphOptions"
      >
        <!-- <template #graph-plug> -->
        <!-- <div class="c-my-panel"> -->
        <!-- <el-radio-group v-model="currentCase" size="small" @change="resetGraph">
              <el-radio-button label="Horizontal Tree"></el-radio-button>
              <el-radio-button label="Vertical Tree"></el-radio-button>
            </el-radio-group> -->
        <!-- </div> -->
        <!-- </template> -->
      </RelationGraph>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import RelationGraph, {
  RGOptions,
  RGJsonData,
  RGNode,
  RGLine,
  RGLink,
  RGUserEvent,
  RelationGraphComponent,
  RGTreeLayoutOptions,
} from 'relation-graph-vue3';

const currentCase = ref('Horizontal Tree');
const graphOptions: RGOptions = {
  // debug: true,
  // showDebugPanel: true,
  // lineUseTextPath: true,
  layout: {
    layoutName: 'tree',
    from: 'center',
    min_per_height: 80,
  },
  defaultNodeShape: 1,
  defaultNodeWidth: 250,
  defaultLineShape: 4,
  disableDragNode: true,
  graphOffset_x: 0,
  graphOffset_y: 0,
  disableDragLine: true,
  defaultJunctionPoint: 'left',
  defaultNodeBorderWidth: 0,
  defaultLineColor: 'rgba(0, 186, 189, 1)',
  defaultNodeColor: '#2091f6',
  defaultLineTextOffset_x: -10,
  defaultLineTextOffset_y: -1,
  disableDragCanvas: false,
};

const graphRef = ref<RelationGraphComponent | null>(null);

const resetGraph = async () => {
  const treeLayoutOptions = graphOptions.layout as RGTreeLayoutOptions;
  if (currentCase.value === 'Horizontal Tree') {
    treeLayoutOptions.from = 'left';
    treeLayoutOptions.min_per_width = 400;
    treeLayoutOptions.min_per_height = 30;
    graphOptions.defaultJunctionPoint = 'ltrb'; // 箭头指向问题
  } else {
    treeLayoutOptions.from = 'top';
    treeLayoutOptions.min_per_width = 100;
    treeLayoutOptions.max_per_width = 150;
    treeLayoutOptions.min_per_height = 120;
    treeLayoutOptions.max_per_height = 140;
    graphOptions.defaultJunctionPoint = 'tb';
  }
  const graphInstance = graphRef.value?.getInstance();
  if (graphInstance) {
    await graphInstance.setOptions(graphOptions);
    await showGraph();
    await graphInstance.refresh(); // 这里解决刚加载时页面的显示问题 - 显示不完全且不居中
  }
};

const showGraph = async () => {
  const __graph_json_data: RGJsonData = {
    rootId: 'root',
    nodes: [
      { id: 'root', text: '山西鹏飞集团有限公司', color: 'blue', height: 50 },
      // { id: 'N2', text: '孝义市鹏飞新能源有限公司', color: 'blue' },
      { id: 'N2', text: '孝义市鹏飞新能源有限公司', height: 50 },
      { id: 'N3', text: '孝义市聚慧科技有限公司', height: 50 },
      { id: 'N4', text: '山西世宸贸易有限公司', height: 50 },
      { id: 'c1', text: '山西鹏飞集团置业投资有限公司'},
      { id: 'c2', text: '山西鹏飞绿色能源投资有限公司' },
      { id: 'c3', text: '北京三晋鹏飞产业运营管理有限公司' },
      { id: 'c4', text: '山西鹏飞水务有限公司'},
      { id: 'c5', text: '山西鹏飞蓝能新能源科技有限公司' },
      { id: 'c6', text: '山西大华通新能源物流有限公司' },
      { id: 'c7', text: '山西鹏飞氢晨新能源科技有限公司'},
      { id: 'c8', text: '山西鹏飞资源综合利用有限公司' },
      { id: 'c9', text: '山西鲲鹏氢能源科技有限公司' },
      { id: 'c10', text: '山西鹏飞智创汽车制造有限公司'},
      { id: 'c11', text: '山西华美投资有限公司' },
      { id: 'c12', text: '山西鹏飞申能氢能发展有限公司' },
      { id: 'c13', text: '山西盛和矿机新材料科技发展有限公司' },
      { id: 'c14', text: '山西美酒制造有限公司' },
      { id: 'c15', text: '山西鹏飞焦化绿色发展有限公司'},
      { id: 'c16', text: '山西普宏科技发展有限公司' },
      { id: 'c17', text: '山西郑好能源科技有限公司' },
      { id: 'c18', text: '山西富鸿源能源科技有限公司' },
      { id: 'c19', text: '孝义市鹏飞国宾友谊酒店有限公司' },
    ],
    lines: [
      { from: 'N2', to: 'root', text: '控股37.5%' ,lineShape: 2,},
      { from: 'N3', to: 'root', text: '控股37.5%' ,lineShape: 2,},
      { from: 'N4', to: 'root', text: '控股25%',lineShape: 2, },
      { from: 'root', to: 'c1', text: '控股100%' ,lineShape: 2,},
      { from: 'root', to: 'c2', text: '控股30%' ,lineShape: 2,},
      { from: 'root', to: 'c3', text: '控股80%' ,lineShape: 2,},
      { from: 'root', to: 'c4', text: '控股100%',lineShape: 2, },
      { from: 'root', to: 'c5', text: '控股51%' ,lineShape: 2,},
      { from: 'root', to: 'c6', text: '控股100%',lineShape: 2, },
      { from: 'root', to: 'c7', text: '控股51%' ,lineShape: 2,},
      { from: 'root', to: 'c8', text: '控股100%',lineShape: 2, },
      { from: 'root', to: 'c9', text: '控股51%' ,lineShape: 2,},
      { from: 'root', to: 'c10', text: '控股100%' ,lineShape: 2,},
      { from: 'root', to: 'c11', text: '控股100%',lineShape: 2, },
      { from: 'root', to: 'c12', text: '控股50%',lineShape: 2, },
      { from: 'root', to: 'c13', text: '控股51%' ,lineShape: 2,},
      { from: 'root', to: 'c14', text: '控股51%' ,lineShape: 2,},
      { from: 'root', to: 'c15', text: '控股100%' ,lineShape: 2,},
      { from: 'root', to: 'c16', text: '控股70%',lineShape: 2, },
      { from: 'root', to: 'c17', text: '控股100%',lineShape: 2, },
      { from: 'root', to: 'c18', text: '控股100%',lineShape: 2, },
      { from: 'root', to: 'c19', text: '控股55%',lineShape: 2, },
    ],
  };
  const graphInstance = graphRef.value?.getInstance();
  if (graphInstance) {
    await graphInstance.setJsonData(__graph_json_data);
    // const leftNodes: RGNode[] = [];
    // for (const node of graphInstance.getNodes()) {
    //   if (node.lot && node.lot.level! < 0) {
    //     node.color = '#2091f6';
    //     leftNodes.push(node);
    //   }
    // }
    // const leftLines = graphInstance
    //   .getLinks()
    //   .filter((link) => leftNodes.includes(link.fromNode) || leftNodes.includes(link.toNode))
    //   .reduce((currentLines: RGLine[], link: RGLink) => currentLines.concat(...link.relations), []);
    // for (const line of leftLines) {
    //   line.placeText = 'start';
    // }
  }
};

onMounted(() => {
  resetGraph();
});
</script>

<style scoped>
.c-my-panel {
  width: 350px;
  text-align: center;
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 10px;
  z-index: 800;
  padding: 10px;
  background-color: rgba(239, 239, 239, 0.86);
  border: #eeeeee solid 1px;
  overflow: hidden;
}
.c-my-panel .c-option-name {
  color: #666666;
  font-size: 14px;
  line-height: 40px;
}
</style>
