<template>
  <el-container class="layout-container" style="height: 100vh;">
    <img :src="require('@/assets/login/side.png')" style="height: 100%; width: 50%;"/>
    <div class="login-main" style="display: flex; flex-direction: column; justify-content: center; align-items: center; align-content: center;height: 100%; width: 50%;">
      <el-form
        label-position="top"
        label-width="100px"
        :model="dataForm"
        style="width: 50%;"
      >
        <h2>重置密码</h2>
        <el-form-item label="手机号" class="item">
          <el-input v-model="dataForm.phone" placeholder="请输入您的手机号"/>
        </el-form-item>
        <el-form-item label="验证码" class="item">
          <el-input v-model="dataForm.code" placeholder="请输入验证码" style="width: 70%;"> 
          </el-input>
          <el-button type="warning" @click="sendVerificationCode" :disabled="isSendingCode || countdown > 0" style="font-size: small; color: white;width: 25%; margin-left: 5%;">
            {{ countdown > 0 ? `重新发送(${countdown})` : '发送验证码' }}
          </el-button>
        </el-form-item>
        <el-form-item label="密码" class="item">
          <el-input v-model="dataForm.password" placeholder="请输入密码" show-password/>
        </el-form-item>
        <el-form-item label="确认" class="item">
          <el-input v-model="dataForm.password2" placeholder="请再输入一遍密码" show-password> 
          </el-input>
        </el-form-item>
        <div>
          <el-button type="primary" style="width: 35%;" @click="onSubmit">
            重置
          </el-button>
        </div>
      </el-form>
    </div>
  </el-container>
</template>

<script setup>
import {reactive, ref, inject } from 'vue';
import { get, post } from '@/utils/request'
import {ElMessage} from "element-plus";

const dataForm = reactive({
  phone: '',
  code: '',
  password: '',//第一遍
  password2: '',//再确认一遍
})

let countdown = ref(0);
let isSendingCode = ref(false);

// 发送验证码，调验证码接口
const sendVerificationCode = () => {
  if (!dataForm.phone) {
    ElMessage.error('请输入手机号');
    return;
  }
  if (!/^1[3-9]\d{9}$/.test(dataForm.phone)) {
    ElMessage.error('请输入正确的手机号');
    return;
  }
  post('/account/send-code', {
    phone: dataForm.phone
  })
  .then(res => {
    ElMessage.success(res.data)
  })
  .catch(error => {
    console.error(error);
  });


  isSendingCode.value = true;
  // 设置倒计时时间，这里假设为10秒
  countdown.value = 60;

  // 倒计时效果
  const countdownInterval = setInterval(() => {
    countdown.value--;
    if (countdown.value <= 0) {
      clearInterval(countdownInterval);
      isSendingCode.value = false;
    }
  }, 1000);
}

// 登录，调检验验证码和密码的登录接口
const onSubmit = () => {
  if (dataForm.code === '') {
    ElMessage.error('验证不能为空')
    return
  }
  if (dataForm.password === '') {
    ElMessage.error('密码不能为空')
    return
  }
  if (dataForm.password2 === '') {
    ElMessage.error('确认密码不能为空')
    return
  }
  if (dataForm.password !== dataForm.password2) {
    ElMessage.error('两次密码不一致')
    return
  }
  if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/.test(dataForm.password)) {
    ElMessage.error('密码必须包含大写字母小写字母和数字，且不能少于 6 位')
    return
  }
  post('/account/set-password', {
    phone: dataForm.phone,
    code: dataForm.code,
    password: dataForm.password
  })
  .then(res => {
    if(res.data.code == '200') {
      ElMessage.success(res.data);
      window.location.href = '/login';
    } else {
      ElMessage.error(res.data);
    }
   
  })
  .catch(error => {
    console.error(error);
  });
}
</script>

<style>
</style>
