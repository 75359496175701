<template>
  <el-row
    style="
      background-color: #316ccb;
      width: 100%;
      height: 50px;
      align-items: center;
      z-index: 99;
      position: fixed;
      top: 0;
    "
  >
    <el-col style="color: white" :span="5"> {{ h2Company.name }}</el-col>
    <el-col style="color: white" :span="10" :offset="2">
      <span v-for="(anchor, index) in anchors" :key="index">
        <el-button
          color="#316ccb"
          style="color: white; font-size: midium; font-weight: 600"
          type="primary"
          @click="scrollToAnchor(anchor)"
        >
          {{ anchor.label }}
        </el-button>
      </span>
    </el-col>
  </el-row>
  <div class="gradient-bg"></div>
  <el-container class="mainContainer" style="margin-top: 50px">
    <el-button
      style="position: fixed; bottom: 20px; right: 0px; z-index: 9999; width: 50px; font-size: 10px"
      round
      type="primary"
      @click="scrollToTopDiv"
      >返回顶部</el-button
    >
    <el-button
      style="position: fixed; bottom: 60px; right: 0px; z-index: 9999; width: 50px; font-size: 10px"
      round
      type="primary"
      @click="$router.push('/city')"
      >返回城市</el-button
    >
    <el-row class="subContainer" id="companyBasic">
      <el-col :span="24" style="height: 20px"/>
      <el-col :span="8" style="font-size: 20px; font-weight: 600; color:black">
        {{ h2Company.name }}
      </el-col>
      <el-col :span="6" :offset="10">
        <el-button
          type="primary"
          style="width: 60%; font-weight: 600;"
          class="my_button"
          @click="$router.push('/company/report/dsmkec')"
        >
          <img :src="require('@/assets/company/link.png')" style="width: 18px; margin-right: 5px;">
          微尽调报告
        </el-button>
      </el-col>
      <el-col :span="24" style="height: 10px"></el-col>
      <el-col
        :span="23"
        :offset="1"
        style="display: flex; flex-direction: row; justify-content: start; align-items: start"
      >
        <el-tag type="primary" effect="light">氢能标注</el-tag>
        <el-tag type="success" effect="light">加氢站</el-tag>
      </el-col>
      <el-col :span="24" style="height: 20px"/>
      <Description />
      <el-col :span="24" style="height: 20px"/>
    </el-row>
    <el-row class="subContainer" id="companyProjects">
      <el-col :span="24" style="height: 20px"/>
      <projects />
      <el-col :span="24" style="height: 20px"/>
    </el-row>
    <el-row class="subContainer">
      <el-col :span="24" style="height: 20px"/>
      <el-col :span="23" :offset="1">
        <div class="citySubTitle" id="companyPriviligy">知识产权</div>
      </el-col>
      <el-col :span="24" style="height: 20px"/>
      <Patients />
      <el-col :span="24" style="height: 20px"/>
    </el-row>
    <el-row class="subContainer">
      <el-col :span="24" style="height: 20px"/>
      <el-col :span="23" :offset="1">
        <div class="citySubTitle" id="companyPower">穿透信息</div>
      </el-col>
      <el-col :span="24" style="height: 20px"/>
      <Entity />
      <el-col :span="24" style="height: 20px"/>
      <el-col :span="23" :offset="1">
        <div class="citySubTitle">投融资列表</div>
      </el-col>
      <el-col :span="24" style="height: 20px"/>
      <el-col :span="22" :offset="1">
        <el-table
          stripe
          border
          :data="investList2"
          max-height="500"
          style="width: 100%; font-size: 11px"
        >
          <el-table-column align="center" label="序号" prop="investId" width="50" />
          <el-table-column align="center" label="投资企业名称" prop="companyName">
            <template #default="{ row }">
              <span class="blue-text">{{ row.companyName }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="被投企业名称" prop="investedCompanyName" />
          <el-table-column align="center" label="投资金额/万元" prop="investMoney" width="120" />
          <el-table-column align="center" label="持股比例/%" prop="investRatio" width="90" />
        </el-table>
      </el-col>
      <el-col :span="24" style="height: 35px"></el-col>
    </el-row>
    <el-row class="subContainer" style="margin-bottom: 30px">
      <el-col :span="24" style="height: 20px"/>
      <el-col :span="23" :offset="1">
        <div class="citySubTitle" id="companyNews">企业资讯</div>
      </el-col>
      <el-col :span="24" style="height: 20px"/>
      <News />
      <el-col :span="24" style="height: 20px"/>
    </el-row>
  </el-container>
  <el-row style="background-color: black; width: 100%; height: 60px; align-items: center">
    <el-col style="color: white" :span="24">@ 2020-2024 氢能产业大数据平台 </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import Entity from './CompanyEntities.vue';
import News from './CompanyNews.vue';
import Projects from './CompanyProjects.vue';
import Description from './CompanyDescription.vue';
import Patients from './Patients.vue';

const h2Company = ref({
  name: '山西鹏飞集团有限公司',
});

const anchors = ref([
  { id: 'companyBasic', label: '基本信息' },
  { id: 'companyProjects', label: '项目信息' },
  { id: 'companyPower', label: '企业穿透' },
  { id: 'companyPriviligy', label: '知识产权' },
  { id: 'companyNews', label: '企业资讯' },
]);

const investList2 = ref([
  {
    investId: '1',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞集团置业投资有限公司',
    investMoney: '5000',
    investRatio: '100',
  },
  {
    investId: '2',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞绿色能源投资有限公司',
    investMoney: '1500',
    investRatio: '30',
  },
  {
    investId: '3',
    companyName: '山西鹏飞集团',
    investedCompanyName: '北京三晋鹏飞产业运营管理有限公司',
    investMoney: '1000',
    investRatio: '80',
  },
  {
    investId: '4',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞水务有限公司',
    investMoney: '5000',
    investRatio: '100',
  },
  {
    investId: '5',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞蓝能新能源科技有限公司',
    investMoney: '5100',
    investRatio: '51',
  },
  {
    investId: '6',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞氢晨新能源科技有限公司',
    investMoney: '1275',
    investRatio: '51',
  },
  {
    investId: '7',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西大华通新能源物流有限公司',
    investMoney: '10000',
    investRatio: '100',
  },
  {
    investId: '8',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞资源综合利用有限公司',
    investMoney: '2000',
    investRatio: '100',
  },
  {
    investId: '9',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鲲鹏氢能源科技有限公司',
    investMoney: '1275',
    investRatio: '51',
  },
  {
    investId: '10',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞智创汽车制造有限公司',
    investMoney: '20000',
    investRatio: '100',
  },
  {
    investId: '11',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西华美投资有限公司',
    investMoney: '50000',
    investRatio: '100',
  },
  {
    investId: '12',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞申能氢能发展有限公司',
    investMoney: '5000',
    investRatio: '50',
  },
  {
    investId: '13',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西盛和矿机新材料科技发展有限公司',
    investMoney: '5100',
    investRatio: '51',
  },
  {
    investId: '14',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西美酒制造有限公司',
    investMoney: '1530',
    investRatio: '51',
  },
  {
    investId: '15',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞焦化绿色发展有限公司',
    investMoney: '5000',
    investRatio: '100',
  },
  {
    investId: '16',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西普宏科技发展有限公司',
    investMoney: '2100',
    investRatio: '70',
  },
  {
    investId: '17',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西郑好能源科技有限公司',
    investMoney: '9000',
    investRatio: '100',
  },
  {
    investId: '18',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西富鸿源能源科技有限公司',
    investMoney: '5000',
    investRatio: '100',
  },
  {
    investId: '19',
    companyName: '山西鹏飞集团',
    investedCompanyName: '孝义市鹏飞国宾友谊酒店有限公司',
    investMoney: '11000',
    investRatio: '55',
  },
  {
    investId: '20',
    companyName: '孝义市鹏飞新能源有限公司',
    investedCompanyName: '山西鹏飞集团',
    investMoney: '6000',
    investRatio: '37.5',
  },
  {
    investId: '21',
    companyName: '孝义市聚慧科技有限公司',
    investedCompanyName: '山西鹏飞集团',
    investMoney: '6000',
    investRatio: '37.5',
  },
  {
    investId: '22',
    companyName: '孝义市聚慧科技有限公司',
    investedCompanyName: '山西鹏飞集团',
    investMoney: '4000',
    investRatio: '25',
  },
]);

// 跳转到指定锚点的方法
const scrollToAnchor = (anchor) => {
  document.getElementById(anchor.id)?.scrollIntoView({ behavior: 'smooth' });
};

// 跳转到指定锚点的方法
const scrollToTopDiv = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  // document.getElementById('topDiv')?.scrollIntoView({ behavior: 'smooth' });
};
</script>

<style scoped>
.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eeeff9;
  z-index: -1;
}

.gradient-bg {
  background: linear-gradient(to bottom, #a8d2fb, #eeeff9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 110px;
  z-index: 0;
}

.subContainer {
  border: 1px solid;
  border-radius: 10px;
  border-color: white;
  background-color: white;
  width: 63%;
  align-items: top;
  margin-top: 20px;
  box-shadow: 0 3px 3px 0 rgba(141, 141, 141, 0.2);
}

.littleContainer {
  border: 0.1px solid;
  border-color: #d4e6f6;
  border-radius: 10px;
}

.el-tag {
  margin-right: 10px;
}

.citySubTitle {
  margin: 0;
  width: 7vw;
  font-size: medium;
  font-weight: 550;
}

.el-table th {
  display: table-cell !important;
}

.blue-text {
  color: blue;
}

.my_button {
  background-color: #2a2eff;
  border-radius: 8px;
  
}
</style>
