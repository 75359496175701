<template>
    <el-container class="layout-container">
      <my-aside></my-aside>
      <el-main>
        <el-steps :active="0" align-center finish-status="success">
          <el-step title="基本信息" description="" />
          <el-step title="模式选择" description="" />
          <el-step title="参数设置" description="" />
        </el-steps>
        <el-container style="display: flex; align-items: center; flex-direction: column;">
            <el-form :model="basicForm" 
                ref="basicFormRef"
                label-position="left" 
                :rules="rules"
                label-width="120px" 
                style="display: flex; align-items: center; flex-direction: column; width: 50%; margin-top:100px; border: 1px solid blue; border-radius: 30px;  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.30);"
                size="large"
            >
                <h1 style="margin-bottom: 50px; margin-top: 50px; color: #2091F6;">基本信息</h1>
                <el-form-item label="项目名称" style="margin-bottom: 50px;" prop="name">
                    <el-input v-model="basicForm.name" placeholder="请输入项目名称" style="width: 250px;" @blur="changeBasicInfo"/>
                </el-form-item>
                <el-form-item label="所在地" prop="province">
                <el-select v-model="basicForm.province" style="width: 250px; margin-bottom: 50px;" @change="changeBasicInfo" placeholder="请选择项目所在地区">
                    <el-option
                    v-for="item in provinceList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
                </el-form-item>
            </el-form> 
            <el-button type="primary" style="margin-top: 50px;height: 39px;width: 170px;" @click="submitForm(basicFormRef)">下一步</el-button>
        </el-container>
      </el-main>
    </el-container>
  </template>
  
  <script lang="ts" setup>
  import { reactive, ref, onMounted } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import { get, post } from '@/utils/request'
import MyAside from '../components/Aside.vue'

import { useRoute } from 'vue-router';

const route = useRoute();
const projectUuid = route.params.projectUuid as string;


interface BasicForm {
  name: string
  province: string
  projectId: string
}

onMounted(() => {
  get('/project/np',{
      projectUuid: projectUuid,
    }).then((res:any) => {
    basicForm.name = res.data.name
    basicForm.province = res.data.province
    basicForm.projectId = res.data.projectId
  })
})

const emit = defineEmits(['goToChoose'])
  
function goToChoose(this:any) {
  location.href = '/project/edit/tp/' + projectUuid ;
}

const provinceList = [
  {
    name: "黑龙江",
    id: "黑龙江"
  },
  {
    name: "甘肃",
    id: "甘肃"
  },
  {
    name: "青海",
    id: "青海"
  },
  {
    name: "陕西",
    id: "陕西"
  },
  {
    name: "吉林",
    id: "吉林"
  },
  {
    name: "辽宁",
    id: "辽宁"
  },
  {
    name: "上海",
    id: "上海"
  },
  {
    name: "山西",
    id: "山西"
  },
  {
    name: "广东",
    id: "广东"
  },
  {
    name: "广西",
    id: "广西"
  },
  {
    name: "贵州",
    id: "贵州"
  },
  {
    name: "河南",
    id: "河南"
  },
  {
    name: "福建",
    id: "福建"
  },
  {
    name: "山东",
    id: "山东"
  },
  {
    name: "内蒙古",
    id: "内蒙古"
  },
  {
    name: "宁夏",
    id: "宁夏"
  },
  {
    name: "新疆",
    id: "新疆"
  },
  {
    name: "四川",
    id: "四川"
  },
  {
    name: "云南",
    id: "云南"
  },
  {
    name: "湖北",
    id: "湖北"
  }
]

const basicFormRef = ref<FormInstance>()
const basicForm = reactive<BasicForm>({
  name: '',
  province: '',
  projectId: "1",
})

const rules = reactive<FormRules<BasicForm>>({
  name: [
    { required: true, message: '请填写项目名称', trigger: 'blur' },
    { min: 1, max: 8, message: '项目名称长度不允许超过8', trigger: 'blur' },
  ],
  province: [
    {
      required: true,
      message: '请选择项目所在地',
      trigger: 'change',
    },
  ]
})

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      goToChoose()
    } else {
      console.log('error submit!', fields)
    }
  })
}

function changeBasicInfo () {
  sendRequest();
}


async function sendRequest() {
  post("/project/update/np", basicForm);
}
  </script>
  <style scoped>
  </style>
    