<template>
    <el-aside style="color: blue; background-color: #2091F6; height: 100vh; overflow: hidden;" width="130px">
        <div>
          <img src="../assets/header.png" style="height: 33px;"/>
        </div>
        <div style="margin-top: 10%;">
            <el-avatar> 氢能 </el-avatar>
        </div>
        <div style="margin-top: 30%;">
            <el-button color="#2091F6" style="height: 60px;" @click="$router.push('/projects')">
                <img src="../assets/basic-icons/rocket.png" style="height: 50px; "/>
                项目管理
            </el-button>
        </div>
        <div style="margin-top: 30%;">
            <el-button color="#2091F6"  style="height: 60px;" @click="$router.push('/data')">
                <img src="../assets/basic-icons/data.png" style="height: 40px;margin-left: 10px;"/>
                数据中心
            </el-button>
        </div>
        <div style=" margin-top: 200%;">
            <el-button color="#2091F6"  style="height: 60px;" @click.native="$router.push('/account/info')">
                <img src="../assets/basic-icons/gear.png" style="height: 40px;"/>
            </el-button>
        </div>
      </el-aside>
</template>

<script lang="ts" setup>
import { reactive, onMounted, toRefs } from 'vue'

const state = reactive({
  circleUrl:
    'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
  squareUrl:
    'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
  sizeList: ['small', '', 'large'] as const,
})

const { circleUrl, squareUrl, sizeList } = toRefs(state)


</script>