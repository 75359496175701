<template>
  <div style="width: 100%">
    <div style="height: 200px; width: 100%">
      <RelationGraph
        ref="graphRef"
        :options="graphOptions"
        @node-click="onNodeClick"
        @line-click="onLineClick"
      >
        <!-- <template #graph-plug> -->
        <!-- <div class="c-my-panel"> -->
        <!-- <el-radio-group v-model="currentCase" size="small" @change="resetGraph">
              <el-radio-button label="Horizontal Tree"></el-radio-button>
              <el-radio-button label="Vertical Tree"></el-radio-button>
            </el-radio-group> -->
        <!-- </div> -->
        <!-- </template> -->
      </RelationGraph>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import RelationGraph, {
  RGOptions,
  RGJsonData,
  RGNode,
  RGLine,
  RGLink,
  RGUserEvent,
  RelationGraphComponent,
  RGTreeLayoutOptions,
} from 'relation-graph-vue3';

const currentCase = ref('Horizontal Tree');
const graphOptions: RGOptions = {
  // debug: true,
  // showDebugPanel: true,
  // lineUseTextPath: true,
  layout: {
    layoutName: 'tree',
    from: 'center',
    max_per_width: 300,
    min_per_height: 80,
  },
  defaultNodeShape: 1,
  defaultNodeWidth: 150,
  defaultLineShape: 4,
  disableDragNode: true,
  graphOffset_x: 0,
  graphOffset_y: 0,
  disableDragLine: true,
  defaultJunctionPoint: 'left',
  defaultNodeBorderWidth: 0,
  defaultLineColor: 'rgba(0, 186, 189, 1)',
  defaultNodeColor: '#2091f6',
  defaultLineTextOffset_x: -10,
  defaultLineTextOffset_y: -1,
  disableDragCanvas: false,
};

const graphRef = ref<RelationGraphComponent | null>(null);

const resetGraph = async () => {
  const treeLayoutOptions = graphOptions.layout as RGTreeLayoutOptions;
  if (currentCase.value === 'Horizontal Tree') {
    treeLayoutOptions.from = 'left';
    treeLayoutOptions.min_per_width = 300;
    treeLayoutOptions.min_per_height = 80;
    graphOptions.defaultJunctionPoint = 'ltrb'; // 箭头指向问题
  } else {
    treeLayoutOptions.from = 'top';
    treeLayoutOptions.min_per_width = 100;
    treeLayoutOptions.max_per_width = 150;
    treeLayoutOptions.min_per_height = 120;
    treeLayoutOptions.max_per_height = 140;
    graphOptions.defaultJunctionPoint = 'tb';
  }
  const graphInstance = graphRef.value?.getInstance();
  if (graphInstance) {
    await graphInstance.setOptions(graphOptions);
    await showGraph();
    await graphInstance.refresh(); // 这里解决刚加载时页面的显示问题
  }
};

const showGraph = async () => {
  const __graph_json_data: RGJsonData = {
    rootId: 'root',
    nodes: [
      { id: 'root', text: '内蒙古圣圆氢能源科技有限公司' },
      { id: 'N2', text: '内蒙古圣圆能源集团有限责任公司', color: 'blue' },
      { id: 'N3', text: '内蒙古圣圆能源集团有限责任公司2' },
      { id: 'N4', text: '内蒙古圣圆能源集团子公司1' },
    ],
    lines: [
      { from: 'N2', to: 'root', text: '控股50%' },
      { from: 'N3', to: 'root', text: '控股50%' },
      { from: 'root', to: 'N4', text: '控股100%' },
    ],
  };
  const graphInstance = graphRef.value?.getInstance();
  if (graphInstance) {
    await graphInstance.setJsonData(__graph_json_data);
    const leftNodes: RGNode[] = [];
    for (const node of graphInstance.getNodes()) {
      if (node.lot && node.lot.level! < 0) {
        node.color = '#2091f6';
        leftNodes.push(node);
      }
    }
    const leftLines = graphInstance
      .getLinks()
      .filter((link) => leftNodes.includes(link.fromNode) || leftNodes.includes(link.toNode))
      .reduce((currentLines: RGLine[], link: RGLink) => currentLines.concat(...link.relations), []);
    for (const line of leftLines) {
      line.placeText = 'start';
    }
  }
};

const onNodeClick = (nodeObject: RGNode, $event: RGUserEvent) => {
  console.log('onNodeClick:', nodeObject);
};

const onLineClick = (lineObject: RGLine, linkObject: RGLink, $event: RGUserEvent) => {
  console.log('onLineClick:', lineObject);
};

onMounted(() => {
  resetGraph();
});
</script>

<style scoped>
.c-my-panel {
  width: 350px;
  text-align: center;
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 10px;
  z-index: 800;
  padding: 10px;
  background-color: rgba(239, 239, 239, 0.86);
  border: #eeeeee solid 1px;
  overflow: hidden;
}
.c-my-panel .c-option-name {
  color: #666666;
  font-size: 14px;
  line-height: 40px;
}
</style>
