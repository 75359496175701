<template>
  <el-container class="layout-container">
    <el-header class="home-header" style="width: 100%; background-color: #316CCB;">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#316CCB"
        text-color="#fff"
        active-text-color="#ffd04b"
        @select="handleSelect"
      >
        <el-menu-item index="0">
          <img src="../assets/header.png" style="height: 50px; margin-left: 50px; margin-right: 50px;"/>
        </el-menu-item>
        <el-menu-item index="1" @click="goUrl(1)">中国氢能联盟</el-menu-item>
        <el-sub-menu index="2" disabled>
          <template #title>投资者计划</template>
          <el-menu-item index="2-1" style="margin: 1px solid white;">机构</el-menu-item>
          <el-menu-item index="2-2">个人</el-menu-item>
        </el-sub-menu>
        <el-menu-item index="3" @click="goUrl(2)">了解更多</el-menu-item>
        <el-menu-item index="4" @click="goUrl(3)">会员企业</el-menu-item>
        <span style="width: 100px;"/>
        <el-menu-item ><el-button class="contact-btn" style="" @click="$router.push('/login')">登录</el-button></el-menu-item>
        <el-menu-item ><el-button class="contact-btn" style="" @click="$router.push('/register')">注册</el-button></el-menu-item>
        <el-menu-item ><el-button class="contact-btn" style="" @click="contact">联系我们</el-button></el-menu-item>
      </el-menu>
    </el-header>
    <el-main class="home-body">
      <div style="width: 100%; background-color: #316CCB; height: 500px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <h1 style="font-size: 60px; color: #fff; margin: 0">风 光 氢 储 源 网 项 目</h1>
        <h1 style="font-size: 17px; color: #f5f5f5;">评 测 优 化 氢 能 项 目 投 资 参 数</h1>
        <el-button @click="open" style="width: 300px; height: 100px; border: 2px solid rgb(207, 207, 207); border-radius: 3%;   box-shadow: 0 6px 15px 0 rgba(253, 253, 253, 0.3);">
          <h1>立即创建</h1>        
        </el-button>
      </div>
      <video ref="myVideo" autoplay loop muted  style="height: 600px; margin-top: -100px">  
        <source :src="require('@/assets/video/home.mp4')" type="video/mp4">  
      </video>
      <div class="box-card-container" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div class="box-card">
          <div class="card-title">
            <img :src="require('@/assets/basic-icons/project.png')" style="height: 80px; width: 80px;">
            <h3>项目评测</h3>
          </div>
          <el-divider direction="vertical" style="height: 100%;"/>
          <div class="card-content">
            <img :src="require('@/assets/home/1.png')" style="height: 150px;">
            <el-text line-clamp="3">
              平台提供全面的氢能项目评估服务，<br />
              深入分析项目技术可行性、<br />
              经济效益及环境影响，全面助力决策与优化.<br />
            </el-text>
          </div>
        </div>
        <div class="box-card">
          <div class="card-title">
            <img :src="require('@/assets/basic-icons/create.png')" style="height: 80px; width: 80px;">
            <h3>项目优化</h3>
          </div>
          <el-divider direction="vertical" style="height: 100%;"/>
          <div class="card-content">
            <img :src="require('@/assets/home/2.png')" style="height: 150px;">
            <el-text line-clamp="3">
              平台致力于氢能项目综合优化，<br />
              通过高效分析与智能调控，<br />
              实现能效提升、成本节约及可持续发展最大化<br />
            </el-text>
          </div>
        </div>   
        <div class="box-card">
          <div class="card-title">
            <img :src="require('@/assets/basic-icons/vision.png')" style="height: 80px; width: 80px;">
            <h3>项目可视化</h3>
          </div>
          <el-divider direction="vertical" style="height: 100%;"/>
          <div class="card-content">
            <img :src="require('@/assets/home/1.png')" style="height: 150px;">
            <el-text line-clamp="3">
              全面展现氢能项目动态，<br />
              可视化技术呈现能源流动与转化过程，<br />
              实时监控效能数据，助力决策分析与优化管理<br />
            </el-text>
          </div>
        </div>   
      </div>     
      <el-footer class="home-footer" style="width: 100%;height: 500px; margin-top: 20px; background-color: #000000;">
        <div style="width: 100%;height: 400px; background-color: #eadfdf;">
          <img :src="require('@/assets/home/footer.png')" style="height: 400px;">
        </div>>
        <el-divider />
        <div>

        </div>>
      </el-footer>
    </el-main>
  </el-container>
</template>

<script lang="ts" setup>
import { ElMessage } from 'element-plus'
import { ref } from 'vue'
import { post, getToken } from '@/utils/request'
import { onMounted } from 'vue'
import { useRouter } from "vue-router";
const router = useRouter();

const activeIndex = ref('')
const handleSelect = (key: string, keyPath: string[]) => {
}

onMounted(() => {
  if (getToken()) {
    router.push({path: "/projects"})
  }
})


const open = () => {
  ElMessage({
    showClose: true,
    message: '请先登录',
  })
  location.href = '/login';
}

function contact() {
  ElMessage({
    showClose: true,
    message: '测试版暂不支持此功能',
  })
}

function goUrl(num) {
  if(num == 1) {
    window.location.href = 'https://www.chinah2data.com/#/client/home';
  }else if(num == 2) {
    window.location.href = 'https://www.chinah2data.com/#/client/database/EventBaseList';
  } else {
    window.location.href = 'https://www.chinah2data.com/#/client/database/CompanyBaseList';
  }
}


</script>

<style scoped>
.home-header{
  position: relative;
  height: 5%;
  padding: 0;
}

.el-menu-demo{
  margin: 0;
  width: 100%;
}

.home-body {
  margin-top: 60px;
  padding: 0;
  height: 92%;
  width: 100%;
  position: absolute;
  overflow-y: scroll;
}

.home-body::-webkit-scrollbar {
  	display: none;
}

.box-card {
  display: flex;
  border: 1px solid rgb(215, 215, 215);
  flex-direction: row;
  justify-content: center;
  width: 1066px;
  height: 300px;
  margin-top: 20px;
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.20);
}

.card-title {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-content {
  width: 68%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.layout-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}
</style>
