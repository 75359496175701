<template>
  <el-container class="layout-container" style="">
      <div style="background-color: white; border-radius: 10px; height: 5%; width: 95%; margin-top: 20px;"> 
        <h1>氢能项目交互式展示</h1>
      </div>
      <div id="line" style="background-color: white; border-radius: 10px; padding-top: 20px; height: 300px; width: 95%; margin-top: 20px;"/>
      <div style="border-radius: 10px; width: 95%; margin-top: 20px; display: flex; flex-direction: row; justify-content: space-between;">
        <div id="bar"  style="background-color: white; padding-top: 20px; border-radius: 10px; height: 300px; width: 49%; "/>
        <div id="round"  style="background-color: white; padding-top: 20px; border-radius: 10px; height: 300px; width: 49%;"/>
      </div>
      <div id="sortbar" style="background-color: white; margin-top: 20px; padding-top: 20px; border-radius: 10px; height: 300px; width: 95%;"/>
      <div style="height: 20px;"/>
  </el-container>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted, onUnmounted, getCurrentInstance, inject } from 'vue'
  import {
    Edit,
    Message,
  } from '@element-plus/icons-vue'
  import { useStore } from 'vuex'
  let internalInstance = getCurrentInstance();
  let echarts = internalInstance.appContext.config.globalProperties.$echarts;

  onMounted(() => {
    drawECharts();
    drawSortBarCharts();
    drawBarCharts();
    drawRoundCharts();
  })

  function drawECharts(){
    var myChart = echarts.init(document.getElementById("line"),'temp')
    var option = {}
    option = {
      title: {
        text: '25年收益数据'
      },
      tooltip : {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#6a7985'
            }
        }
      },
        
      //  右上角的组件，用于说明，也可进行点击筛选
      legend: {
          align : 'right',
          x : 'right',
          top : 25,
          selectedMode : 'single',    //  我这里设置的单选模式
          data:['总利润','总成本', '总收益']            //  显示的第一项和第二项，这里的颜色是根据自定义主题的颜色顺序来定的
      },
      
      //  x、y轴显示一些设置，比如刻度颜色显示什么的，可在自定义主题设置一部分
      xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      yAxis: {
          type: 'value'
      },
      // 具体配置项，根据具体项目查看官网配置项说明
      series: [
          {
              name : '总利润',
              data: [150, 132, 201, 534, 290, 530, 820],
              type: 'line',
              smooth: true,   //  是否平滑曲线
              areaStyle: {},
          },
          { 
              name : '总成本',
              data: [82, 93, 90, 93, 129, 333, 432],
              type: 'line',
              smooth: true,
              areaStyle: {},
          },
          { 
              name : '总收益',
              data: [82, 93, 90, 93, 129, 333, 432],
              type: 'line',
              smooth: true,
              areaStyle: {},
          },
      ]
    }

    // 查看Echarts的API，我们需要显示默认的一些数据，配置如下
    // 默认显示坏的数据
    myChart.dispatchAction({
        type: 'legendSelect',
        name: '坏',
    })
    //  默认显示第7个数据
    myChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 1,
        dataIndex: 6,
    })

    // 执行渲染图形和数据的操作
    if (option && typeof option === "object") {
      myChart.setOption(option, true)
    }
  }

  function drawRoundCharts(){
    var myChart = echarts.init(document.getElementById('round'), 'temp');

    // 指定图表的配置项和数据
    var option = {
      title: {
        text: '运维成本分布'
      },
      series: [
        {
          type: 'pie',
          data: [
            {
              value: 700,
              name: '人力'
            },
            {
              value: 900,
              name: '土地'
            },
            {
              value: 300,
              name: '税费'
            },
            {
              value: 400,
              name: '保险'
            },
            {
              value: 500,
              name: '维护'
            }
          ],
          roseType: 'area'
        }
      ]
    };
    myChart.setOption(option);
  };
    
  function drawBarCharts(){
    var myChart = echarts.init(document.getElementById('bar'), 'temp');

      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '投资成本分布'
        },
        tooltip: {},
        legend: {
          data: ['成本分布']
        },
        xAxis: {
          data: ['风能', '光能', '电能', '储能', '电解槽', '线路']
        },
        yAxis: {},
        series: [
          {
            name: '销量',
            type: 'bar',
            data: [5, 20, 36, 10, 10, 20]
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
  }

  function drawSortBarCharts(){
    var myChart = echarts.init(document.getElementById('sortbar'), 'temp');

      // 指定图表的配置项和数据
      var option = {
        title: {
          text: '收益分布'
        },
        tooltip: {},
        legend: {
          data: ['成本分布']
        },
        xAxis: {
          max: 'dataMax'
        },
        yAxis: {
          type: 'category',
          data: ['下游产品', '上网收益'],
          inverse: true,
          animationDuration: 300,
          animationDurationUpdate: 300,
          max: 2 // only the largest 3 bars will be displayed
        },
        series: [
          {
            realtimeSort: true,
            name: 'X',
            type: 'bar',
            data: [5, 20],
            label: {
              show: true,
              position: 'right',
              valueAnimation: true
            }
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
  }
</script>
<style scoped>

.layout-container{
  background-image: url("/src/assets/vision/vision-backgroud.png");
  background-size: 100% 100%;
  background-attachment: fixed;
  background-repeat: repeat-y;
 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 900px;
  min-height: 1000px;
}


</style>
  