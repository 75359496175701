<template>
  <el-row>
    <el-col :span="12">
      <el-radio-group v-model="projectType" size="large">
        <el-radio-button label="电解水制氢" value="电解水制氢" />
        <el-radio-button label="化石能源制氢" value="化石能源制氢" />
        <el-radio-button label="工业副产氢" value="工业副产氢" />
        <el-radio-button label="加氢站" value="加氢站" />
      </el-radio-group>
    </el-col>
    <el-col :span="12"> </el-col>
    <el-col :span="12">
      <div id="chinaChart" style="width: 600px; height: 600px"></div>

      <!-- <img style="width: 450px; height: 450px" :src="require('@/assets/222.png')" />  -->
    </el-col>
    <el-col :span="12">
      <div
        style="
          width: 90%;
          margin-left: 0%;
          border: 0.1px solid black;
          box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
        "
      >
        <span style="width: 100%; font-size: larger; font-weight: 500"
          >{{ projectType }}项目（座）</span
        >
        <el-row>
          <el-col :span="8">
            <el-statistic title="规划" :value="projectStatus.plan" />
          </el-col>
          <el-col :span="8">
            <el-statistic title="建成" :value="projectStatus.built" />
          </el-col>
          <el-col :span="8">
            <el-statistic title="运营" :value="projectStatus.operating" />
          </el-col>
        </el-row>
      </div>
      <div
        style="
          width: 90%;
          margin-left: 0%;
          border: 0.1px solid black;
          box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
          margin-top: 10px;
        "
      >
        <span style="width: 100%; font-size: larger; font-weight: 500"
          >{{ projectType == '加氢站' ? '供给能力' : '氢气产能' }}（座）</span
        >
        <el-row>
          <el-col :span="12">
            <el-statistic
              :title="projectType == '加氢站' ? '0 - 500公斤/天' : '0 - 50000吨'"
              :value="projectProduction.one"
            />
          </el-col>
          <el-col :span="12">
            <el-statistic
              :title="projectType == '加氢站' ? '500 - 1000公斤/天' : '50000吨以上'"
              :value="projectProduction.two"
            />
          </el-col>
        </el-row>
      </div>
      <el-row style="margin-top: 3%">
        <el-col :span="12">
          <div class="pieChart" id="firstChart" style="height: 200px; width: 300px"></div>
        </el-col>
        <el-col :span="12">
          <div class="pieChart" id="secondChart" style="height: 200px; width: 300px"></div>
        </el-col>
        <el-col :span="12" :offset="6">
          <div class="pieChart" id="thirdChart" style="height: 200px; width: 350px"></div>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
  <el-col :span="23" :offset="1">
    <div class="citySubTitle">项目列表</div>
  </el-col>
  <el-row>
    <el-col
      :span="22"
      :offset="1"
      style="
        height: 500px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: start;
      "
    >
      <el-table stripe :data="projectList" max-height="500" style="width: 90%">
        <el-table-column align="center" label="序号" prop="companyId" width="200" />
        <el-table-column align="center" label="项目名称" sortable prop="companyName" width="270" />
        <el-table-column
          align="center"
          label="项目类型"
          sortable
          prop="companyPrivilliageCount"
          width="230"
        />
        <el-table-column align="center" label="项目地址" sortable prop="companyArea" width="390" />
      </el-table>
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { get } from '@/utils/request';
import { ref, onMounted, getCurrentInstance, watch } from 'vue';
let internalInstance = getCurrentInstance();

let echarts = internalInstance.appContext.config.globalProperties.$echarts;

const projectList = ref([
]);

onMounted(() => {
  initCharts();
  updateProjectStatus();
  updateProjectsTable();
});

let projectType = ref('电解水制氢');

let projectStatus = ref({
  plan: 0,
  built: 1,
  operating: 0,
});

let projectProduction = ref({
  one: 1,
  two: 0,
});

watch(
  () => projectType,
  (newProjectType) => {
    updateProjectStatus();
    updateFirstChart();
    updateSecondChart();
    updateThirdChart();
    updateChinaChart();
    updateProjectsTable();
  },
  { deep: true },
);

function updateProjectsTable() {
  if (projectType.value === '电解水制氢') {
    projectList.value = [
      {
        companyId: '1',
        companyName: '120MW光伏发电制2000吨/年绿氢项目',
        companyPrivilliageCount: 'PEM',
        companyArea: '山西长治',
        mainCategory: 'xxxxxxxxx',
        subCategory: 'xxxxxxxxx',
      }
    ];
  } else if (projectType.value === '化石能源制氢') {
    projectList.value = [
      {
        companyId: '1',
        companyName: '孝义鹏飞化石能源制氢项目1',
        companyPrivilliageCount: '煤制氢',
        companyArea: '山西吕梁',
        mainCategory: 'xxxxxxxxx',
        subCategory: 'xxxxxxxxx',
      },
      {
        companyId: '2',
        companyName: '孝义鹏飞化石能源制氢项目2',
        companyPrivilliageCount: '焦炉煤气',
        companyArea: '山西吕梁',
        mainCategory: 'xxxxxxxxx',
        subCategory: 'xxxxxxxxx',
      },
    ];
  } else if (projectType.value === '工业副产氢') {
    projectList.value = [
      {
        companyId: '1',
        companyName: '孝义鹏飞工业副产氢项目',
        companyPrivilliageCount: '焦炭',
        companyArea: '山西吕梁市孝义市',
        mainCategory: 'xxxxxxxxx',
        subCategory: 'xxxxxxxxx',
      }
    ];
  } else if (projectType.value === '加氢站') {
    projectList.value = [
      {
        companyId: '1',
        companyName: '山西鹏飞集团城区综合能源站',
        companyPrivilliageCount: '固定站',
        companyArea: '山西吕梁',
        mainCategory: 'xxxxxxxxx',
        subCategory: 'xxxxxxxxx',
      },
      {
        companyId: '2',
        companyName: '山西鹏飞集团北姚综合能源站',
        companyPrivilliageCount: '固定站',
        companyArea: '山西吕梁',
        mainCategory: 'xxxxxxxxx',
        subCategory: 'xxxxxxxxx',
      },
      {
        companyId: '3',
        companyName: '山西鹏飞集团西王屯综合能源站',
        companyPrivilliageCount: '固定站',
        companyArea: '山西吕梁',
        mainCategory: 'xxxxxxxxx',
        subCategory: 'xxxxxxxxx',
      },
      {
        companyId: '4',
        companyName: '山西鹏飞集团金港湾综合能源站',
        companyPrivilliageCount: '固定站',
        companyArea: '山西吕梁',
        mainCategory: 'xxxxxxxxx',
        subCategory: 'xxxxxxxxx',
      },
      {
        companyId: '5',
        companyName: '山西鹏飞集团东庄综合能源站',
        companyPrivilliageCount: '固定站',
        companyArea: '山西吕梁',
        mainCategory: 'xxxxxxxxx',
        subCategory: 'xxxxxxxxx',
      },
    ];
  }
}

function updateProjectStatus() {
  if (projectType.value === '电解水制氢') {
    projectStatus.value = {
      plan: 0,
      built: 1,
      operating: 0,
    };
    projectProduction.value = {
      one: 1,
      two: 0,
    };
  } else if (projectType.value === '化石能源制氢') {
    projectStatus.value = {
      plan: 0,
      built: 2,
      operating: 0,
    };
    projectProduction.value = {
      one: 2,
      two: 0,
    };
  } else if (projectType.value === '工业副产氢') {
    projectStatus.value = {
      plan: 0,
      built: 1,
      operating: 0,
    };
    projectProduction.value = {
      one: 0,
      two: 1,
    };
  } else if (projectType.value === '加氢站') {
    projectStatus.value = {
      plan: 1,
      built: 2,
      operating: 2,
    };
    projectProduction.value = {
      one: 0,
      two: 5,
    };
  }
}

let firstChart = null;
let secondChart = null;
let thirdChart = null;
let chinaChart = null;

function initCharts() {
  var chartDom = document.getElementById('firstChart');
  firstChart = echarts.init(chartDom);
  updateFirstChart();

  var chartDom2 = document.getElementById('secondChart');
  secondChart = echarts.init(chartDom2);
  updateSecondChart();

  var chartDom3 = document.getElementById('thirdChart');
  thirdChart = echarts.init(chartDom3);
  updateThirdChart();

  var chinaChartDom = document.getElementById('chinaChart');
  chinaChart = echarts.init(chinaChartDom);
  updateChinaChart();
}

function updateFirstChart() {
  let firstChartData = null;
  let chartTitle = '';
  if (projectType.value === '电解水制氢') {
    firstChartData = [
      {
        value: 1,
        name: 'PEM',
        itemStyle: { color: '#87CEFA' }, // 轻蓝
      },
    ];
    chartTitle = '技术路线';
  } else if (projectType.value === '化石能源制氢') {
    firstChartData = [
      {
        value: 1,
        name: '煤制氢',
        itemStyle: { color: '#87CEFA' }, // 轻蓝
      },
      {
        value: 1,
        name: '焦炉煤气',
        itemStyle: { color: '#4682B4' }, // 中蓝
      },
    ];
    chartTitle = '氢气来源';
  } else if (projectType.value === '工业副产氢') {
    firstChartData = [
      {
        value: 1,
        name: '焦炭',
        itemStyle: { color: '#87CEFA' }, // 轻蓝
      },
    ];
    chartTitle = '项目类型';
  } else if (projectType.value === '加氢站') {
    firstChartData = [
      {
        value: 5,
        name: '固定站',
        itemStyle: { color: '#87CEFA' }, // 轻蓝
      },
    ];
    chartTitle = '加氢站类型';
  }
  const option = {
    title: {
      text: chartTitle,
    },
    legend: {
      top: 30,
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      left: 'center', // 可选，如果希望水平居中显示图例
    },
    series: {
      top: 40,
      name: '访问来源',
      type: 'pie',
      radius: '70%', // 半径大小
      data: firstChartData,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'inside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  firstChart.setOption(option);
}

function updateSecondChart() {
  let chartData = null;
  let chartTitle = '';
  if (projectType.value === '电解水制氢') {
    chartData = [
      {
        value: 1,
        name: '光伏',
        itemStyle: { color: '#87CEFA' }, // 轻蓝
      },
    ];
    chartTitle = '电源类型';
  } else if (projectType.value === '化石能源制氢') {
    chartData = [
      {
        value: 1,
        name: '高温煤焦油',
        itemStyle: { color: '#87CEFA' }, // 轻蓝
      },
      {
        value: 1,
        name: '合成甲醇',
        itemStyle: { color: '#4682B4' }, // 中蓝
      },
    ];
    chartTitle = '主产品';
  } else if (projectType.value === '工业副产氢') {
    chartData = [
      {
        value: 0,
        name: '暂无数据',
        itemStyle: { color: '#4682B4' }, // 中蓝
      },
    ];
    chartTitle = '氢气供应情况';
  } else if (projectType.value === '加氢站') {
    (chartData = [
      {
        value: 5,
        name: '35MPa',
        itemStyle: { color: '#87CEFA' }, // 轻蓝
      },
    ]),
      (chartTitle = '压力等级');
  }

  const option2 = {
    title: {
      text: chartTitle,
    },
    legend: {
      top: 30,
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      left: 'center', // 可选，如果希望水平居中显示图例
    },
    series: {
      name: '访问来源',
      type: 'pie',
      top: 40,
      radius: '70%', // 半径大小
      data: chartData,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'inside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  secondChart.setOption(option2);
}

function updateThirdChart() {
  let chartData = null;
  let chartTitle = '';
  if (projectType.value === '电解水制氢') {
    chartData = [
      {
        value: 1,
        name: '10000t以下',
        itemStyle: { color: '#87CEFA' }, // 轻蓝
      },
    ];
    chartTitle = '项目氢气产能';
  } else if (projectType.value === '化石能源制氢') {
    chartData = [
      {
        value: 2,
        name: '10000t - 50000t',
        itemStyle: { color: '#87CEFA' }, // 轻蓝
      },
    ];
    chartTitle = '项目氢气产能';
  } else if (projectType.value === '工业副产氢') {
    chartData = [
      {
        value: 1,
        name: '50000t以上',
        itemStyle: { color: '#87CEFA' }, // 中蓝
      },
    ];
    chartTitle = '项目氢气产能';
  } else if (projectType.value === '加氢站') {
    (chartData = [
      {
        value: 5,
        name: '500-1000kg/d',
        itemStyle: { color: '#87CEFA' }, // 中蓝
      },
    ]),
      (chartTitle = '供给能力');
  }

  const option3 = {
    title: {
      text: chartTitle,
    },
    legend: {
      top: 30,
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      left: 'center', // 可选，如果希望水平居中显示图例
    },
    series: {
      name: '访问来源',
      top: 40,
      type: 'pie',
      radius: '70%', // 半径大小
      data: chartData,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'inside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  thirdChart.setOption(option3);
}

function updateChinaChart() {
  let provinceProjectCount = 0;
  let chartData = [];
  if (projectType.value === '电解水制氢') {
    
    chartData = [
      {
        name: '长治',
        value: [113.11, 36.16],
        sales: '电解水制氢项目',
        text: '1个',
      },
    ];
    provinceProjectCount = 1;
  } else if (projectType.value === '化石能源制氢') {
    chartData = [
      {
        name: '吕梁',
        value: [111.1382201, 37.5180245],
        sales: '化石能源制氢项目',
        text: '2个',
      },
    ];
    provinceProjectCount = 2;
  } else if (projectType.value === '工业副产氢') {
    chartData = [
      {
        name: '吕梁',
        value: [111.1382201, 37.5180245],
        sales: '工业副产氢项目',
        text: '1个',
      },
    ];
    provinceProjectCount = 1;
  } else if (projectType.value === '加氢站') {
    (chartData = [
      {
        name: '吕梁',
        value: [111.1382201, 37.5180245],
        sales: '加氢站项目',
        text: '5个',
      },
    ]),
      (provinceProjectCount = 5);
  }

  let fullColorData = [
    { name: '北京市', value: 0 },
    { name: '天津市', value: 0 },
    { name: '河北省', value: 0 },
    { name: '山西省', value: provinceProjectCount },
    { name: '内蒙古自治区', value: 0 },
    { name: '辽宁省', value: 0 },
    { name: '吉林省', value: 0 },
    { name: '黑龙江省', value: 0 },
    { name: '上海市', value: 0 },
    { name: '江苏省', value: 0 },
    { name: '浙江省', value: 0 },
    { name: '安徽省', value: 0 },
    { name: '福建省', value: 0 },
    { name: '江西省', value: 0 },
    { name: '山东省', value: 0 },
    { name: '河南省', value: 0 },
    { name: '湖北省', value: 0 },
    { name: '湖南省', value: 0 },
    { name: '广东省', value: 0 },
    { name: '广西壮族自治区', value: 0 },
    { name: '海南省', value: 0 },
    { name: '重庆市', value: 0 },
    { name: '四川省', value: 0 },
    { name: '贵州省', value: 0 },
    { name: '云南省', value: 0 },
    { name: '西藏自治区', value: 0 },
    { name: '陕西省', value: 0 },
    { name: '甘肃省', value: 0 },
    { name: '青海省', value: 0 },
    { name: '宁夏回族自治区', value: 0 },
    { name: '新疆维吾尔自治区', value: 0 },
    { name: '台湾省', value: 0 },
    { name: '香港特别行政区', value: 0 },
    { name: '澳门特别行政区', value: 0 },
  ];

  var chinaJson = null;
  get('https://www.lxjwzl.cn/json/china.json', {}, true).then((res: any) => {
  // get('http://localhost:8080/json/china.json', {}, true).then((res: any) => {
    chinaJson = res;
    echarts.registerMap('全国', chinaJson);
    const chinaChartOption = {
      //设置一个标题
      title: {
        text: '项目个数',
        x: 'center',
        textStyle: {
          color: '#fff',
          fontSize: '20',
        },
      },
      //鼠标划过省份下弹框
      tooltip: {
        show: true,
        triggerOn: 'click', //点击生效
      },
      geo: {
        map: '全国', //必须写
        roam: false, // 拖拽功能；自选关闭开启
        zoom: 1.3, //地图缩放比例
        center: [105, 36], //地图位置
        //地图省份的样式；包括板块颜色和边框颜色
        itemStyle: {
          areaColor: '#87CEFA',
          borderColor: '#835f5f',
        },
        //省份字体样式；包括是否展示，字体大小和颜色
        label: {
          normal: {
            show: true,
            fontSize: '11.5',
            color: 'rgb(107,102,102)',
          },
        },
        regions: generateRegionsConfig(fullColorData),
        //鼠标划过的高亮设置；包括省份板块颜色和字体等
        emphasis: {
          itemStyle: {
            areaColor: '#d0a3a3',
          },
          label: {
            show: true,
            color: 'rgb(255,255,255)',
          },
        },
      },

      series: [
        //我们的散点2
        {
          name: '个数',
          type: 'scatter',
          coordinateSystem: 'geo', //坐标系统
          data: chartData,
          //地图点的样式；颜色，大小
          symbolSize: 12,
          itemStyle: {
            color: '#f13434',
            shadowBlur: 2,
            shadowColor: '#333',
          },
          //鼠标点击散点的下弹框
          tooltip: {
            show: true,
            triggerOn: 'click',
            formatter: function (data1) {
              let data2 = data1.data;
              return data2.name +  '<br>' + data2.text;
            },
          },
        },

        //涟漪特效2
        {
          name: '项目个数',
          type: 'effectScatter',
          coordinateSystem: 'geo',
          data: chartData, //传入的地图点数据
          symbolSize: 6, //涟漪大小
          showEffectOn: 'render',
          //涟漪效应
          rippleEffect: {
            brushType: 'stroke',
            color: '#f13434',
            period: 10, //周期
            scale: 10, //规模
          },
          hoverAnimation: true, //悬停动画
          //地图点样式
          label: {
            formatter: '{b}',
            position: 'top',
            show: true,
            fontSize: '10',
          },
          itemStyle: {
            color: '#f13434',
            shadowBlur: 2,
            shadowColor: '#333',
          },
          //鼠标点击散点的下弹框
          tooltip: {
            show: true,
            triggerOn: 'click',
            formatter: function (data1) {
              let data2 = data1.data;
              return data2.name +  '<br>' + data2.text;
            },
          },
          zlevel: 1,
        },
      ],
    };

    chinaChart.setOption(chinaChartOption);
  });
}

function generateRegionsConfig(data) {
  return data.map((item) => ({
    name: item.name,
    label: {
      normal: {
        show: item.value > 0,
        fontSize: '11.5',
        color: 'black', // 动态计算颜色
      },
    },
    itemStyle: {
      areaColor: calculateColor(item.value),
      borderColor: '#835f5f',
    },
  }));
}

function calculateColor(value) {
  // 当value <= 0时，颜色为白色
  if (value <= 0) {
    // 浅蓝色 rgb
    return '#87CEFA'; // 直
  }

  // 确保value至少为5，以便于计算
  value = Math.max(value, 5);

  // 蓝色的色相（Hue）保持在220，属于蓝色范围
  // 饱和度（Saturation）随value增加而增加，使颜色更鲜艳，最大饱和度设为100%
  // 亮度（Lightness）保持在中间值，比如50%，确保颜色既不太深也不太浅
  const hue = 220;
  const maxSaturation = 100; // 最大饱和度
  const lightness = 60; // 中间亮度值
  // 每增加5，饱和度增加一定比例，这里为了效果明显，直接按比例增加
  const saturation = Math.min(maxSaturation, (value / 5) * maxSaturation);

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}
</script>

<style scoped></style>
