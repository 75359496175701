<template>
  <el-container class="layout-container" style="height: 100vh;">
    <img :src="require('@/assets/login/side.png')" style="height: 100%; width: 50%;"/>
    <div class="login-main" style="display: flex; flex-direction: column; justify-content: center; align-items: center; align-content: center;height: 100%; width: 50%;">
      <el-form
        label-position="top"
        label-width="100px"
        :model="loginForm"
        style="width: 50%;"
      >
        <h2>登 录</h2>
        <el-button type="primary" style="width: 100%; background-color: rgb(69, 69, 255); box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.20);
}">
          氢界大数据平台统一认证登录
        </el-button>
        <el-divider content-position="center">或</el-divider>
        <el-form-item label="手机号" class="item">
          <el-input v-model="loginForm.phone" placeholder="请输入您的手机号"/>
          <div style="font-size: small; color: rgb(69, 69, 255) ;" @click="$router.push('/register')">没有账号？点击注册</div>
        </el-form-item>
        <el-form-item label="密码" class="item">
          <el-input v-model="loginForm.password" placeholder="请输入您的密码" show-password>
          </el-input>
          <span style="font-size: small; color: rgb(69, 69, 255) ;" @click="$router.push('/forget-password')">忘记密码</span>
        </el-form-item>
        <div>
          <el-button type="primary" style="width: 30%;" @click="Login">
            点 击 登 录
          </el-button>
        </div>
      </el-form>
    </div>
  </el-container>
</template>

<script lang="ts" setup>
import { reactive, onMounted } from 'vue'
import { ElNotification } from 'element-plus'
import { post, getToken } from '@/utils/request'
import {ElMessage} from "element-plus";
import useTokenStore  from '@/store/useToken'

import { useRouter } from "vue-router";
const router = useRouter();

const tokenStore = useTokenStore();

const loginForm = reactive({
  phone: '',
  password: '',
})

onMounted(() => {
  if (getToken()) {
    router.push({path: "/projects"})
  } else {
    ElNotification({
      title: '',
      message: '欢迎您登录',
      type: 'info',
    })
  }
})

function Login() {
  if (loginForm.phone.length == 0 || loginForm.password.length == 0){
    ElNotification({
      title: '提示',
      message: '请输入手机名和密码',
      type: 'warning',
    })
  }
  post('/account/login', {
    phone: loginForm.phone,
    password: loginForm.password,
  })
  .then((res:any) => {
    if(res.code == '200') {
      tokenStore.saveToken(res.data);
      ElMessage.success("登录成功")
      router.push({path: "/projects"})
    } else {
      ElNotification({
        title: '提示',
        message: res.data.msg,
        type: 'warning',
      })
    }
  })
  .catch(error => {
    console.error(error);
  });
}

</script>

<style>
</style>
