<template>
  <el-container class="layout-container">
    <my-aside></my-aside>
    <el-main style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <el-row style="height: 10%; width: 100%;">
        <el-col :span="4" style="display: flex; flex-direction: row;justify-content: center; align-items: center;">
          <img src="../assets/basic-icons/rocket_blue.png" style="height: 43px;" />
          <h2 style="color: #2091F6;">最优方案</h2>
        </el-col>
        <el-col :span="6" :offset="13"
          style=" display: flex; flex-direction: row;justify-content: center; align-items: center;">
          <el-tooltip content="<span><strong>继续编辑</strong></span>" raw-content>
            <el-button type="primary" @click="jumpEditModules()" :icon="Edit" circle />
          </el-tooltip>
          <el-tooltip content="<span><strong>可视化分析</strong></span>" raw-content>
            <el-button type="success" @click="$router.push('/project/vision/' + projectUuid)" :icon="View" circle />
          </el-tooltip>
        </el-col>
      </el-row>
      <div class="result_container">
        <el-row style="width: 100%; margin-top: 2%;">
          <el-col :span="4" v-if="bestResult.target == 'PRICE' && bestResult.product == 'ALCOHOL'">
            <el-statistic title="边缘甲醇价(元/吨)" :value="bestResult.alcoholprice" />
          </el-col>
          <el-col :span="4" v-if="bestResult.target == 'SCALE' && bestResult.product == 'ALCOHOL'">
            <el-statistic title="年制甲醇规模(吨)" :value="bestResult.alcoholscale" />
          </el-col>
          <el-col :span="4" v-if="bestResult.target == 'PRICE' && bestResult.product == 'NH3'">
            <el-statistic title="边缘氨价(元/吨)" :value="bestResult.nh3price" />
          </el-col>
          <el-col :span="4" v-if="bestResult.target == 'SCALE' && bestResult.product == 'NH3'">
            <el-statistic title="年制氨规模(吨)" :value="bestResult.nh3scale" />
          </el-col>
          <el-col :span="4" v-if="bestResult.target == 'INCOME'">
            <el-statistic title="年最佳收益(元)" :value="bestResult.bestIncome" />
          </el-col>
          <el-col :span="4" v-if="bestResult.target == 'PRICE' && bestResult.product == 'H2'">
            <el-statistic title="边缘氢价(元/千克)" :value="bestResult.h2price" />
          </el-col>
          <el-col :span="4" v-if="bestResult.target == 'SCALE' && bestResult.product == 'H2'">
            <el-statistic title="最大制氢规模(千克)" :value="bestResult.h2scale" />
          </el-col>
          <el-col :span="4" v-if="!(bestResult.target == 'PRICE' && bestResult.product == 'H2')">
            <el-statistic title="氢价(元/千克)" :value="bestResult.h2price" />
          </el-col>
          <el-col :span="4" v-if="!(bestResult.target == 'SCALE' && bestResult.product == 'H2')">
            <el-statistic title="氢气年产量(千克)" :value="bestResult.h2scale" />
          </el-col>
          <el-col :span="4">
            <el-statistic title="内部收益率(%)" :value="formatNumber(bestResult.innerProfitRatio)" />
          </el-col>
        </el-row>
        <el-row style="width: 100%;">
          <el-col :span="4">
            <el-statistic title="最佳电解槽规模(千瓦)" :value="formatElectrisis(bestResult.electricityRatedPower)" />
          </el-col>
          <el-col :span="4">
            <el-statistic title="最佳储能规模(千瓦)" :value="formatNumber(bestResult.storageRatedPower)" />
          </el-col>
          <el-col :span="4">
            <el-statistic title="最佳风规模(千瓦)" :value="formatNumber(bestResult.windRatedPower)" />
          </el-col>
          <el-col :span="4">
            <el-statistic title="最佳光规模(千瓦)" :value="formatNumber(bestResult.solarRatedPower)" />
          </el-col>
        </el-row>
        <el-row style="width: 100%;">
          <el-col :span="4">
            <el-statistic title="年制氢耗电量(千瓦时)" :value="formatNumber(bestResult.allElectricity)" />
          </el-col>
          <el-col :span="4">
            <el-statistic title="年上网电量(千瓦时)" :value="formatNumber(bestResult.upGridElectricity)" />
          </el-col>
          <el-col :span="4">
            <el-statistic title="年下网电量(千瓦时)" :value="formatNumber(bestResult.downGridElectricity)" />
          </el-col>
          <el-col :span="4">
            <el-statistic title="年弃电电量(千瓦时)" :value="formatNumber(bestResult.wasteElectricity)" />
          </el-col>
          <el-col :span="4">
            <el-statistic title="风电度电成本(元)" :value="formatNumber(bestResult.windElectricityCost)" />
          </el-col>
          <el-col :span="4" v-if="bestResult.solarRatedPower > 0">
            <el-statistic title="光伏度电成本(元)" :value="formatNumber(bestResult.solarElectricityCost)" />
          </el-col>
        </el-row>
        <el-row style="width: 100%; margin-top: 1%; height: 70%;">
          <el-col :span="24">
            <div class="chart" id="elecEchart"
              style="height: 90%; width: 100%;margin-top: 2%; margin-bottom: 0; padding: 0;"></div>
            <el-statistic title="电解年利用小时数:" :value="formatNumber(bestResult.elecArea)"
              style="height: 10px; margin-top: -30px;" />
          </el-col>
        </el-row>
      </div>
    </el-main>
  </el-container>
</template>

<script lang="ts" setup>
import { reactive, ref, onMounted, getCurrentInstance, } from 'vue'
import MyAside from '../components/Aside.vue'
import { Edit, View } from '@element-plus/icons-vue'
import { useRoute } from 'vue-router';
import { get } from '@/utils/request';
import { ElNotification } from 'element-plus';

const route = useRoute();
const projectUuid = route.params.projectUuid as string;

let bestResult = ref({
  // 总数据
  innerProfitRatio: 0,
  target: '',
  product: '',
  bestIncome: 0,
  h2price: 0,
  h2scale: 0,
  nh3price: 0,
  nh3scale: 0,
  alcoholprice: 0,
  alcoholscale: 0,
  coalprice: 0,
  coalscale: 0,
  // 功率数据
  solarRatedPower: 0,
  windRatedPower: 0,
  lineRatedPower: 0,
  electricityRatedPower: 0,
  storageRatedPower: 0,
  // 电量数据
  upGridElectricity: 0,
  downGridElectricity: 0,
  wasteElectricity: 0,
  windElectricityCost: 0,
  solarElectricityCost: 0,
  allElectricity: 0,
  // 图表数据
  curveHoursType: '24',
  actualElePowers: [],
  wastePowers: [],
  windPowers: [],
  solarPowers: [],
  inGridPowers: [],
  outGridPowers: [],
  instoragePowers: [],
  outstoragePowers: [],
  elecArea: 0
});

let elecEchart = null;
let internalInstance = getCurrentInstance();
let echarts = internalInstance.appContext.config.globalProperties.$echarts;

onMounted(() => {
  get('/project/calculate', {
    projectUuid: projectUuid,
  }).then((res: any) => {
    if (res.code) {
      bestResult.value = res.data;
      console.log(bestResult.value);
      initChart();
    } else {
      ElNotification.error({
        title: '计算错误',
        message: res.msg
      });
    }
  });
})


function initChart() {
  var chartDom = document.getElementById("elecEchart")!;
  elecEchart = echarts.init(chartDom);
  updateChart(elecEchart,
    bestResult.value.actualElePowers,
    bestResult.value.windPowers,
    bestResult.value.solarPowers,
    bestResult.value.inGridPowers,
    bestResult.value.wastePowers,
    bestResult.value.outGridPowers,
    bestResult.value.instoragePowers,
    bestResult.value.outstoragePowers);
}
function jumpEditModules() {
  location.href = '/project/edit/modules/' + projectUuid;
}

function updateChart(chart: any, actualElePower: number[], windPower: number[], solarPower: number[], inGridPower: number[], wastePower: number[], outGridPower: number[], instoragePower: number[], outstoragePower: number[]) {
  let xAxisData = [];
  if (bestResult.value.curveHoursType == '8760') {
    for (var i = 1; i <= 8760; i++) {
      xAxisData.push(i);
    }
  } else {
    for (var i = 1; i <= 24; i++) {
      xAxisData.push(i);
    }
  }
  const option = {
    color: ['pink', 'blue', 'red', 'purple', 'green', 'grey', 'yellow', 'black'], // 图例颜色数组，分别对应每个系列的颜色 
    legend: {
      show: true, // 是否显示
      data: ['风力出力', '光伏出力', '电解曲线', '上网电量', '弃电量', '下网电量', '储能充电', '储能放电'], // 图例名称
      itemWidth: 8, // 图例标记的图形宽度。
      itemGap: 5, // 图例每项之间的间隔。
      textStyle: {
      }
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      name: '时间',
      axisTick: {
        show: true,    // 是否显示坐标轴刻度
        inside: true,     // 坐标轴刻度是否朝内，默认朝外
        length: 5,    //坐标轴刻度的长度        
        lineStyle: {
          color: '#FFF',     //刻度线的颜色
          width: 1,    //坐标轴刻度线宽
          type: 'solid',    //坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
        },
      },
      axisLabel: {
        align: 'center', // 设置标签文本居中对齐
        margin: 8, // 设置标签文本与坐标轴之间的距离，可以根据实际情况调整
      },
    },

    yAxis: {
      type: 'value',
      name: '功率',
      axisLabel: {
        fontSize: 6, // 可以调整标签的字体大小  
        position: 'inside', // 可以尝试将此值改为 'inside'  
        formatter: function (value) {
          return value / 10000 + '万kw'; // 将原始值除以 10000，然后加上单位 '万'  
        }
      }
    },
    series: [
      {
        type: 'line',
        name: '风力出力',
        stack: '风光总量',
        data: windPower,
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 0,
        },
        areaStyle: {
          fillOpacity: 1
        },
        z: 0
      },
      {
        type: 'line',
        name: '光伏出力',
        data: solarPower,
        smooth: true,
        symbol: 'none',
        stack: '风光总量',
        lineStyle: {
          width: 0,
        },
        areaStyle: {
          fillOpacity: 1
        },
        z: 1
      },
      {
        type: 'line',
        name: '电解曲线',
        data: actualElePower,
        smooth: true,
        stack: '消耗',
        symbol: 'line',
        lineStyle: {
          width: 1
        },
        z: 2
      },
      {
        type: 'line',
        name: '上网电量',
        data: inGridPower,
        stack: '消耗',
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 0,
        },
        areaStyle: {
          fillOpacity: 1
        },
        z: 3
      },
      {
        type: 'line',
        name: '弃电量',
        data: wastePower,
        smooth: true,
        stack: '消耗',
        symbol: 'none',
        lineStyle: {
          width: 0,
        },
        areaStyle: {
          fillOpacity: 1
        },
        z: 4
      },
      {
        type: 'line',
        name: '下网电量',
        data: outGridPower,
        smooth: true,
        stack: '风光总量',
        symbol: 'none',
        lineStyle: {
          width: 0,
        },
        areaStyle: {
          fillOpacity: 1
        },
        z: 5
      },
      {
        type: 'line',
        name: '储能充电',
        data: instoragePower,
        smooth: true,
        stack: '消耗',
        symbol: 'none',
        lineStyle: {
          width: 0,
        },
        areaStyle: {
          fillOpacity: 1
        },
        z: 6
      },
      {
        type: 'line',
        name: '储能放电',
        data: outstoragePower,
        smooth: true,
        stack: '风光总量',
        symbol: 'none',
        lineStyle: {
          width: 0,
        },
        areaStyle: {
          fillOpacity: 1
        },
        z: 7
      },
    ]
  };
  chart.setOption(option);
}

function formatNumber(num: number) {
  return num
}

function formatElectrisis(num: number) {
  let integer = Math.ceil(num)
  let length = String(integer).length
  let result = Math.ceil(integer / Math.pow(10, length - 3)) * Math.pow(10, length - 3)
  return result;
}

</script>

<style scoped>
.result_container {
  height: 90%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid;
  border-radius: 20px;
  border-color: #2091F6;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.30);
}
</style>