<template>
  <el-row style="background-color: #2091f6; width: 100%; height: 50px; align-items: center">
    <el-col id="topDiv" style="color: white" :span="5"> {{ h2Company.name }}</el-col>
  </el-row>
  <el-row style="width: 100%; height: 50px; align-items: center">
    <el-col
      :span="6"
      style="display: flex; flex-direction: row; justify-content: start; align-items: start"
    >
      <el-tag type="primary">氢能制取</el-tag>
      <el-tag type="success">氢能加注</el-tag>
      <el-button
        style="
          position: fixed;
          bottom: 20px;
          right: 0px;
          z-index: 9999;
          width: 50px;
          font-size: 10px;
        "
        round
        type="primary"
        @click="scrollToTopDiv"
        >返回顶部</el-button
      >
      <el-button
        style="
          position: fixed;
          bottom: 60px;
          right: 0px;
          z-index: 9999;
          width: 50px;
          font-size: 10px;
        "
        round
        type="primary"
        @click="$router.push('/city')"
        >返回城市</el-button
      >
    </el-col>
    <el-col :span="5" :offset="13">
      <el-button type="primary" style="width: 70%" @click="$router.push('/company/report/dsmkec')"> 微尽调报告 </el-button>
    </el-col>
  </el-row>
  <el-row style="width: 100%; height: 50px; align-items: center">
    <span style="margin-left: 50px"></span>
    <span v-for="(anchor, index) in anchors" :key="index" style="margin-right: 5px">
      <el-button
        text
        style="color: black; font-size: large; font-weight: 600"
        @click="scrollToAnchor(anchor)"
      >
        {{ anchor.label }}
      </el-button>
    </span>
  </el-row>
  <el-row style="width: 100%; height: 400px; align-items: center">
    <el-col :span="23" :offset="1">
      <div class="citySubTitle" id="companyBasic">基本信息</div>
    </el-col>
    <el-col
      style="
        height: 350px;
        border: 1px solid blue;
        border-radius: 10px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
        padding: 10px;
      "
      :span="22"
      :offset="1"
    >
      <Description/>
    </el-col>
  </el-row>
  <el-row style="width: 100%; height: 1100px; align-items: center">
    <el-col :span="23" :offset="1">
      <div class="citySubTitle" id="companyProjects">项目信息</div>
    </el-col>
    <el-col
      style="
        height: 1050px;
        border: 1px solid blue;
        border-radius: 10px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
      "
      :span="22"
      :offset="1"
    >
      <div style="height: 10px; margin-top: 20px; width: 50%"></div>
      <projects />
    </el-col>
  </el-row>
  <el-row style="width: 100%; height: 150px; align-items: center">
    <el-col :span="23" :offset="1">
      <div class="citySubTitle" id="companyPriviligy">知识产权</div>
    </el-col>
    <el-col
      style="
        height: 70px;
        border: 1px solid blue;
        border-radius: 10px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
      "
      :span="22"
      :offset="1"
    >
      <Patients/>
    </el-col>
  </el-row>
  <el-row style="width: 100%; height: 1200px; align-items: center">
    <el-col :span="23" :offset="1">
      <div class="citySubTitle" id="companyPower">企业穿透</div>
    </el-col>
    <el-col
      style="
        height: 1150px;
        border: 1px solid blue;
        border-radius: 10px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
        padding: 20px;
      "
      :span="22"
      :offset="1"
    >
      <el-col :span="23" :offset="1">
        <div class="citySubTitle">穿透信息</div>
      </el-col>
      <el-col
        :span="22"
        :offset="1"
        style="
          height: 650px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: start;
        "
      >
        <Entity />
      </el-col>
      <el-col :span="23" :offset="1">
        <div class="citySubTitle">投融资列表</div>
      </el-col>
      <el-col
        :span="22"
        :offset="1"
        style="
          height: 500px;
          display: flex;
          margin-top: 20px;
          flex-direction: row;
          justify-content: center;
          align-items: start;
        "
      >

      <el-table stripe :data="investList2" max-height="400" style="width: 100%">
        <el-table-column align="center" label="序号" prop="investId" width="50" />
        <el-table-column align="center" label="投资企业名称" prop="companyName" />
        <el-table-column align="center" label="被投企业名称" prop="investedCompanyName" />
        <el-table-column align="center" label="投资金额/万元" prop="investMoney"  />
        <el-table-column align="center" label="持股比例/%" prop="investRatio" width="60" />
      </el-table>
      </el-col>
    </el-col>
  </el-row>
  <el-row style="width: 100%; height: 300px; align-items: center">
    <el-col :span="23" :offset="1">
      <div class="citySubTitle" id="companyNews">企业资讯</div>
    </el-col>
    <el-col
      style="
        border: 1px solid blue;
        border-radius: 10px;
        height: 250px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
      :span="22"
      :offset="1"
    >
      <News/>
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import Entity from './CompanyEntities.vue';
import News from './CompanyNews.vue';
import Projects from './CompanyProjects.vue';
import Description from './CompanyDescription.vue';
import Patients from './Patients.vue';

const h2Company = ref({
  name: '山西鹏飞集团有限公司',
});

const anchors = ref([
  { id: 'companyBasic', label: '基本信息' },
  { id: 'companyProjects', label: '项目信息' },
  { id: 'companyPower', label: '企业穿透' },
  { id: 'companyPriviligy', label: '知识产权' },
  { id: 'companyNews', label: '企业资讯' },
]);

const investList2 = ref([
  {
    investId: '1',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞集团置业投资有限公司',
    investMoney: '5000',
    investRatio: '100',
  },
  {
    investId: '2',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞绿色能源投资有限公司',
    investMoney: '1500',
    investRatio: '30',
  },
  {
    investId: '3',
    companyName: '山西鹏飞集团',
    investedCompanyName: '北京三晋鹏飞产业运营管理有限公司',
    investMoney: '1000',
    investRatio: '80',
  },
  {
    investId: '4',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞水务有限公司',
    investMoney: '5000',
    investRatio: '100',
  },
  {
    investId: '5',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞蓝能新能源科技有限公司',
    investMoney: '5100',
    investRatio: '51',
  },
  {
    investId: '6',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞氢晨新能源科技有限公司',
    investMoney: '1275',
    investRatio: '51',
  },
  {
    investId: '7',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西大华通新能源物流有限公司',
    investMoney: '10000',
    investRatio: '100',
  },
  {
    investId: '8',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞资源综合利用有限公司',
    investMoney: '2000',
    investRatio: '100',
  },
  {
    investId: '9',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鲲鹏氢能源科技有限公司',
    investMoney: '1275',
    investRatio: '51',
  },
  {
    investId: '10',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞智创汽车制造有限公司',
    investMoney: '20000',
    investRatio: '100',
  },
  {
    investId: '11',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西华美投资有限公司',
    investMoney: '50000',
    investRatio: '100',
  },
  {
    investId: '12',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞申能氢能发展有限公司',
    investMoney: '5000',
    investRatio: '50',
  },
  {
    investId: '13',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西盛和矿机新材料科技发展有限公司',
    investMoney: '5100',
    investRatio: '51',
  },
  {
    investId: '14',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西美酒制造有限公司',
    investMoney: '1530',
    investRatio: '51',
  },
  {
    investId: '15',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西鹏飞焦化绿色发展有限公司',
    investMoney: '5000',
    investRatio: '100',
  },
  {
    investId: '16',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西普宏科技发展有限公司',
    investMoney: '2100',
    investRatio: '70',
  },
  {
    investId: '17',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西郑好能源科技有限公司',
    investMoney: '9000',
    investRatio: '100',
  },
  {
    investId: '18',
    companyName: '山西鹏飞集团',
    investedCompanyName: '山西富鸿源能源科技有限公司',
    investMoney: '5000',
    investRatio: '100',
  },
  {
    investId: '19',
    companyName: '山西鹏飞集团',
    investedCompanyName: '孝义市鹏飞国宾友谊酒店有限公司',
    investMoney: '11000',
    investRatio: '55',
  },
  {
    investId: '20',
    companyName: '孝义市鹏飞新能源有限公司',
    investedCompanyName: '山西鹏飞集团',
    investMoney: '6000',
    investRatio: '37.5',
  },
  {
    investId: '21',
    companyName: '孝义市聚慧科技有限公司',
    investedCompanyName: '山西鹏飞集团',
    investMoney: '6000',
    investRatio: '37.5',
  },
  {
    investId: '22',
    companyName: '孝义市聚慧科技有限公司',
    investedCompanyName: '山西鹏飞集团',
    investMoney: '4000',
    investRatio: '25',
  },
]);

// 跳转到指定锚点的方法
const scrollToAnchor = (anchor) => {
  document.getElementById(anchor.id)?.scrollIntoView({ behavior: 'smooth' });
};

// 跳转到指定锚点的方法
const scrollToTopDiv = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  // document.getElementById('topDiv')?.scrollIntoView({ behavior: 'smooth' });
};

</script>

<style scoped>
.el-tag {
  margin-right: 10px;
}

.citySubTitle {
  margin: 0;
  width: 7vw;
  font-size: medium;
  font-weight: 550;
}

.el-table th {
  display: table-cell !important;
}
</style>
