<template>
  <el-row
    type="flex"
    justify="space-between"
    style="padding: 0 2rem;width: 100%"
  >
  <el-col :span="5" class="littleContainer">
    <div class="citySummaryTitle">氢能企业</div>
    <span class="citySummaryNum">{{ h2City.companyNum }} </span> 家
  </el-col>
  <el-col :span="5" class="littleContainer">
    <div class="citySummaryTitle">城市资讯</div>
    <span class="citySummaryNum">{{ h2City.newsNum }} </span> 条
  </el-col>
  <el-col :span="5" class="littleContainer">
    <div class="citySummaryTitle">氢能政策</div>
    <span class="citySummaryNum">{{ h2City.policyNum }} </span> 条
  </el-col>
  <el-col :span="5" class="littleContainer">
    <div class="citySummaryTitle">氢能项目</div>
    <span class="citySummaryNum">{{ h2City.projectNum }} </span> 个
  </el-col>
</el-row>
</template>

<script lang="ts" setup>
import { ref, onMounted, shallowRef, getCurrentInstance, computed } from 'vue';

const h2City = ref({
  name: '鄂尔多斯',
  districtUrl: '',
  introduce:
    '&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp鄂尔多斯市，位于内蒙古自治区西南部，' +
    '地处黄河几字弯河套腹地，北靠黄河，南临古长城，西依贺兰山，东接自治区首府呼和浩特，是内蒙古重要的经济中心、城市集群和呼包鄂城市群的中心城市之一。' +
    '这里不仅有着悠久的历史文化和独特的自然景观，还是国家重要的能源基地和生态屏障。' +
    '<br> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp鄂尔多斯市拥有丰富的自然资源，特别是煤炭、天然气等能源资源储量巨大。近年来，随着经济的持续发展和产业结构的转型升级，鄂尔多' +
    '地处黄河几字弯河套腹地，北靠黄河，南临古长城，西依贺兰山，东接自治区首府呼和浩特，是内蒙古重要的经济中心、城市集群和呼包鄂城市群的中心城市之一。' +
    '这里不仅有着悠久的历史文化和独特的自然景观，还是国家重要的能源基地和生态屏障。',
  companyNum: 10,
  newsNum: 8,
  policyNum: 5,
  projectNum: 89,
  productLine: {},
  companyList: {
    companyName: '',
    companyArea: '',
    companyPreviliage: '',
    companyUuid: '',
  },
  newsList: {
    newsTitle: '',
    newsUuid: '',
  },
  projectList: {
    projectUuid: '',
    projectTitle: '',
    projectType: '',
    projectAddress: '',
    projectStatus: '', // 建成 or 运营
    projectProduction: '', // 产能
    projectElectricitySource: '',
    projectTechniqueRoute: '',
    projectProductDirection: '',
  },
});
</script>

<style scoped>
.citySummaryNum {
  color: #2091f6;
  font-size: 30px;
  font-style: italic;
  font-weight: 550;
}

.citySummaryTitle {
  width: 40%;
  font-size: 11px;
  font-weight: 600;
  margin-top: 3px;
}

.littleContainer {
  border: 0.1px solid;
  border-color: #d4e6f6;
  border-radius: 3px;
  height: 60px;
}
</style>
