<template>
  <el-container class="layout-container">
    <my-aside></my-aside>
    <el-main
      style="
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <el-row style="height: 10%; width: 100%">
        <el-col
          :span="6"
          style="display: flex; flex-direction: row; justify-content: center; align-items: center"
        >
          <img
            :src="require('@/assets/basic-icons/person-center.png')"
            style="height: 35px; margin-right: 5px"
          />
          <span style="font-size: 30px; color: #2091f6">个人中心</span>
        </el-col>
        <el-col
          :span="5"
          :offset="13"
          style="display: flex; flex-direction: row; justify-content: center; align-items: center"
        >
        </el-col>
      </el-row>
      <el-tabs style="height: 90%; width: 90%">
        <el-tab-pane label="基本信息">
          <el-form
            :model="accountForm"
            size="large"
            label-position="center"
            label-width="120px"
            style="margin-top: 50px"
          >
            <el-form-item label="昵称:">
              <el-input
                v-model.number="accountForm.name"
                @blur=""
                size="middle"
                style="width: 240px"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="updateUserBasicInfo" size="middle" style="width: 240px"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="账号管理">
          <el-form
            :model="accountForm"
            size="large"
            label-position="center"
            label-width="120px"
            style="margin-top: 50px"
          >
            <el-form-item label="手机号：">
              <el-input v-model="accountForm.phone" style="width: 240px" />
              <!-- <el-button
                type="danger"
                @click=""
                size="middle"
                style="width: 80px; margin-left: 30px"
                >修改</el-button
              > -->
            </el-form-item>
            <el-form-item label="密码：">
              <el-input v-model="password" style="width: 240px" />
              <!-- <el-button
                type="danger"
                @click=""
                size="middle"
                style="width: 80px; margin-left: 30px"
                >修改</el-button
              > -->
            </el-form-item>
            <el-form-item label="">
            <el-button
                type="danger"
                @click="logOut"
                size="middle"
                style=""
                >退出登录</el-button
              >
            </el-form-item>

          </el-form>
        </el-tab-pane>
        <el-tab-pane label="偏好设置" disabled> 开发中... </el-tab-pane>
        <el-tab-pane label="其他" disabled> 开发中... </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted } from 'vue';
import { get, put } from '@/utils/request';
import MyAside from '@/components/Aside.vue';
import { ElMessage, ElNotification } from 'element-plus';

const accountForm = reactive({
  name: '',
  phone: ''
});

const password = ref('***************');

onMounted(() => {
  get('/user/get', {
    // 
  }).then((res: any) => {
    if (res.code) {
      Object.assign(accountForm, res.data);
    } else {
      ElNotification.error({
        title: '获取个人信息错误',
        message: res.msg,
      });
    }
  });
});

function updateUserBasicInfo() {
  put('/user/basic', {
    name: accountForm.name
  }).then((res: any) => {
    if (res.code == 200) {
      ElNotification({
        message: '更新成功',
        type: 'success',
        offset: 100,
      });
    } else {
      ElNotification({
        title: '提示',
        message: '更新失败',
        type: 'error',
        offset: 100,
      });
    }
  });
}

function logOut() {
  localStorage.removeItem('token')
  localStorage.removeItem('tokenExpiresAt')
  location.href = '/login'
}
</script>
<style scoped>
.box-card {
  border: 1px solid #2091f6;
  border-radius: 30px;
  width: 30%;
  height: 300px;
}
</style>
