<template>
  <el-container class="layout-container">
    <my-aside></my-aside>
    <el-main>
      <el-steps :active="2" align-center finish-status="success">
        <el-step title="基本信息" description="" />
        <el-step title="模式选择" description="" />
        <el-step title="参数设置" description="" />
      </el-steps>
      <el-container class="modify-container">
        <div class="modify-options">
          <div class="module-options" style="width: 250px; height: 100%">
            <h6>请选择您的项目所拥有的模块</h6>
            <div>
              <div class="checkbox">
                <el-checkbox
                  disabled
                  v-model="projectForm.basicChecked"
                  label="基本参数模块"
                  size="large"
                  border
                />
              </div>
              <div class="checkbox">
                <el-checkbox
                  v-model="projectForm.windChecked"
                  label="上游风电模块"
                  size="large"
                  border
                />
              </div>
              <div class="checkbox">
                <el-checkbox
                  v-model="projectForm.solarChecked"
                  label="上游光伏模块"
                  size="large"
                  border
                />
              </div>
              <div class="checkbox">
                <el-checkbox
                  v-model="projectForm.electricityNetChecked"
                  label="中游电网模块"
                  size="large"
                  border
                />
              </div>
              <div class="checkbox">
                <el-checkbox
                  v-model="projectForm.storageChecked"
                  label="中游储能模块"
                  size="large"
                  border
                />
              </div>
              <div class="checkbox">
                <el-checkbox
                  disabled
                  v-model="projectForm.electrolysisChecked"
                  label="中游电解模块"
                  size="large"
                  border
                />
              </div>
              <div v-if="AAproduct == '合成氨'" class="checkbox">
                <el-checkbox
                  v-model="projectForm.nh3Checked"
                  disabled
                  label="下游制氨模块"
                  size="large"
                  border
                />
              </div>
              <div v-else-if="AAproduct == '合成甲醇'" class="checkbox">
                <el-checkbox
                  v-model="projectForm.alcoholChecked"
                  disabled
                  label="下游合成甲醇模块"
                  size="large"
                  border
                />
              </div>
              <div v-else-if="AAproduct == 'COAL'" class="checkbox">
                <el-checkbox
                  v-model="projectForm.coalChecked"
                  disabled
                  label="下游合成可持续航煤模块"
                  size="large"
                  border
                />
              </div>
              <div v-else-if="AAproduct == 'COAL_OIL'" class="checkbox">
                <el-checkbox
                  v-model="projectForm.coalOilChecked"
                  disabled
                  label="下游煤制油模块"
                  size="large"
                  border
                />
              </div>
            </div>
          </div>
          <el-divider direction="vertical" style="height: 100%" />
          <div class="params-options" style="width: 80%; height: 100%">
            <h6>请填写您项目模块的具体参数</h6>
            <ul v-infinite-scroll="load" class="infinite-list" style="overflow: auto">
              <li v-show="projectForm.basicChecked" class="infinite-list-item">
                <el-container
                  class="price-container"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <h6>基本参数模块</h6>
                  <el-form
                    ref="form"
                    :model="basicForm"
                    size="small"
                    label-position="right"
                    label-width="120px"
                  >
                    <el-row style="margin-right: 3%">
                      <el-col :span="8" v-if="AAtarget != '最大规模' && AAproduct == '纯制氢'">
                        <el-form-item label="最小年制氢规模：">
                          <el-input type="number" v-model.number="basicForm.h2scale">
                            <template v-slot:suffix> kg </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" v-else-if="AAtarget != '最大规模' && AAproduct == '合成氨'">
                        <el-form-item label="最小年制氨规模：">
                          <el-input type="number" v-model.number="basicForm.nh3scale">
                            <template v-slot:suffix> 吨 </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col
                        :span="8"
                        v-else-if="AAtarget != '最大规模' && AAproduct == '合成甲醇'"
                      >
                        <el-form-item label="最小年制甲醇规模：">
                          <el-input type="number" v-model.number="basicForm.alcoholscale">
                            <template v-slot:suffix> 吨 </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" v-if="AAtarget != '最大收益'">
                        <el-form-item label="内部收益率：">
                          <el-input type="number" v-model.number="basicForm.irr">
                            <template v-slot:suffix> % </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" v-if="AAtarget != '边缘价格' && AAproduct == '纯制氢'">
                        <el-form-item label="氢价：">
                          <el-input type="number" v-model.number="basicForm.h2price">
                            <template v-slot:suffix> 元/kg </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" v-if="AAtarget != '边缘价格' && AAproduct == '合成氨'">
                        <el-form-item label="氨价：">
                          <el-input type="number" v-model.number="basicForm.nh3price">
                            <template v-slot:suffix> 元/kg </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" v-if="AAtarget != '边缘价格' && AAproduct == '合成甲醇'">
                        <el-form-item label="甲醇价：">
                          <el-input type="number" v-model.number="basicForm.alcoholprice">
                            <template v-slot:suffix> 元/kg </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="折现率：">
                          <el-input type="number" v-model.number="basicForm.discount">
                            <template v-slot:suffix> % </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="贷款比率：">
                          <el-input type="number" v-model.number="basicForm.loanRatio">
                            <template v-slot:suffix> % </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-container>
              </li>
              <li v-show="projectForm.windChecked" class="infinite-list-item">
                <el-container
                  class="wind-container"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                  "
                >
                  <h6>上游风电模块</h6>
                  <el-form
                    ref="form"
                    :model="windForm"
                    size="small"
                    label-position="right"
                    label-width="120px"
                    style="width: 100%"
                  >
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="发电设备购置：">
                          <el-input type="number" v-model.number="windForm.unitBuyEquipmentCost">
                            <template v-slot:suffix> 元/千瓦 </template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="发电设备安装：">
                          <el-input
                            type="number"
                            v-model.number="windForm.unitInstallEquipmentCost"
                          >
                            <template v-slot:suffix> 元/千瓦 </template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="曲线名称类型：">
                          <span>
                            {{ windCurveForm.curveName }} - {{ windCurveForm.province
                            }}{{ windCurveForm.energyType }} - {{ windCurveForm.hoursType }}小时
                          </span>

                          <el-button
                            class="primary"
                            style="width: 40px; margin-left: 8px"
                            @click="chooseWindCurve"
                            >更换</el-button
                          >
                        </el-form-item>
                        <el-form-item label="年利用小时数：">
                          <el-input v-model="windCurveForm.utilizationHours" @change="">
                            <template v-slot:suffix> 小时/年 </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <div
                          class="charts"
                          style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <div
                            class="chart"
                            id="windChart"
                            style="height: 250px; width: 350px"
                          ></div>
                        </div>
                      </el-col>
                    </el-row>
                    <el-collapse :span="24">
                      <el-collapse-item title="其余成本数据">
                        <el-row>
                          <el-col
                            :span="2"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                          <el-col
                            :span="8"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          >
                            <el-form-item label="单位土建工程成本：">
                              <el-input type="number" v-model.number="windForm.unitSoilCost">
                                <template v-slot:suffix> 元/千瓦 </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="土地费用折旧比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="windForm.soilDiscountRatio"
                                :min="0"
                                :max="100"
                                :step="1"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                            <el-form-item label="折旧成本比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="windForm.discountCostRatio"
                                :min="0"
                                :max="100"
                                :step="1"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                          </el-col>
                          <el-col
                            :span="4"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                          <el-col
                            :span="8"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          >
                            <el-form-item label="平均工资：">
                              <el-input type="number" v-model.number="windForm.staffAverageSalary">
                                <template v-slot:suffix> 万元/人 </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="维护成本比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="windForm.maintenanceCostRatio"
                                :min="0"
                                :max="100"
                                :step="0.01"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                            <el-form-item label="保险费比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="windForm.insuranceCostRatio"
                                :min="0"
                                :max="100"
                                :step="0.01"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                            <el-form-item label="故障成本比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="windForm.problemCostRatio"
                                :min="0"
                                :max="100"
                                :step="0.1"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                          </el-col>
                          <el-col
                            :span="2"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                        </el-row>
                      </el-collapse-item>
                    </el-collapse>
                  </el-form>
                </el-container>
              </li>
              <li v-show="projectForm.solarChecked" class="infinite-list-item">
                <el-container
                  class="wind-container"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                  "
                >
                  <h6>上游光伏模块</h6>
                  <el-form
                    ref="form"
                    :model="windForm"
                    size="small"
                    label-position="right"
                    label-width="120px"
                    style="width: 100%"
                  >
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="发电设备购置：">
                          <el-input type="number" v-model.number="solarForm.unitBuyEquipmentCost">
                            <template v-slot:suffix> 元/千瓦 </template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="发电设备安装：">
                          <el-input
                            type="number"
                            v-model.number="solarForm.unitInstallEquipmentCost"
                          >
                            <template v-slot:suffix> 元/千瓦 </template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="曲线名称类型：">
                          <span>
                            {{ solarCurveForm.curveName }} - {{ solarCurveForm.province
                            }}{{ solarCurveForm.energyType }} - {{ solarCurveForm.hoursType }}小时
                          </span>
                          <el-button
                            class="primary"
                            style="width: 40px; margin-left: 8px"
                            @click="chooseSolarCurve"
                            >更换</el-button
                          >
                        </el-form-item>
                        <el-form-item label="年利用小时数：">
                          <el-input v-model="windCurveForm.utilizationHours" @change="">
                            <template v-slot:suffix> 小时/年 </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <div
                          class="charts"
                          style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <div
                            class="chart"
                            id="solarChart"
                            style="height: 250px; width: 350px"
                          ></div>
                        </div>
                      </el-col>
                    </el-row>
                    <el-collapse :span="24">
                      <el-collapse-item title="其余成本数据">
                        <el-row>
                          <el-col
                            :span="2"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                          <el-col
                            :span="8"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          >
                            <el-form-item label="单位土建工程成本：">
                              <el-input type="number" v-model.number="solarForm.unitSoilCost">
                                <template v-slot:suffix> 元/千瓦 </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="土地费用折旧比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="solarForm.soilDiscountRatio"
                                :min="0"
                                :max="100"
                                :step="1"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                            <el-form-item label="折旧成本比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="solarForm.discountCostRatio"
                                :min="0"
                                :max="100"
                                :step="1"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                          </el-col>
                          <el-col
                            :span="4"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                          <el-col
                            :span="8"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          >
                            <el-form-item label="平均工资：">
                              <el-input type="number" v-model.number="solarForm.staffAverageSalary">
                                <template v-slot:suffix> 万元/人 </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="维护成本比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="solarForm.maintenanceCostRatio"
                                :min="0"
                                :max="100"
                                :step="0.01"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                            <el-form-item label="保险费比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="solarForm.insuranceCostRatio"
                                :min="0"
                                :max="100"
                                :step="0.01"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                            <el-form-item label="故障成本比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="solarForm.problemCostRatio"
                                :min="0"
                                :max="100"
                                :step="0.1"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                          </el-col>
                          <el-col
                            :span="2"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                        </el-row>
                      </el-collapse-item>
                    </el-collapse>
                  </el-form>
                </el-container>
              </li>
              <li v-show="projectForm.electricityNetChecked" class="infinite-list-item">
                <el-container
                  class="electricity-net-container"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                  "
                >
                  <h6>中游电网模块</h6>
                  <el-form
                    ref="form"
                    :model="electricityNetForm"
                    size="small"
                    label-position="right"
                    label-width="120px"
                    style="width: 100%"
                  >
                    <el-row>
                      <el-col :span="8" :offset="2">
                        <el-form-item label="上网比例限制：">
                          <el-input-number
                            class="input-number"
                            v-model.number="electricityNetForm.upElectricityRatio"
                            :min="0"
                            :max="100"
                            :step="1"
                            step-strictly
                          >
                          </el-input-number>
                          <span style="color: rgb(120, 119, 119)">%</span>
                        </el-form-item>
                        <el-form-item label="下网比例限制：">
                          <el-input-number
                            class="input-number"
                            v-model.number="electricityNetForm.downElectricityRatio"
                            :min="0"
                            :max="100"
                            :step="1"
                            step-strictly
                          >
                          </el-input-number>
                          <span style="color: rgb(120, 119, 119)">%</span>
                        </el-form-item>
                        <el-form-item label="弃电比例限制：">
                          <el-input-number
                            class="input-number"
                            v-model.number="electricityNetForm.wasteElectricityRatio"
                            :min="0"
                            :max="100"
                            :step="1"
                            step-strictly
                          >
                          </el-input-number>
                          <span style="color: rgb(120, 119, 119)">%</span>
                        </el-form-item>
                        <el-form-item label="线路单位购置成本：">
                          <el-input
                            type="number"
                            v-model.number="electricityNetForm.unitBuyEquipmentCost"
                          >
                            <template v-slot:suffix> 元/千米 </template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="线路里程：">
                          <el-input type="number" v-model.number="electricityNetForm.mills">
                            <template v-slot:suffix> 千米 </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" :offset="4">
                        <el-form-item label="线路单位安装成本：">
                          <el-input
                            type="number"
                            v-model.number="electricityNetForm.unitInstallEquipmentCost"
                          >
                            <template v-slot:suffix> 元/千米 </template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="上网电价：">
                          <el-input type="number" v-model.number="electricityNetForm.upPrice">
                            <template v-slot:suffix> 元/度 </template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="下网尖峰电价：">
                          <el-input type="number" v-model.number="electricityNetForm.downHHPrice">
                            <template v-slot:suffix> 元/度 </template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="下网高峰电价：">
                          <el-input type="number" v-model.number="electricityNetForm.downHPrice">
                            <template v-slot:suffix> 元/度 </template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="下网平时电价：">
                          <el-input type="number" v-model.number="electricityNetForm.downMPrice">
                            <template v-slot:suffix> 元/度 </template>
                          </el-input>
                        </el-form-item>
                        <el-form-item label="下网低谷电价：">
                          <el-input type="number" v-model.number="electricityNetForm.downLPrice">
                            <template v-slot:suffix> 元/度 </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-collapse :span="24">
                      <el-collapse-item title="其余成本数据">
                        <el-row>
                          <el-col
                            :span="2"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                          <el-col
                            :span="8"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          >
                            <el-form-item label="单位土建工程成本：">
                              <el-input
                                type="number"
                                v-model.number="electricityNetForm.unitSoilCost"
                              >
                                <template v-slot:suffix> 万元 </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="土地费用折旧比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="electricityNetForm.soilDiscountRatio"
                                :min="0"
                                :max="100"
                                :step="1"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                            <el-form-item label="折旧成本比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="electricityNetForm.discountCostRatio"
                                :min="0"
                                :max="100"
                                :step="1"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                            <el-form-item label="维护成本比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="electricityNetForm.maintenanceCostRatio"
                                :min="0"
                                :max="100"
                                :step="0.01"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                          </el-col>
                          <el-col
                            :span="4"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                          <el-col
                            :span="8"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          >
                            <el-form-item label="保险费比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="electricityNetForm.insuranceCostRatio"
                                :min="0"
                                :max="100"
                                :step="0.01"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                            <el-form-item label="故障成本比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="electricityNetForm.problemCostRatio"
                                :min="0"
                                :max="100"
                                :step="0.1"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                            <el-form-item label="平均工资：">
                              <el-input
                                type="number"
                                v-model.number="electricityNetForm.staffAverageSalary"
                              >
                                <template v-slot:suffix> 万元/人 </template>
                              </el-input>
                            </el-form-item>
                          </el-col>
                          <el-col
                            :span="2"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                        </el-row>
                      </el-collapse-item>
                    </el-collapse>
                  </el-form>
                </el-container>
              </li>
              <li v-show="projectForm.storageChecked" class="infinite-list-item">
                <el-container
                  class="price-container"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                  "
                >
                  <h6>中游储能模块</h6>
                  <el-form
                    ref="form"
                    :model="storageForm"
                    size="small"
                    label-position="right"
                    label-width="120px"
                    style="width: 100%"
                  >
                    <el-row>
                      <el-col :span="8" :offset="2">
                        <el-form-item label="单位容量购置成本：">
                          <el-input type="number" v-model.number="storageForm.unitBuyEquipmentCost">
                            <template v-slot:suffix> 元/千瓦时 </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" :offset="4">
                        <el-form-item label="单位电池安装成本：">
                          <el-input
                            type="number"
                            v-model.number="storageForm.unitInstallEquipmentCost"
                          >
                            <template v-slot:suffix> 元/千瓦时 </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-collapse :span="24">
                      <el-collapse-item title="其余成本数据">
                        <el-row>
                          <el-col
                            :span="2"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                          <el-col
                            :span="8"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          >
                            <el-form-item label="维护成本比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="storageForm.maintenanceCostRatio"
                                :min="0"
                                :max="100"
                                :step="0.01"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                            <el-form-item label="保险费比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="storageForm.insuranceCostRatio"
                                :min="0"
                                :max="100"
                                :step="0.01"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                            <el-form-item label="故障成本比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="storageForm.problemCostRatio"
                                :min="0"
                                :max="100"
                                :step="0.1"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                            <el-form-item label="平均工资：">
                              <el-input
                                type="number"
                                v-model.number="storageForm.staffAverageSalary"
                              >
                                <template v-slot:suffix> 万元/人 </template>
                              </el-input>
                            </el-form-item>
                          </el-col>
                          <el-col
                            :span="4"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                          <el-col
                            :span="8"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          >
                            <el-form-item label="单位土建工程成本：">
                              <el-input type="number" v-model.number="storageForm.unitSoilCost">
                                <template v-slot:suffix> 元/千瓦 </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="土地费用折旧比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="storageForm.soilDiscountRatio"
                                :min="0"
                                :max="100"
                                :step="1"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                            <el-form-item label="折旧成本比例：">
                              <el-input-number
                                class="input-number"
                                v-model.number="storageForm.discountCostRatio"
                                :min="0"
                                :max="100"
                                :step="1"
                                step-strictly
                              >
                              </el-input-number>
                              <span style="color: rgb(120, 119, 119)">%</span>
                            </el-form-item>
                          </el-col>
                          <el-col
                            :span="2"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                        </el-row>
                      </el-collapse-item>
                    </el-collapse>
                  </el-form>
                </el-container>
              </li>
              <li v-show="projectForm.electrolysisChecked" class="infinite-list-item">
                <el-container
                  class="electrolysis-container"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                  "
                >
                  <h6>中游电解模块</h6>
                  <el-form
                    ref="form"
                    :model="electrolysisForm"
                    size="small"
                    label-position="right"
                    label-width="140px"
                    style="width: 100%"
                  >
                    <el-row>
                      <el-col :span="8" :offset="2">
                        <el-form-item label="单位电解槽购置成本：">
                          <el-input
                            type="number"
                            v-model.number="electrolysisForm.unitBuyEquipmentCost"
                          >
                            <template v-slot:suffix> 元/千瓦 </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" :offset="4">
                        <el-form-item label="单位电解槽安装成本：">
                          <el-input
                            type="number"
                            v-model.number="electrolysisForm.unitInstallEquipmentCost"
                          >
                            <template v-slot:suffix> 元/千瓦 </template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-collapse :span="24">
                      <el-collapse-item title="其余成本数据">
                        <el-row>
                          <el-col
                            :span="2"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                          <el-col
                            :span="8"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          >
                            <el-form-item label="故障成本比例：">
                              <el-input
                                type="number"
                                v-model.number="electrolysisForm.problemCostRatio"
                              >
                                <template v-slot:suffix> % </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="平均工资：">
                              <el-input
                                type="number"
                                v-model.number="electrolysisForm.staffAverageSalary"
                              >
                                <template v-slot:suffix> 万元/人 </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="水价：">
                              <el-input type="number" v-model.number="electrolysisForm.waterPrice">
                                <template v-slot:suffix> 元/吨 </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="单公斤制氢耗电量：">
                              <el-input
                                type="number"
                                v-model.number="electrolysisForm.electricityConsumptionOfUnitedH2"
                              >
                                <template v-slot:suffix> 千瓦时 </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="单公斤制氢耗水量：">
                              <el-input
                                type="number"
                                v-model.number="electrolysisForm.waterConsumptionOfUnitedH2"
                              >
                                <template v-slot:suffix> 千克 </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="其他费用：">
                              <el-input type="number" v-model.number="electrolysisForm.otherCost">
                                <template v-slot:suffix> 元/千瓦 </template>
                              </el-input>
                            </el-form-item>
                          </el-col>
                          <el-col
                            :span="4"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                          <el-col
                            :span="8"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          >
                            <el-form-item label="单位土建工程成本：">
                              <el-input
                                type="number"
                                v-model.number="electrolysisForm.unitSoilCost"
                              >
                                <template v-slot:suffix> 元/千瓦 </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="土地费用折旧比例：">
                              <el-input
                                type="number"
                                v-model.number="electrolysisForm.soilDiscountRatio"
                              >
                                <template v-slot:suffix> % </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="折旧成本比例：">
                              <el-input
                                type="number"
                                v-model.number="electrolysisForm.discountCostRatio"
                              >
                                <template v-slot:suffix> % </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="维护成本比例：">
                              <el-input
                                type="number"
                                v-model.number="electrolysisForm.maintenanceCostRatio"
                              >
                                <template v-slot:suffix> % </template>
                              </el-input>
                            </el-form-item>
                            <el-form-item label="保险费比例：">
                              <el-input
                                type="number"
                                v-model.number="electrolysisForm.insuranceCostRatio"
                              >
                                <template v-slot:suffix> % </template>
                              </el-input>
                            </el-form-item>
                          </el-col>
                          <el-col
                            :span="2"
                            style="
                              background: var(--el-color-primary-light-9);
                              color: var(--el-color-primary);
                            "
                          ></el-col>
                        </el-row>
                      </el-collapse-item>
                    </el-collapse>
                  </el-form>
                </el-container>
              </li>
            </ul>
          </div>
        </div>
        <div class="modify-btns">
          <el-button class="modify-btn" type="success" @click="goToChoose()">上一步</el-button>
          <el-button class="modify-btn" type="primary" @click="updateProject()"
            >保存并生成优化结果</el-button
          >
        </div>
      </el-container>
    </el-main>
  </el-container>
  <el-dialog
    v-model="changeWindCurveDialogVisible"
    title="请选择风力曲线"
    width="700"
    style="border-radius: 10px"
    center
  >
    <el-row>
      <el-input
        v-model="searchedCurveName"
        placeholder="请输入风力曲线名称"
        style="width: 30%"
      >
      </el-input>
      <el-tooltip content="<span><strong> 获取系统内置曲线请输入【内置曲线】</strong></span>" raw-content>
          <img :src="require('@/assets/basic-icons/question-blue.png')" style="width: 3%; height:80%" />
        </el-tooltip>
      <el-input
        v-model="searchedCurveProvince"
        placeholder="请输入风力曲线省份"
        style="width: 30%; margin-left: 7%;"
      >
    </el-input>
      <el-button type="primary" @click="searchWindCurve" style="width: 20%;margin-left: 10%;">搜索</el-button>
      <el-table empty-text="暂无数据" :data="curvesList" style="width: 100%;  margin-top: 20px;" @row-click="changeCurve">
        <el-table-column fixed label="曲线名称" prop="curveName" width="180" />
        <el-table-column label="类别" sortable prop="energyType" width="180" />
        <el-table-column label="类型" sortable prop="hoursType" width="180" />
        <el-table-column fixed="right" label="操作" width="100">
          <template #default="{ row }">
            <!-- 将查看曲线的点击事件更改为行点击处理 -->
            <el-button size="small" @click="() => confirmWindCurve(row.curveId)">
              选择该曲线
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </el-dialog>
  <el-dialog
    v-model="changeSolarCurveDialogVisible"
    title="请选择光伏曲线"
    width="700"
    style="border-radius: 10px"
    center
  >
  <el-row>
      <el-input
        v-model="searchedCurveName"
        placeholder="请输入光伏曲线名称"
        style="width: 30%"
      ></el-input>
      <el-input
        v-model="searchedCurveProvince"
        placeholder="请输入光伏曲线省份"
        style="width: 30%; margin-left: 10%;"
      ></el-input>
      <el-button empty-text="暂无数据" type="primary" @click="searchSolarCurve" style="width: 20%;margin-left: 10%;">搜索</el-button>
      <el-table :data="curvesList" style="width: 100%; margin-top: 20px;" @row-click="changeCurve">
        <el-table-column fixed label="曲线名称" prop="curveName" width="180" />
        <el-table-column label="类别" sortable prop="energyType" width="180" />
        <el-table-column label="类型" sortable prop="hoursType" width="180" />
        <el-table-column fixed="right" label="操作" width="100">
          <template #default="{ row }">
            <!-- 将查看曲线的点击事件更改为行点击处理 -->
            <el-button size="small" @click="() => confirmSolarCurve(row.curveId)">
              选择该曲线
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </el-dialog>
</template>
<script lang="ts" setup>
import { ref, reactive, getCurrentInstance, onMounted, watch } from 'vue';
import MyAside from '../components/Aside.vue';
import { ElNotification } from 'element-plus';
import { useRoute } from 'vue-router';
import { get, put } from '@/utils/request';
let internalInstance = getCurrentInstance();
let echarts = internalInstance.appContext.config.globalProperties.$echarts;

const route = useRoute();
const projectUuid = route.params.projectUuid as string;
const count = ref(0);
const load = () => {
  count.value = 10;
};
const emit = defineEmits(['goToChoose']);

const searchedCurveName = ref('');

const searchedCurveProvince = ref('');

const curvesList = ref([]);

function confirmWindCurve(curveId: number) {
  Object.assign(windCurveForm, reactive(curvesList.value[0]));
  updateWindChart(JSON.parse(windCurveForm.curveData));
  windForm.curveId = curveId;
  changeWindCurveDialogVisible.value = false;
}

function confirmSolarCurve(curveId: number) {
  Object.assign(solarCurveForm, reactive(curvesList.value[0]));
  updateSolarChart(JSON.parse(solarCurveForm.curveData));
  solarForm.curveId = curveId;
  changeSolarCurveDialogVisible.value = false;
}

function searchWindCurve() {
  searchCurve('风');
}

function searchSolarCurve() {
  searchCurve('光');
}

function searchCurve(type: string) {
  get('/data/curve', {
    curveName: searchedCurveName.value,
    energyType: type,
    province: searchedCurveProvince.value
  }).then((res: any) => {
    if (res.code) {
      if (res.data != null) {
        curvesList.value[0] = res.data;
      }
    } else {
      ElNotification({
        title: '失败',
        message: '查询失败',
        type: 'error',
      });
    }
  });
}

let AAproduct = ref('');
let AAtarget = ref('');
let AAprovince = ref('');

const projectForm = reactive({
  projectUuid: '',
  basicChecked: true,
  windChecked: true,
  solarChecked: true,
  electricityNetChecked: true,
  storageChecked: true,
  nh3Checked: true,
  alcoholChecked: true,
  coalChecked: true,
  coalOilChecked: true,
  electrolysisChecked: true,
});

const basicForm = reactive({
  discount: 4,
  loanRatio: 20,
  irr: 6,
  nh3price: 2.3,
  nh3scale: 30000,
  alcoholprice: 2.3,
  alcoholscale: 10000,
  h2price: 20,
  h2scale: 54000000,
  totalWS: 500,
});

const windForm = reactive({
  utilizationHours: 0,
  unitBuyEquipmentCost: 5000,
  unitInstallEquipmentCost: 900,

  unitSoilCost: 260,
  soilDiscountRatio: 5,
  staffCount: 50, // 5万千瓦及以下14人，5-15万千瓦19人，之后每5万千瓦增加5人
  staffAverageSalary: 18,

  discountCostRatio: 5,
  maintenanceCostRatio: 3, // 运营期第1~3年取0.5%,4~8年取1%,9~14年取1.5%，15~20年取2%
  insuranceCostRatio: 0.15,
  problemCostRatio: 1.5,

  curveHoursType: '24',
  curveType: '2',
  province: '',
  curveId: -1,
});

const windCurveForm = reactive({
  hoursType: '',
  energyType: '',
  curveId: -1,
  utilizationHours: '',
  curveName: '',
  province: '',
  curveData: '',
});

const solarForm = reactive({
  utilizationHours: 0,
  unitBuyEquipmentCost: 5000,
  unitInstallEquipmentCost: 900,

  unitSoilCost: 260,
  soilDiscountRatio: 5,
  staffCount: 50, // 5万千瓦及以下14人，5-15万千瓦19人，之后每5万千瓦增加5人
  staffAverageSalary: 18,

  discountCostRatio: 5,
  maintenanceCostRatio: 3, // 运营期第1~3年取0.5%,4~8年取1%,9~14年取1.5%，15~20年取2%
  insuranceCostRatio: 0.15,
  problemCostRatio: 1.5,

  curveHoursType: '24',
  curveType: '2',
  province: '',
  curveId: -1,
});

const solarCurveForm = reactive({
  hoursType: '',
  energyType: '',
  curveId: -1,
  utilizationHours: '',
  curveName: '',
  province: '',
  curveData: '',
});

const electricityNetForm = reactive({
  upElectricityRatio: 10,
  downElectricityRatio: 3,
  wasteElectricityRatio: 0,
  unitBuyEquipmentCost: 20000,
  mills: 50,
  unitInstallEquipmentCost: 20000,
  upPrice: 0.21,
  downHHPrice: 0.1,
  downHPrice: 0.1,
  downMPrice: 0.1,
  downLPrice: 0.1,
  unitSoilCost: 10,

  discountCostRatio: 5,
  soilDiscountRatio: 5,
  maintenanceCostRatio: 0.15,
  insuranceCostRatio: 0.15,
  problemCostRatio: 1.5,
  staffAverageSalary: 18,
  province: '',
});

const storageForm = reactive({
  unitBuyEquipmentCost: 2000,
  unitInstallEquipmentCost: 200,

  unitSoilCost: 10,
  soilDiscountRatio: 5,

  discountCostRatio: 5,

  maintenanceCostRatio: 0.15,
  insuranceCostRatio: 0.15,
  problemCostRatio: 1.5,
  staffAverageSalary: 18,
  staffCount: 10,
});

const electrolysisForm = reactive({
  unitBuyEquipmentCost: 1400,
  unitInstallEquipmentCost: 200,

  waterPrice: 3,
  electricityConsumptionOfUnitedH2: 56.28,
  waterConsumptionOfUnitedH2: 11.76,

  unitSoilCost: 200,
  discountCostRatio: 5,
  soilDiscountRatio: 5,
  maintenanceCostRatio: 3, // 运营期第1~3年取0.5%,4~8年取1%,9~14年取1.5%，15~20年取2%
  insuranceCostRatio: 0.15,
  problemCostRatio: 1.5,
  staffCount: 14, // 5万千瓦及以下14人，5-15万千瓦19人，之后每5万千瓦增加5人
  staffAverageSalary: 18,
  otherCost: 250,
});

const changeWindCurveDialogVisible = ref(false);
const changeSolarCurveDialogVisible = ref(false);

onMounted(() => {
  get('/project/get', {
    projectUuid: projectUuid,
  }).then((res: any) => {
    if (res.code == 200) {
      var completedProject = res.data;
      Object.assign(projectForm, reactive(completedProject.project));
      Object.assign(basicForm, reactive(completedProject.basic));
      Object.assign(windForm, reactive(completedProject.wind));
      Object.assign(windCurveForm, reactive(completedProject.windCurve));
      Object.assign(solarForm, reactive(completedProject.solar));
      Object.assign(solarCurveForm, reactive(completedProject.solarCurve));
      Object.assign(electricityNetForm, reactive(completedProject.electricityNet));
      Object.assign(storageForm, reactive(completedProject.storage));
      Object.assign(electrolysisForm, reactive(completedProject.electrolysis));
      AAproduct.value = completedProject.project.product;
      AAtarget.value = completedProject.project.target;
      AAprovince.value = completedProject.project.projectProvince;

      updateWindChart(JSON.parse(windCurveForm.curveData));
      updateSolarChart(JSON.parse(solarCurveForm.curveData));

      if (AAproduct.value == 'NH3') {
        projectForm.nh3Checked = true;
      } else {
        projectForm.nh3Checked = false;
      }
      if (AAproduct.value == 'ALCOHOL') {
        projectForm.alcoholChecked = true;
      } else {
        projectForm.alcoholChecked = false;
      }
    } else {
      ElNotification({
        title: '提示',
        message: '获取项目信息失败',
        type: 'error',
        offset: 100,
      });
    }
  });
});

watch(
  () => [projectForm.windChecked, projectForm.solarChecked],
  ([newWindVal, newSolarVal]) => {
    if (!newWindVal && !newSolarVal) {
      ElNotification({
        title: '提示',
        message: '上游模块风力和光伏请至少选择一种',
        type: 'warning',
        offset: 100,
      });
      projectForm.windChecked = true;
      projectForm.solarChecked = true;
    }
  },
  { deep: true },
);

function updateWindChart(curveData: any) {
  var chartDom = document.getElementById('windChart');
  var chart = echarts.init(chartDom);
  const option = {
    xAxis: {
      type: 'category',
      name: '日内时间',
      data: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
      ],
      nameLocation: 'center',
      nameGap: 30,
    },
    yAxis: {
      type: 'value',
      name: '风机满发比例',
      nameGap: 30,
    },
    series: [
      {
        type: 'line',
        name: '',
        data: curveData,
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 2,
        },
      },
    ],
  };
  chart.setOption(option);
}

function goToChoose() {
  location.href = '/project/edit/tp/' + projectUuid;
}

function changeCurve() {}

function changeUtilizationHours(value) {
  // let index = 0;
  // if (windForm.curveHoursType == '8760') {
  //     index = 8760;
  //   } else {
  //     index = 24;
  //   }
  // for(var j = 0; j < index; j++) {
  //   windPowers.value[j] = windPowers.value[j] * value / typicalUtilizationHours; // 将功率数据转为浮点数
  // }
  // updateChart(windEchart, windDatas.value, windPowers.value);
}

function updateSolarChart(curveData: any) {
  var chartDom = document.getElementById('solarChart');
  var chart = echarts.init(chartDom);
  const option = {
    xAxis: {
      type: 'category',
      name: '日内时间',
      data: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
      ],
      nameLocation: 'center',
      nameGap: 30,
    },
    yAxis: {
      type: 'value',
      name: '光伏满发比例',
      nameGap: 30,
    },
    series: [
      {
        type: 'line',
        name: '',
        data: curveData,
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 2,
        },
      },
    ],
  };
  chart.setOption(option);
}

function updateProject() {
  put('/project/update', {
    project: projectForm,
    basic: basicForm,
    wind: windForm,
    solar: solarForm,
    electricityNet: electricityNetForm,
    storage: storageForm,
    electrolysis: electrolysisForm,
  }).then((res: any) => {
    if (res.code == 200) {
      window.location.href = '/project/result/' + projectUuid;
    } else {
      ElNotification({
        title: '提示',
        message: '保存失败',
        type: 'error',
        offset: 100,
      });
    }
  });
}

function chooseWindCurve() {
  changeWindCurveDialogVisible.value = true;
}

function chooseSolarCurve() {
  changeSolarCurveDialogVisible.value = true;
}

</script>

<style scoped>
.modify-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.el-input {
  width: 190px;
}

.modify-options {
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid;
  border-radius: 20px;
  border-color: #2091f6;
  margin-top: 20px;
  height: 70vh;
  width: 80vw;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.3);
}

.module-options {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}

.params-options {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}

.checkbox {
  margin-bottom: 20px;
}

.modify-btn {
  height: 39px;
  margin-top: 40px;
  width: 170px;
}

.infinite-list {
  height: 100%;
  width: 100%;
  padding: 0;
  margin-top: -10px;
  margin-bottom: 10px;
  list-style: none;
}

.infinite-list .infinite-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
  margin: 10px;
}

.infinite-list .infinite-list-item + .list-item {
  margin-top: 10px;
}

.model-ratio-group {
  .el-radio__input.is-checked + .el-radio__label {
    color: white;
    font-weight: 1000;
  }

  .el-radio.is-bordered.is-checked {
    border-color: white;
  }

  .el-radio__label {
    margin-left: 60px;
  }

  .el-radio {
    text-align: center;
    padding: 6px 15px 0 15px;
    color: rgb(208, 217, 253);
    border-radius: var(--el-border-radius-base);
    height: 28px;
    width: 250px;
    font-size: 16px;
  }
}

.modify-btns {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2%;
}

.collapse-title {
  flex: 0 1 54%;
  order: 1;
}

.el-collapse {
  border: none;
  width: 100%;
}

.el-collapse-item {
  position: relative;
  width: 100%;
}

::v-deep .el-collapse-item__header {
  bottom: -40px;
  width: 100%;
  text-align: center;
  color: rgb(148, 148, 148);
  background-color: var(--el-color-primary-light-9);
  font-size: small;
}

::v-deep .el-collapse-item__content {
  padding-bottom: 0;
}

.el-collapse-item__arrow,
.el-tabs__nav {
  transform: rotate(90deg);
}

::v-deep .el-collapse-item__arrow.is-active {
  transform: rotate(90deg);
}
</style>
