<template>
  <el-row
    style="
      height: 925px;
      width: 100%;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
      margin-bottom: 20px;
      padding-top: 50px;
    "
  >
    <el-col :span="8" :offset="2" style="height: 50px; font-size: 20px; font-weight: 500">
      二、氢能产业经营情况
    </el-col>
    <el-col :span="14"> </el-col>
    <el-col :span="20" :offset="2">
      <div style="word-wrap: break-word; text-align: left">
        &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp
        山西鹏飞集团公司在全国共有9个氢能项目，电解水制氢项目1个，化石能源制氢项目2个，工业副产氢项目项目1个，加氢站项目5个，分布如下：
      </div>
    </el-col>
    <el-col :span="20" :offset="2" style="height: 850px">
      <div id="chinaChart" style="width: 600px; height: 600px"></div>
      图2 山西鹏飞集团公司全国产业布局
    </el-col>
  </el-row>
  <el-row
    style="
      height: 925px;
      width: 100%;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
      margin-bottom: 20px;
      padding-top: 50px;
    "
  >
    <el-col :span="6" style="height: 30px; font-size: 15px; font-weight: 500">
      1.电解水制氢
    </el-col>
    <el-col :span="18"> </el-col>
    <el-col :span="20" :offset="2">
      <div style="word-wrap: break-word; text-align: left">
        &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp从区域分布看，山西鹏飞集团公司电解水制氢项目主要分布在山西省；从技术路线来看，该公司PEM项目数量1个，项目规模达到120兆瓦，氢气产能达到2000吨。
      </div>
    </el-col>
    <el-col :span="20" :offset="2" style="height: 400px">
      <div class="pieChart" id="secondChart" style="height: 400px; width: 100%"></div>
      图3 电解水制氢项目技术路线
    </el-col>
    <el-col :span="20" :offset="2" style="height: 450px">
      <div
        style="
          width: 100%;
          height: 50px;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          color: white;
          background-color: darkblue;
        "
      >
      电解水制氢项目列表
      </div>
      <el-table border stripe :data="projectList2" size="small" max-height="400" style="width: 100%">
        <el-table-column align="center" label="序号" prop="projectId" width="90" />
        <el-table-column align="center" label="项目名称"  prop="projectName" />
        <el-table-column
          align="center"
          label="技术路线"
          prop="projectFeature"
          width="130"
        />
        <el-table-column align="center" label="项目地址"  prop="projectProvince"/>
      </el-table>
    </el-col>
  </el-row>
  <el-row
  style="
    height: 925px;
    width: 100%;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    padding-top: 50px;
  "
>
  <el-col :span="6" style="height: 30px; font-size: 15px; font-weight: 500">
    2.化石能源制氢
  </el-col>
  <el-col :span="18"> </el-col>
  <el-col :span="20" :offset="2">
    <div style="word-wrap: break-word; text-align: left">
      &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp从区域分布看，山西鹏飞集团公司化石能源制氢项目主要分布在山西省；从氢气来源来看，该公司煤制氢项目数量1个，氢气产能达到23186.67吨，焦炉煤气制氢项目数量1个，氢气产能达到38412.05吨。
    </div>
  </el-col>
  <el-col :span="20" :offset="2" style="height: 400px">
    <div class="pieChart" id="thirdChart" style="height: 400px; width: 100%"></div>
    图4 化石能源制氢氢气来源
  </el-col>
  <el-col :span="20" :offset="2" style="height: 450px">
    <div
      style="
        width: 100%;
        height: 50px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: white;
        background-color: darkblue;
      "
    >
    化石能源制氢项目列表
    </div>
    <el-table border stripe :data="projectList3" size="small" max-height="400" style="width: 100%">
      <el-table-column align="center" label="序号" prop="projectId" width="90" />
      <el-table-column align="center" label="项目名称"  prop="projectName"/>
      <el-table-column
        align="center"
        label="氢气来源"
        prop="projectFeature"
        width="130"
      />
      <el-table-column align="center" label="项目地址"  prop="projectProvince"/>
    </el-table>
  </el-col>
</el-row>
<el-row
style="
  height: 925px;
  width: 100%;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  padding-top: 50px;
"
>
<el-col :span="6" style="height: 30px; font-size: 15px; font-weight: 500">
  3.工业副产氢项目
</el-col>
<el-col :span="18"> </el-col>
<el-col :span="20" :offset="2">
  <div style="word-wrap: break-word; text-align: left">
    &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp从区域分布看，山西鹏飞集团公司工业副产氢项目主要分布在山西省；从氢气来源来看，该公司焦炭项目数量1个，氢气产能达到76000吨。
  </div>
</el-col>
<el-col :span="20" :offset="2" style="height: 400px">
  <div class="pieChart" id="fourthChart" style="height: 400px; width: 100%"></div>
  图4 工业副产氢项目类型
</el-col>
<el-col :span="20" :offset="2" style="height: 450px">
  <div
    style="
      width: 100%;
      height: 50px;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: white;
      background-color: darkblue;
    "
  >
  工业副产氢项目列表
  </div>
  <el-table border stripe :data="projectList4" size="small" max-height="400" style="width: 100%">
    <el-table-column align="center" label="序号" prop="projectId" width="90" />
    <el-table-column align="center" label="项目名称"  prop="projectName" />
    <el-table-column
      align="center"
      label="项目类型"
      prop="projectFeature"
      width="130"
    />
    <el-table-column align="center" label="项目地址"  prop="projectProvince"  />
  </el-table>
</el-col>
</el-row>
<el-row
style="
  height: 925px;
  width: 100%;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  padding-top: 50px;
"
>
<el-col :span="6" style="height: 30px; font-size: 15px; font-weight: 500">
  4.加氢站项目
</el-col>
<el-col :span="18"> </el-col>
<el-col :span="20" :offset="2">
  <div style="word-wrap: break-word; text-align: left">
    &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp从区域分布看，山西鹏飞集团公司加氢站项目主要分布在山西省；从加氢站类型来看，固定站5个；从压力等级来看，35兆帕加氢站5个；合计设计日加注能力为3500公斤/天。
  </div>>
  </el-col>
<el-col :span="20" :offset="2" style="height: 400px">
  <div class="pieChart" id="firstChart" style="height: 400px; width: 100%"></div>
  图4 加氢站项目类型
</el-col>
<el-col :span="20" :offset="2" style="height: 450px">
  <div
    style="
      width: 100%;
      height: 50px;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: white;
      background-color: darkblue;
    "
  >
  加氢站项目列表
  </div>
  <el-table border stripe :data="projectList1" size="small" max-height="400" style="width: 100%">
    <el-table-column align="center" label="序号" prop="projectId" width="90" />
    <el-table-column align="center" label="项目名称"  prop="projectName"/>
    <el-table-column
      align="center"
      label="项目类型"
      prop="projectFeature"
      width="130"
    />
    <el-table-column align="center" label="项目地址"  prop="projectProvince"/>
  </el-table>
</el-col>
</el-row>
</template>

<script lang="ts" setup>
import { get } from '@/utils/request';
import { ref, onMounted, getCurrentInstance } from 'vue';
let internalInstance = getCurrentInstance();

let echarts = internalInstance.appContext.config.globalProperties.$echarts;

const projectList1 = ref([
  {
    projectId: '1',
    projectName: '山西鹏飞集团城区综合能源站',
    projectProvince: '山西省',
    projectFeature: '固定站',
  },
  {
    projectId: '2',
    projectName: '山西鹏飞集团北姚综合能源站',
    projectProvince: '山西省',
    projectFeature: '固定站',
  },
  {
    projectId: '3',
    projectName: '山西鹏飞集团西王屯综合能源站',
    projectProvince: '山西省',
    projectFeature: '固定站',
  },
  {
    projectId: '4',
    projectName: '山西鹏飞集团金港湾综合能源站',
    projectProvince: '山西省',
    projectFeature: '固定站',
  },
  {
    projectId: '5',
    projectName: '山西鹏飞集团东庄综合能源站',
    projectProvince: '山西省',
    projectFeature: '固定站',
  },
]);


const projectList2 = ref([
  {
    projectId: '1',
    projectName: '120MW光伏发电制2000吨/年绿氢项目',
    projectProvince: '山西省',
    projectFeature: 'PEM',
  },
]);

const projectList3 = ref([
  {
    projectId: '1',
    projectName: '孝义鹏飞化石能源制氢项目1',
    projectProvince: '山西省',
    projectFeature: '煤制氢',
  },
  {
    projectId: '2',
    projectName: '孝义鹏飞化石能源制氢项目2',
    projectProvince: '山西省',
    projectFeature: '焦炉煤气',
  },
]);

const projectList4 = ref([
  {
    projectId: '1',
    projectName: '孝义鹏飞工业副产氢项目',
    projectProvince: '山西省',
    projectFeature: '焦炭',
  },
]);

onMounted(() => {
  initCharts();
});

function initCharts() {
  var chartDom = document.getElementById('firstChart');
  var chart = echarts.init(chartDom);
  const option = {
    title: {
      // text: '加氢站类型',
    },
    legend: {
      top: 30,
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      left: 'center', // 可选，如果希望水平居中显示图例
    },
    series: {
      top: 40,
      name: '访问来源',
      type: 'pie',
      radius: '70%', // 半径大小
      data: [
        {
          value: 1,
          name: '固定站',
          itemStyle: { color: '#87CEFA' }, // 轻蓝
        },
        {
          value: 5,
          name: '撬装站',
          itemStyle: { color: '#4682B4' }, // 中蓝
        },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'outside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  chart.setOption(option);
  var chartDom2 = document.getElementById('secondChart');
  var chart2 = echarts.init(chartDom2);
  const option2 = {
    title: {},
    legend: {
      top: 30,
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      left: 'center', // 可选，如果希望水平居中显示图例
    },
    series: {
      name: '访问来源',
      type: 'pie',
      top: 40,
      radius: '70%', // 半径大小
      data: [
        {
          value: 1,
          name: 'PEM',
          itemStyle: { color: '#87CEFA' }, // 轻蓝
        },
       
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'outside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  chart2.setOption(option2);

  var chartDom3 = document.getElementById('thirdChart');
  var chart3 = echarts.init(chartDom3);
  const option3 = {
    title: {
    },
    legend: {
      top: 30,
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      left: 'center', // 可选，如果希望水平居中显示图例
    },
    series: {
      name: '访问来源',
      top: 40,
      type: 'pie',
      radius: '70%', // 半径大小
      data: [
        {
          value: 1,
          name: '煤制氢',
          itemStyle: { color: '#87CEFA' }, // 轻蓝
        },
        {
          value: 1,
          name: '焦炉煤气',
          itemStyle: { color: '#4682B4' }, // 中蓝
        },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'outside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  chart3.setOption(option3);


  var chartDom4 = document.getElementById('fourthChart');
  var chart4 = echarts.init(chartDom4);
  const option4 = {
    title: {
    },
    legend: {
      top: 30,
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      left: 'center', // 可选，如果希望水平居中显示图例
    },
    series: {
      name: '访问来源',
      top: 40,
      type: 'pie',
      radius: '70%', // 半径大小
      data: [
        {
          value: 1,
          name: '焦炭',
          itemStyle: { color: '#87CEFA' }, // 轻蓝
        },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'outside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  chart4.setOption(option4);
  let fullColorData = [
    { name: '北京市', value: 0 },
    { name: '天津市', value: 0 },
    { name: '河北省', value: 0 },
    { name: '山西省', value: 9 },
    { name: '内蒙古自治区', value: 0 },
    { name: '辽宁省', value: 0 },
    { name: '吉林省', value: 0 },
    { name: '黑龙江省', value: 0 },
    { name: '上海市', value: 0 },
    { name: '江苏省', value: 0 },
    { name: '浙江省', value: 0 },
    { name: '安徽省', value: 0 },
    { name: '福建省', value: 0 },
    { name: '江西省', value: 0 },
    { name: '山东省', value: 0 },
    { name: '河南省', value: 0 },
    { name: '湖北省', value: 0 },
    { name: '湖南省', value: 0 },
    { name: '广东省', value: 0 },
    { name: '广西壮族自治区', value: 0 },
    { name: '海南省', value: 0 },
    { name: '重庆市', value: 0 },
    { name: '四川省', value: 0 },
    { name: '贵州省', value: 0 },
    { name: '云南省', value: 0 },
    { name: '西藏自治区', value: 0 },
    { name: '陕西省', value: 0 },
    { name: '甘肃省', value: 0 },
    { name: '青海省', value: 0 },
    { name: '宁夏回族自治区', value: 0 },
    { name: '新疆维吾尔自治区', value: 0 },
    { name: '台湾省', value: 0 },
    { name: '香港特别行政区', value: 0 },
    { name: '澳门特别行政区', value: 0 },
  ];

  var chinaChartDom = document.getElementById('chinaChart');
  var chinaChart = echarts.init(chinaChartDom);
  var chinaJson = null;
  get('https://www.lxjwzl.cn/json/china.json', {}, true).then((res: any) => {
  // get('http://localhost:8080/json/china.json', {}, true).then((res: any) => {
    chinaJson = res;
    echarts.registerMap('全国', chinaJson);
    let dataValue2 = [
      {
        name: '长治——电解水制氢项目1个',
        value: [113.11, 36.16],
        sales: '电解水制氢项目',
        text: '1个',
      },
      {
        name: '吕梁——化石能源制氢2个+工业副产氢1个+加氢站项目5个',
        value: [111.1382201, 37.5180245],
        sales: '电解水制氢项目',
        text: '1个',
      },
    ];
    const chinaChartOption = {
      //设置一个标题
      title: {
        text: '项目个数',
        x: 'center',
        textStyle: {
          color: '#fff',
          fontSize: '20',
        },
      },
      //鼠标划过省份下弹框
      tooltip: {
        show: true,
        triggerOn: 'click', //点击生效
      },
      geo: {
        map: '全国', //必须写
        roam: false, // 拖拽功能；自选关闭开启
        zoom: 1.3, //地图缩放比例
        center: [105, 36], //地图位置
        //地图省份的样式；包括板块颜色和边框颜色
        itemStyle: {
          areaColor: '#87CEFA',
          borderColor: '#835f5f',
        },
        //省份字体样式；包括是否展示，字体大小和颜色
        label: {
          normal: {
            show: true,
            fontSize: '11.5',
            color: 'rgb(107,102,102)',
          },
        },
        regions: generateRegionsConfig(fullColorData),
        //鼠标划过的高亮设置；包括省份板块颜色和字体等
        emphasis: {
          itemStyle: {
            areaColor: '#d0a3a3',
          },
          label: {
            show: true,
            color: 'rgb(255,255,255)',
          },
        },
      },

      series: [
        //我们的散点2
        {
          name: '个数',
          type: 'scatter',
          coordinateSystem: 'geo', //坐标系统
          data: dataValue2,
          //地图点的样式；颜色，大小
          symbolSize: 12,
          itemStyle: {
            color: '#f13434',
            shadowBlur: 2,
            shadowColor: '#333',
          },
          //鼠标点击散点的下弹框
          tooltip: {
            show: true,
            triggerOn: 'click',
            formatter: function (data1) {
              let data2 = data1.data;
              return '<b>项目个数<b><br>' + data2.name + data2.sales;
            },
          },
        },

        //涟漪特效2
        {
          name: '项目个数',
          type: 'effectScatter',
          coordinateSystem: 'geo',
          data: dataValue2, //传入的地图点数据
          symbolSize: 6, //涟漪大小
          showEffectOn: 'render',
          //涟漪效应
          rippleEffect: {
            brushType: 'stroke',
            color: '#f13434',
            period: 10, //周期
            scale: 10, //规模
          },
          hoverAnimation: true, //悬停动画
          //地图点样式
          label: {
            formatter: '{b}',
            position: 'top',
            show: true,
            fontSize: '10',
          },
          itemStyle: {
            color: '#f13434',
            shadowBlur: 2,
            shadowColor: '#333',
          },
          //鼠标点击散点的下弹框
          tooltip: {
            show: true,
            triggerOn: 'click',
            formatter: function (data1) {
              console.log(data1);
              let data2 = data1.data;
              return '<b>个数<b><br>' + data2.name + data2.sales;
            },
          },
          zlevel: 1,
        },
          {
  name: 'Trend Line',
  type: 'markLine',
  coordinateSystem: 'geo',
  data: [
    {
      name: 'Connection between two points',
      symbol: ['none', 'arrow'], // 设置箭头
      symbolSize: 10,
      label: {
        normal: {
          show: true,
          position: 'end',
          formatter: '这里是备注文字'
        }
      },
      lineStyle: {
        normal: {
          color: '#ff4500',
          width: 2
        }
      },
      effect: {
        show: true,
        period: 6,    // 动画时间
        trailLength: 0.7, // 特效尾迹长度 [0,1] 值越大，尾迹越长重
        symbol: 'arrow',   // 箭头图标
        symbolSize: 5 // 箭头大小
      },
      // 提供地理坐标，例如北京和上海的坐标
      coords: [
        [116.4074, 39.9027], // 北京
        [121.4737, 31.2304]  // 上海
      ]
    }
  ]
}
      ],
    };

    chinaChart.setOption(chinaChartOption);
  });
}

function generateRegionsConfig(data) {
  return data.map((item) => ({
    name: item.name,
    label: {
      normal: {
        show: item.value > 0,
        fontSize: '11.5',
        color: 'black', // 动态计算颜色
      },
    },
    itemStyle: {
      areaColor: calculateColor(item.value),
      borderColor: '#835f5f',
    },
  }));
}

function calculateColor(value) {
  // 当value <= 0时，颜色为白色
  if (value <= 0) {
    // 浅蓝色 rgb
    return '#87CEFA'; // 直
  }

  // 确保value至少为5，以便于计算
  value = Math.max(value, 5);

  // 蓝色的色相（Hue）保持在220，属于蓝色范围
  // 饱和度（Saturation）随value增加而增加，使颜色更鲜艳，最大饱和度设为100%
  // 亮度（Lightness）保持在中间值，比如50%，确保颜色既不太深也不太浅
  const hue = 220;
  const maxSaturation = 100; // 最大饱和度
  const lightness = 60; // 中间亮度值
  // 每增加5，饱和度增加一定比例，这里为了效果明显，直接按比例增加
  const saturation = Math.min(maxSaturation, (value / 5) * maxSaturation);

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}
</script>

<style scoped></style>
