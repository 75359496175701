<template>
  <el-row>
    <el-col :span="4" :offset="10">
      <h4>暂无数据</h4>
    </el-col>

</el-row>

</template>

<script lang="ts" setup>
import { get } from '@/utils/request';
import { ref, onMounted, getCurrentInstance } from 'vue';
let internalInstance = getCurrentInstance();

let echarts = internalInstance.appContext.config.globalProperties.$echarts;

const patentList = ref([
  
]);

onMounted(() => {
  // initCharts();
});


</script>

<style scoped></style>
