<template>
  <el-col class="littleContainer" :span="11" :offset="1">
    <div
      class="mindmap-container"
      ref="mindmapContainer"
      id="mindMapContainer"
      style="width: 98%; margin-left: 1%; margin-top: 1%; height: 400px"
    ></div>
  </el-col>
  <el-col :offset="1" :span="10">
    <el-table
      @row-click="jumpToCity"
      stripe
      border
      row-class-name="hoverable-row"
      :data="choosenCompanys"
      :cell-style="cellStyle"
      max-height="400"
      style="margin-bottom: 1%; font-size: 11px"
    >
      <el-table-column label="序号" prop="companyId" width="50" align="center" />
      <el-table-column
      label="企业名称"
      align="center"
      prop="companyPrivilliageCount"
    >
      <template #default="{ row }">
        <span class="blue-text">{{ row.companyName }}</span>
      </template>
    </el-table-column>
      <el-table-column
        label="知识产权数"
        prop="companyPrivilliageCount"
        width="90"
        align="center"
      />
      <el-table-column label="所在区域" prop="companyArea" align="center" />
    </el-table>
    <!-- <el-pagination
      layout="prev, pager, next"
      background
      :total="choosenCompanys.length"
      :page-size="10"
      @current-change="handlePageChange"
    /> -->
  </el-col>
</template>

<script lang="ts" setup>
import { ref, onMounted, shallowRef } from 'vue';
import MindMap from 'simple-mind-map';
const mindmapContainer = ref<HTMLDivElement | null>(null);

let mindMap = null;

// 当前激活的节点列表
const companyList = ref([
  {
    companyId: '1',
    companyName: '内蒙古圣圆氢能源科技有限公司',
    companyPrivilliageCount: 10,
    companyArea: '内蒙古鄂尔多斯市',
    mainCategory: '氢能加注',
    subCategory: '车辆运输',
  },
  {
    companyId: '2',
    companyName: '鄂尔多斯市新杭能源有限公司',
    companyPrivilliageCount: 2,
    companyArea: '内蒙古鄂尔多斯市',
    mainCategory: '氢能加注',
    subCategory: '',
  },
  {
    companyId: '3',
    companyName: '国能蒙西煤化工股份有限公司',
    companyPrivilliageCount: 3,
    companyArea: '内蒙古鄂尔多斯市',
    mainCategory: '氢能加注',
    subCategory: '',
  },
  {
    companyId: '4',
    companyName: '内蒙古博大实地化学有限公司',
    companyPrivilliageCount: 0,
    companyArea: '内蒙古鄂尔多斯市',
    mainCategory: '氢能制取',
    subCategory: '',
  },
  {
    companyId: '5',
    companyName: '内蒙古东华能源有限责任公司',
    companyPrivilliageCount: 4,
    companyArea: '内蒙古鄂尔多斯市',
    mainCategory: '氢能制取',
    subCategory: '',
  },
  {
    companyId: '6',
    companyName: '内蒙古鄂尔多斯电力冶金集团股份有限公司氯碱化工分公司',
    companyPrivilliageCount: 5,
    companyArea: '内蒙古鄂尔多斯市',
    mainCategory: '技术装备',
    subCategory: '',
  },
  {
    companyId: '7',
    companyName: '内蒙古鄂尔多斯化学工业有限公司',
    companyPrivilliageCount: 6,
    companyArea: '内蒙古鄂尔多斯市',
    mainCategory: '氢能应用',
    subCategory: '',
  },
  {
    companyId: '8',
    companyName: '内蒙古鄂尔多斯联合化工有限公司',
    companyPrivilliageCount: 1,
    companyArea: '内蒙古鄂尔多斯市',
    mainCategory: '氢能应用',
    subCategory: '',
  },
  {
    companyId: '9',
    companyName: '内蒙古恒坤化工有限公司',
    companyPrivilliageCount: 0,
    companyArea: '内蒙古鄂尔多斯市',
    mainCategory: '氢能应用',
    subCategory: '',
  },
  {
    companyId: '10',
    companyName: '内蒙古汇能煤化工有限公司',
    companyPrivilliageCount: 0,
    companyArea: '内蒙古鄂尔多斯市',
    mainCategory: '氢能应用',
    subCategory: '',
  },
]);

const choosenCompanys = ref([]);
const activeNodes = shallowRef([]);

const radio1 = ref('电解水制氢');

onMounted(() => {
  initMindMap();

  choosenCompanys.value = companyList.value;
});

function jumpToCity() {
  window.location.href = 'company';
}

function initMindMap() {
  mindMap = new MindMap({
    el: document.getElementById('mindMapContainer'),
    mousewheelAction: 'move', // zoom（放大缩小）、move（上下移动）
    root: {
      shape: 'rectangle',
      fillColor: '#black',
    },
    enableDblclickBackToRootNode: true,
    fit: true,
    isLimitMindMapInCanvas: true,
    data: {
      data: {
        text: '氢能产业',
      },
      children: [
        {
          data: {
            text: '氢能制取',
            expand: false,
          },
          children: [
            {
              data: {
                text: '电解水制氢',
              },
              children: [],
            },
            {
              data: {
                text: '氯气提纯',
              },
              children: [],
            },
            {
              data: {
                text: '化石能源制氢',
              },
              children: [],
            },
          ],
        },
        {
          data: {
            text: '氢能储运',
            expand: false,
          },
          children: [
            {
              data: {
                text: '氢能储存',
              },
              children: [],
            },
            {
              data: {
                text: '管道运输',
              },
              children: [],
            },
            {
              data: {
                text: '车辆运输',
              },
              children: [],
            },
          ],
        },
        {
          data: {
            text: '氢能加注',
            expand: false,
          },
          children: [
            {
              data: {
                text: '加氢站',
              },
              children: [],
            },
          ],
        },
        {
          data: {
            expand: false,
            text: '氢能应用',
          },
          children: [
            {
              data: {
                text: '交通应用',
              },
              children: [],
            },
            {
              data: {
                text: '化工应用',
              },
              children: [],
            },
            {
              data: {
                text: '电力应用',
              },
              children: [],
            },
            {
              data: {
                text: '钢铁应用',
              },
              children: [],
            },
          ],
        },
        {
          data: {
            text: '技术装备',
            expand: false,
          },
          children: [
            {
              data: {
                text: '电解槽',
              },
              children: [],
            },
            {
              data: {
                text: '高压固定储罐',
              },
              children: [],
            },
            {
              data: {
                text: '车载储氢罐',
              },
              children: [],
            },
            {
              data: {
                text: '固定液氢储罐',
              },
              children: [],
            },
            {
              data: {
                text: '固态储气',
              },
              children: [],
            },
            {
              data: {
                text: '纯氢管道',
              },
              children: [],
            },
            {
              data: {
                text: '运氢车辆',
              },
              children: [],
            },
            {
              data: {
                text: '加氢机',
              },
              children: [],
            },
            {
              data: {
                text: '压缩机',
              },
              children: [],
            },
            {
              data: {
                text: '燃料电池',
              },
              children: [],
            },
          ],
        },
      ],
    },
    initRootNodePosition: ['left', 'center'],
  });

  // 监听节点激活事件
  mindMap.on('node_active', (node, nodeList) => {
    activeNodes.value = nodeList;
    if (activeNodes.value.length == 1) {
      changeChoosenCompanys(activeNodes.value[0].getData('text'));
    }
  });
  mindMap.setThemeConfig({
    root: {
      fillColor: '#2091f6',
    },
    backgroundSize: 'contain',
    second: {
      fillColor: 'white',
      color: 'black',
    },
    node: {
      fcolor: 'black',
      fontWeight: 'noraml',
    },
  });
}
function cellStyle(row) {

  if (row.column.label == "企业名称") {
      return { 'color': '#blue' };
  }
}

function changeChoosenCompanys(category: string) {
  if (category == '氢能产业') {
    choosenCompanys.value = companyList.value;
  } else if (
    category == '氢能制取' ||
    category == '氢能储运' ||
    category == '氢能加注' ||
    category == '氢能应用' ||
    category == '技术装备'
  ) {
    choosenCompanys.value = companyList.value.filter((item) => item.mainCategory == category);
  } else {
    choosenCompanys.value = companyList.value.filter((item) => item.subCategory == category);
  }
}

// 当前页码状态
const currentPage = ref(1);

// 计算显示的公司列表
function calDisplayedCompanys() {
  const startIndex = (currentPage.value - 1) * 10;
  const endIndex = startIndex + 10;
  choosenCompanys.value = choosenCompanys.value.slice(startIndex, endIndex);
}

// 分页改变的处理函数
const handlePageChange = (newPage: number) => {
  currentPage.value = newPage;
  calDisplayedCompanys();
};
</script>

<style scoped>
.littleContainer {
  border: 0.1px solid;
  border-color: #d4e6f6;
  border-radius: 10px;
}
.blue-text {
  color: blue;
}
</style>
