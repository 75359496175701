<template>
  <el-row
    style="
      background-color: #316ccb;
      width: 100%;
      height: 50px;
      align-items: center;
      z-index: 99;
      position: fixed;
      top: 0;
    "
  >
    <el-col style="color: white; font-size: 16px; font-weight: 600" :span="3">预览</el-col>
    <el-col style="color: white" :span="3" :offset="18">
      <el-button type="primary" @click="() => htmlToPDF('report-id', '山西鹏飞集团微尽调报告')">
        下载微尽调报告
      </el-button>
    </el-col>
  </el-row>
  <div class="gradient-bg"></div>
  <el-row style="width: 100%; margin-top: 100px; ">
    <el-col :span="12" :offset="6">
      <el-container
        id="report-id"
        style="display: flex; flex-direction: column; justify-content: start; align-items: start;"
      >
        <ReportHome />
        <Description />
        <Projects />
        <Patients />
      </el-container>
    </el-col>
  </el-row>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { htmlToPDF } from '@/utils/html2pdf';
import ReportHome from './ReportHome.vue';
import Projects from './ReportCompanyProjects.vue';
import Description from './ReportCompanyDescription.vue';
import Patients from './ReportCompanyPatients.vue';

onMounted(() => {});

</script>

<style scoped>
.el-tag {
  margin-right: 10px;
}

.citySubTitle {
  margin: 0;
  width: 7vw;
  font-size: medium;
  font-weight: 550;
}

.gradient-bg {
  background: linear-gradient(to bottom, #a8d2fb, #ffffff);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  z-index: -1;
}

.el-table th {
  display: table-cell !important;
}
</style>
