<template>
  <el-container class="layout-container">
    <my-aside></my-aside>
    <el-main style="height: 100vh;">
      <el-row style="height: 10%;width: 100%;">
          <el-col :span="6" style="display: flex; flex-direction: row;justify-content: center; align-items: center;">
          <img src="../assets/basic-icons/template-inside.png" style="height: 35px;  margin-right: 5px;"/>
          <span style="font-size: 30px; color: #2091F6;">我的模板</span>
          </el-col>
          <el-col :span="5" :offset="13" style=" display: flex; flex-direction: row; justify-content: center; align-items: center;">
          <el-button @click="createProject" style="height: 90%; font-size:150%; width: 100%; border: 2px solid #2091F6;">
              <img src="../assets/basic-icons/plus.png" style="height: 28px; margin-right: 5px;"/>
              <span style="color: #2091F6;"> 创建新模板</span>
              </el-button>
          </el-col>
      </el-row>
      <el-divider style="height: 2%;"/>
      <div style="display: flex; flex-direction: row; justify-content: space-around;">
          <el-card class="box-card" shadow="always">
              <template #header>
              <div class="card-header">
                  <span style="font-size: 20px;">齐齐哈尔风能模板</span>
                  <el-button class="button" text @click="">查看详情</el-button>
              </div>
              </template>
              <div class="text item">合成甲醇</div>
              <div class="text item">最大收益率</div>
          </el-card>
          <el-card class="box-card" shadow="always">
              <template #header>
              <div class="card-header">
                  <span style="font-size: 20px;">宁夏模板</span>
                  <el-button class="button" text @click="">查看详情</el-button>
              </div>
              </template>
              <div class="text item">合成甲醇</div>
              <div class="text item">最大收益率</div>
          </el-card>
          <el-card class="box-card" shadow="always">
              <template #header>
              <div class="card-header">
                  <span style="font-size: 20px;">阿克苏风能模板</span>
                  <el-button class="button" text @click="">查看详情</el-button>
              </div>
              </template>
              <div class="text item">合成甲醇</div>
              <div class="text item">最大收益率</div>
          </el-card>
      </div>
    </el-main>
  </el-container>
</template>

<script lang="ts" setup>
  import {ref} from 'vue'
  import { get, post } from '@/utils/request'
  import MyAside from '../components/Aside.vue'
  let curPage = ref(0);

  const createProject = () => {

  }
</script>
<style scoped>

.box-card {
  border: 1px solid #2091F6;
  border-radius: 30px;
  width: 30%;
  height: 300px;
}
</style>
  