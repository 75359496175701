<template>
  <el-col class="littleContainer" :span="11" :offset="1">
  <div style=" text-align: left; height: 280px; padding: 5px">
    <p v-for="news in newsList">
      <a href="https://www.chinah2data.com/#/client/database/EventBaseList" target="_blank">
        <u style="font-size: 10px;">{{ news.newsTitle }}</u>
      </a>
    </p>
  </div>
</el-col>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';

const newsList = ref([
  {
    newsTitle: '内蒙古财政厅发布《关于提前下达2024年节能减排补助资金预算的通知》',
    newsUuid: '1',
  },
  {
    newsTitle: '远景能源年产152万吨的零碳氢氨项目明年6月投产',
    newsUuid: '2',
  },
  {
    newsTitle: '霍林河“源-网-荷-储-用”创新示范项目PEM制氢系统成功试运行',
    newsUuid: '3',
  },
  {
    newsTitle: '亿利350万千瓦立体风光氢治沙制取航空燃料一体化示范项目签约',
    newsUuid: '1',
  },
  {
    newsTitle: '内蒙古印发《新能源倍增行动实施方案的通知》',
    newsUuid: '2',
  },
  {
    newsTitle: '全国首个芦竹生物质零碳产业园签约',
    newsUuid: '3',
  },
  {
    newsTitle: '内蒙古发布《内蒙古自治区风光制氢一体化项目实施细则2023年修订版（试行）》',
    newsUuid: '2',
  },
  {
    newsTitle: '大唐集团首个绿氢重点示范项目开工',
    newsUuid: '3',
  },
]);

onMounted(() => {
});

</script>

<style scoped>
.littleContainer {
  border: 0.1px solid;
  border-color: #d4e6f6;
  border-radius: 10px;
}
</style>
