<template>
  <el-table stripe :data="projectList" border max-height="500" style="width: 100%; text-align: start;font-size: 11px">
    <el-table-column label="序号" align="center" prop="projectId" width="50" />
    <el-table-column label="项目名称" align="center"  prop="projectName">
    <template #default="{ row }">
      <span class="blue-text">{{ row.projectName }}</span>
    </template>
  </el-table-column>
    <el-table-column label="项目类型" align="center"  prop="projectCategory"  />
    <el-table-column label="项目地址" align="center"  prop="projectArea"  />
  </el-table>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const projectList = ref([
  {
    projectId: '1',
    projectName: '纳日松光伏制氢产业示范项目（一期）',
    projectArea: '具体位置2号102街道',
    projectCategory: '电解水制氢',
  },
  {
    projectId: '2',
    projectName: '纳日松光伏制氢产业示范项目（二期）',
    projectArea: '具体位置2号104街道',
    projectCategory: '电解水制氢',
  },
  {
    projectId: '3',
    projectName: '乌审旗风光融合绿氢化工示范项目（一期）',
    projectArea: '具体位置5号105街道',
    projectCategory: '电解水制氢',
  },
  {
    projectId: '4',
    projectName: '鄂托克旗风光制氢一体化合成绿氨项目',
    projectArea: '具体位置2号106街道',
    projectCategory: '电解水制氢',
  },
  {
    projectId: '5',
    projectName: '圣圆能源制氢加氢一体化项目（二期）',
    projectArea: '具体位置2号107街道',
    projectCategory: '电解水制氢',
  },
  {
    projectId: '6',
    projectName: '国家能源集团“氢洲”项目',
    projectArea: '具体位置2号108街道',
    projectCategory: '电解水制氢',
  },
  {
    projectId: '7',
    projectName: '10万吨/年液态阳光—二氧化碳加绿氢制甲醇技术示范项目',
    projectArea: '具体位置2号109街道',
    projectCategory: '电解水制氢',
  },
  {
    projectId: '8',
    projectName: '圣圆能源制氢加氢一体化项目',
    projectArea: '具体位置2号101街道',
    projectCategory: '电解水制氢',
  },
  {
    projectId: '9',
    projectName: '上海庙经济开发区光伏制氢项目（一期）',
    projectArea: '具体位置2号103街道',
    projectCategory: '电解水制氢',
  },
]);
</script>

<style scoped>
.blue-text {
  color: blue;
}
</style>
