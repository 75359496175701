import { defineStore } from 'pinia'
import { ref, onBeforeMount } from 'vue'

const useTokenStore = defineStore('token', () => {
  const token = ref()

  // 从 localStorage 加载初始 token 值（如果存在）
  onBeforeMount(() => {
    const storedToken = localStorage.getItem('token')
    if (storedToken) {
      token.value = storedToken
    }
  })

  const ONE_DAY_MS = 24 * 60 * 60 * 1000; // 1天的毫秒数

  const saveToken = (newToken) => {
      token.value = newToken;
      const expiresAt = new Date(Date.now() + ONE_DAY_MS); // 1天后过期
      localStorage.setItem('token', newToken);
      localStorage.setItem('tokenExpiresAt', expiresAt.getTime().toString());
  };

  const removeToken = () => {
    token.value = ''
    localStorage.removeItem('token')
    localStorage.removeItem('tokenExpiresAt')
  }

  return { token, saveToken, removeToken }
})

export default useTokenStore