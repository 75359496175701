<template>
  <el-descriptions style="">
    <el-descriptions-item label="企业名称:">山西鹏飞集团有限公司 </el-descriptions-item>
    <el-descriptions-item label="统一社会编码:">91141181MA0KX8656Y</el-descriptions-item>
    <el-descriptions-item label="注册资本:">16000万人民币</el-descriptions-item>

    <el-descriptions-item label="归属企业:">孝义市鹏飞新能源有限公司</el-descriptions-item>
    <el-descriptions-item label="企业类型:">有限责任公司(自然人投资或控股) </el-descriptions-item>
    <el-descriptions-item label="成立日期:">2021-03-25</el-descriptions-item>
    <el-descriptions-item label="氢能产业环节:">氢能制取、氢能加注</el-descriptions-item>
    <el-descriptions-item label="氢能产业环节细分:">电解水制氢、化石能源制氢、工业副产氢、加氢站</el-descriptions-item>
    <el-descriptions-item label="技术装备:">无</el-descriptions-item>
  </el-descriptions>
  <el-descriptions style="">
    <el-descriptions-item label="地址:"
      >山西省吕梁市孝义市下栅乡下栅村</el-descriptions-item
    >
  </el-descriptions>
  <el-descriptions style="">
    <el-descriptions-item label="基本信息:">
      山西鹏飞集团有限公司成立于2020年01月21日，注册地位于山西省吕梁市孝义市下栅乡下栅村，法定代表人为马小龙。
    </el-descriptions-item>
  </el-descriptions>
  <el-descriptions style="">
    <el-descriptions-item label="经营范围:">
      企业管理；大数据产业开发；市场营销、策划企业、文化策划；会议会展服务；物业服务；房地产开发、咨询服务；铁路运输代理服务；教育服务（不含教育培训）；煤炭技术开发；电力生产、电力供应；煤层气开发；酒店管理、住宿、餐饮服务；经销精煤、焦炭、煤炭、铁矿粉、生铁、铝矾土、硅铁、钢材、机械设备；硫酸、盐酸、甲苯、丙酮、高锰酸钾、过氧化氢、硝酸银、硝酸钾、硝酸锌、水银、液氨、油漆【闭杯闪点≤60℃】、液氧、甲醇、氢气、正丁烷、液氮、丙烷、石脑油、液氨（合成氨）、苯、二甲苯、沥青、轻芳烃、重芳烃、液化天然气、焦油、异辛洗异丁烷、甲基叔丁基醚、二甲醚、合成氨、苯、金属镁、吗啉、过氧化氢（双氧水）、煤焦油、粗苯、煤焦沥青、硫磺、煤气（依法须经批准的项目，经相关部门批准后方可开展经营活动） </el-descriptions-item>
  </el-descriptions>
</template>

<script lang="ts" setup></script>

<style scoped></style>
