<template>
  <el-row
    style="
      background-color: #316ccb;
      width: 100%;
      height: 50px;
      align-items: center;
      z-index: 99;
      position: fixed;
      top: 0;
    "
  >
    <el-col style="color: white" :span="4"> {{ h2City.name }}城市之窗</el-col>
  </el-row>
  <el-container class="mainContainer" style="margin-top: 50px">
    <div class="gradient-bg"></div>
    <el-row class="subContainer">
      <el-col :span="24" style="height:30px"></el-col>
      <el-col class="littleContainer" :span="11" :offset="1">
        <img style="width: 300px; margin-top:20px" :src="require('@/assets/city/cityTemplate.png')" />
        <div style="width: 120px; height: 20px">
          <span style="font-size: small; color: black">氢能产业热度：784</span>
        </div>
      </el-col>
      <el-col :span="11" :offset="1">
        <div
          style="
            text-align: left;
            width: 100%;
            color: #2972fa;
            font-size: 17px;
            font-weight: 550;
            margin-top: 10px;
            margin-bottom: 10px;
          "
        >
          城市简介与氢能规划
        </div>
        <div style="overflow: auto; color: black; text-align: left" class="custom-scrollbar">
          <div
            v-html="h2City.introduce"
            style="
              width: 90%;
              margin-top: 2%;
              font-size: 13px;
              letter-spacing: 2px;
            "
          ></div>
        </div>
      </el-col>
      <el-col :span="24" style="height:20px"></el-col>
      <CitySummary />
      <el-col :span="24" style="height:30px"></el-col>
    </el-row>
    <el-row class="subContainer">
      <el-col :span="24" style="height:20px"></el-col>
      <el-col :span="11" :offset="1">
        <div class="citySubTitle">氢能产业链图</div>
      </el-col>
      <el-col :span="11">
        <div class="citySubTitle">企业列表</div>
      </el-col>
      <CityCompanys />
      <el-col :span="24" style="height:20px"></el-col>
    </el-row>
    <el-row class="subContainer">
      <el-col :span="24" style="height:20px"></el-col>
      <el-col :span="2" :offset="1">
        <span class="citySubTitle">城市资讯</span>
      </el-col>
        <el-col :span="3" :offset="6">
        <el-link type="primary" href="https://www.chinah2data.com/#/client/database/EventBaseList">查看更多>></el-link>
      </el-col>
      <el-col :span="2" :offset="1">
        <span class="citySubTitle">氢能政策</span>
      </el-col>
      <el-col :span="3" :offset="6">
        <el-link type="primary" href="https://www.chinah2data.com/#/client/database/PolicyBaseList">查看更多>></el-link>
      </el-col>
      <el-col :span="24" style="height:20px"></el-col>
      <CityNews />
      <CityPolicies />
      <el-col :span="24" style="height:20px"></el-col>
    </el-row>
    <el-row class="subContainer" style="margin-bottom: 20px">
      <el-col :span="24" style="height:20px"></el-col>
      <CityProjectsChart />
      <el-col :span="24" style="height:20px"></el-col>
      <el-col :span="2" :offset="1">
        <div class="citySubTitle">项目列表</div>
      </el-col>
      <el-col :span="21"></el-col>
      <el-col
        :span="22"
        :offset="1"
      >
        <CityProjects />
      </el-col>
    </el-row>
    
  </el-container>
  <el-row style="background-color: black; width: 100%; height: 60px; align-items: center">
    <el-col style="color: white" :span="24">@ 2020-2024 氢能产业大数据平台 </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import CityProjectsChart from './CityProjectsChart.vue';
import CityProjects from './CityProjects.vue';
import CityNews from './CityNews.vue';
import CityPolicies from './CityPolicies.vue';
import CityCompanys from './CityCompanys.vue';
import CitySummary from './CitySummary.vue';

const h2City = ref({
  name: '鄂尔多斯',
  districtUrl: '',
  introduce:
    '&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp鄂尔多斯市，位于内蒙古自治区西南部，' +
    '地处黄河几字弯河套腹地，北靠黄河，南临古长城，西依贺兰山，东接自治区首府呼和浩特，是内蒙古重要的经济中心、城市集群和呼包鄂城市群的中心城市之一。' +
    '这里不仅有着悠久的历史文化和独特的自然景观，还是国家重要的能源基地和生态屏障。' +
    '<br> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp鄂尔多斯市拥有丰富的自然资源，特别是煤炭、天然气等能源资源储量巨大。近年来，随着经济的持续发展和产业结构的转型升级，鄂尔多' +
    '地处黄河几字弯河套腹地，北靠黄河，南临古长城，西依贺兰山，东接自治区首府呼和浩特，是内蒙古重要的经济中心、城市集群和呼包鄂城市群的中心城市之一。' +
    '这里不仅有着悠久的历史文化和独特的自然景观，还是国家重要的能源基地和生态屏障。',
  companyNum: 10,
  newsNum: 8,
  policyNum: 5,
  projectNum: 89,
  productLine: {},
  companyList: {
    companyName: '',
    companyArea: '',
    companyPreviliage: '',
    companyUuid: '',
  },
  newsList: {
    newsTitle: '',
    newsUuid: '',
  },
  projectList: {
    projectUuid: '',
    projectTitle: '',
    projectType: '',
    projectAddress: '',
    projectStatus: '', // 建成 or 运营
    projectProduction: '', // 产能
    projectElectricitySource: '',
    projectTechniqueRoute: '',
    projectProductDirection: '',
  },
});
</script>

<style scoped>
.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eeeff9;
  z-index: -1;
}

.gradient-bg {
  background: linear-gradient(to bottom, #a8d2fb, #eeeff9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 110px;
  z-index: 0;
}

.subContainer {
  border: 1px solid;
  border-radius: 10px;
  border-color: white;
  background-color: white;
  width: 63%;
  align-items: top;
  margin-top: 20px;
  box-shadow: 0 3px 3px 0 rgba(141, 141, 141, 0.2);
}

.littleContainer {
  border: 0.1px solid;
  border-color: #d4e6f6;
  border-radius: 10px;
}

/* 自定义滚动条样式 */
.custom-scrollbar {
  overflow: auto;
  height: 250px;
}

/* 针对Webkit内核浏览器 */
.custom-scrollbar::-webkit-scrollbar {
  width: 2px;
  /* 宽度 */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  /* 轨道颜色 */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  /* 滑块颜色 */
  border-radius: 3px;
  /* 圆角 */
}

/* 当滑块被hover时改变颜色 */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.citySubTitle {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
}

.mindmap-container {
  width: 100%;
  height: 00px;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
}

::v-deep .hoverable-row:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
  cursor: pointer;
}
</style>
