<template>
  <el-row style="height: 925px; width: 100%; box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5); margin-bottom: 20px; padding-top: 50px;">
    <el-col :span="8" style="height: 50px; font-size: 20px; font-weight: 500;">
      一、企业简介
    </el-col>
    <el-col :span="16"> </el-col>
    <el-col :span="6" style="height: 30px; font-size: 15px; font-weight: 500"> 1.基本信息 </el-col>
    <el-col :span="18"> </el-col>
    <el-col :span="20" :offset="2" style="height: 800px">
      <div
        style="
          width: 100%;
          height: 50px;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          color: white;
          background-color: darkblue;
        "
      >
        工商信息
      </div>
      <el-descriptions style="" border :column="1">
        <el-descriptions-item label="企业名称:" :width="80"
          >山西鹏飞集团有限公司
        </el-descriptions-item>
        <el-descriptions-item label="归属企业:">孝义市鹏飞新能源有限公司</el-descriptions-item>
        <el-descriptions-item label="统一社会编码:">91141181MA0KX8656Y</el-descriptions-item>
        <el-descriptions-item label="企业类型:"
          >有限责任公司(自然人投资或控股)
        </el-descriptions-item>
        <el-descriptions-item label="注册资本:">16000万人民币</el-descriptions-item>
        <el-descriptions-item label="基本信息:">
          山西鹏飞集团有限公司成立于2020年01月21日，注册地位于山西省吕梁市孝义市下栅乡下栅村，法定代表人为马小龙。
        </el-descriptions-item>
        <el-descriptions-item label="经营范围:">
          企业管理；大数据产业开发；市场营销、策划企业、文化策划；会议会展服务；物业服务；房地产开发、咨询服务；铁路运输代理服务；教育服务（不含教育培训）；煤炭技术开发；电力生产、电力供应；煤层气开发；酒店管理、住宿、餐饮服务；经销精煤、焦炭、煤炭、铁矿粉、生铁、铝矾土、硅铁、钢材、机械设备；硫酸、盐酸、甲苯、丙酮、高锰酸钾、过氧化氢、硝酸银、硝酸钾、硝酸锌、水银、液氨、油漆【闭杯闪点≤60℃】、液氧、甲醇、氢气、正丁烷、液氮、丙烷、石脑油、液氨（合成氨）、苯、二甲苯、沥青、轻芳烃、重芳烃、液化天然气、焦油、异辛洗异丁烷、甲基叔丁基醚、二甲醚、合成氨、苯、金属镁、吗啉、过氧化氢（双氧水）、煤焦油、粗苯、煤焦沥青、硫磺、煤气（依法须经批准的项目，经相关部门批准后方可开展经营活动）
        </el-descriptions-item>
      </el-descriptions>
     
    </el-col>
  </el-row>
  <el-row style="height: 925px; width: 100%; box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5); margin-bottom: 20px; padding-top: 50px;">
    <el-col :span="20" :offset="2" style="height: 200px;">
      <div
      style="
        width: 100%;
        height: 50px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: white;
        background-color: darkblue;
      "
    >
      创新能力
    </div>
    <el-descriptions style="" border :column="1">
      <el-descriptions-item label="所有专利:" :width="80"> 暂无 </el-descriptions-item>
    </el-descriptions>
    <div
      style="
        width: 100%;
        height: 50px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: white;
        background-color: darkblue;
      "
    >
      氢能信息
    </div>
    <el-descriptions style="" border :column="1">
      <el-descriptions-item label="氢能产业环节:" :width="80"
        >氢能制取、氢能加注</el-descriptions-item
      >
      <el-descriptions-item label="细分领域:" :width="80"
        >电解水制氢、化石能源制氢、工业副产氢、加氢站</el-descriptions-item
      >
    </el-descriptions>
    </el-col>
    <el-col :span="20" :offset="2" style="height: 550px">
      <div style="height: 550px; width: 100%">
        <RelationGraph
          ref="graphRef"
          :options="graphOptions"
        >
          <!-- <template #graph-plug> -->
          <!-- <div class="c-my-panel"> -->
          <!-- <el-radio-group v-model="currentCase" size="small" @change="resetGraph">
                <el-radio-button label="Horizontal Tree"></el-radio-button>
                <el-radio-button label="Vertical Tree"></el-radio-button>
              </el-radio-group> -->
          <!-- </div> -->
          <!-- </template> -->
        </RelationGraph>
        图1 山西鹏飞集团公司股权穿透图
      </div>
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import RelationGraph, {
  RGOptions,
  RGJsonData,
  RGNode,
  RGLine,
  RGLink,
  RGUserEvent,
  RelationGraphComponent,
  RGTreeLayoutOptions,
} from 'relation-graph-vue3';

const currentCase = ref('Horizontal Tree');
const graphOptions: RGOptions = {
  // debug: true,
  // showDebugPanel: true,
  // lineUseTextPath: true,
  layout: {
    layoutName: 'tree',
    from: 'center',
    min_per_height: 80,
  },
  defaultNodeShape: 1,
  defaultNodeWidth: 250,
  defaultLineShape: 4,
  disableDragNode: true,
  graphOffset_x: 0,
  graphOffset_y: 0,
  disableDragLine: true,
  defaultJunctionPoint: 'left',
  defaultNodeBorderWidth: 0,
  defaultLineColor: 'rgba(0, 186, 189, 1)',
  defaultNodeColor: '#2091f6',
  defaultLineTextOffset_x: -10,
  defaultLineTextOffset_y: -1,
  disableDragCanvas: false,
};

const graphRef = ref<RelationGraphComponent | null>(null);

const resetGraph = async () => {
  const treeLayoutOptions = graphOptions.layout as RGTreeLayoutOptions;
  if (currentCase.value === 'Horizontal Tree') {
    treeLayoutOptions.from = 'left';
    treeLayoutOptions.min_per_width = 400;
    treeLayoutOptions.min_per_height = 30;
    graphOptions.defaultJunctionPoint = 'ltrb'; // 箭头指向问题
  } else {
    treeLayoutOptions.from = 'top';
    treeLayoutOptions.min_per_width = 100;
    treeLayoutOptions.max_per_width = 150;
    treeLayoutOptions.min_per_height = 120;
    treeLayoutOptions.max_per_height = 140;
    graphOptions.defaultJunctionPoint = 'tb';
  }
  const graphInstance = graphRef.value?.getInstance();
  if (graphInstance) {
    await graphInstance.setOptions(graphOptions);
    await showGraph();
    await graphInstance.refresh(); // 这里解决刚加载时页面的显示问题
  }
};

const showGraph = async () => {
  const __graph_json_data: RGJsonData = {
    rootId: 'root',
    nodes: [
      { id: 'root', text: '山西鹏飞集团有限公司', color: 'blue', height: 50 },
      // { id: 'N2', text: '孝义市鹏飞新能源有限公司', color: 'blue' },
      { id: 'N2', text: '孝义市鹏飞新能源有限公司', height: 50 },
      { id: 'N3', text: '孝义市聚慧科技有限公司', height: 50 },
      { id: 'N4', text: '山西世宸贸易有限公司', height: 50 },
      { id: 'c1', text: '山西鹏飞集团置业投资有限公司'},
      { id: 'c2', text: '山西鹏飞绿色能源投资有限公司' },
      { id: 'c3', text: '北京三晋鹏飞产业运营管理有限公司' },
      { id: 'c4', text: '山西鹏飞水务有限公司'},
      { id: 'c5', text: '山西鹏飞蓝能新能源科技有限公司' },
      { id: 'c6', text: '山西大华通新能源物流有限公司' },
      { id: 'c7', text: '山西鹏飞氢晨新能源科技有限公司'},
      { id: 'c8', text: '山西鹏飞资源综合利用有限公司' },
      { id: 'c9', text: '山西鲲鹏氢能源科技有限公司' },
      { id: 'c10', text: '山西鹏飞智创汽车制造有限公司'},
      { id: 'c11', text: '山西华美投资有限公司' },
      { id: 'c12', text: '山西鹏飞申能氢能发展有限公司' },
      { id: 'c13', text: '山西盛和矿机新材料科技发展有限公司' },
      { id: 'c14', text: '山西美酒制造有限公司' },
      { id: 'c15', text: '山西鹏飞焦化绿色发展有限公司'},
      { id: 'c16', text: '山西普宏科技发展有限公司' },
      { id: 'c17', text: '山西郑好能源科技有限公司' },
      // { id: 'c18', text: '山西富鸿源能源科技有限公司' },
      // { id: 'c19', text: '孝义市鹏飞国宾友谊酒店有限公司' },
    ],
    lines: [
      { from: 'N2', to: 'root', text: '控股37.5%' ,lineShape: 2,},
      { from: 'N3', to: 'root', text: '控股37.5%' ,lineShape: 2,},
      { from: 'N4', to: 'root', text: '控股25%',lineShape: 2, },
      { from: 'root', to: 'c1', text: '控股100%' ,lineShape: 2,},
      { from: 'root', to: 'c2', text: '控股30%' ,lineShape: 2,},
      { from: 'root', to: 'c3', text: '控股80%' ,lineShape: 2,},
      { from: 'root', to: 'c4', text: '控股100%',lineShape: 2, },
      { from: 'root', to: 'c5', text: '控股51%' ,lineShape: 2,},
      { from: 'root', to: 'c6', text: '控股100%',lineShape: 2, },
      { from: 'root', to: 'c7', text: '控股51%' ,lineShape: 2,},
      { from: 'root', to: 'c8', text: '控股100%',lineShape: 2, },
      { from: 'root', to: 'c9', text: '控股51%' ,lineShape: 2,},
      { from: 'root', to: 'c10', text: '控股100%' ,lineShape: 2,},
      { from: 'root', to: 'c11', text: '控股100%',lineShape: 2, },
      { from: 'root', to: 'c12', text: '控股50%',lineShape: 2, },
      { from: 'root', to: 'c13', text: '控股51%' ,lineShape: 2,},
      { from: 'root', to: 'c14', text: '控股51%' ,lineShape: 2,},
      { from: 'root', to: 'c15', text: '控股100%' ,lineShape: 2,},
      { from: 'root', to: 'c16', text: '控股70%',lineShape: 2, },
      { from: 'root', to: 'c17', text: '控股100%',lineShape: 2, },
      { from: 'root', to: 'c18', text: '控股100%',lineShape: 2, },
      { from: 'root', to: 'c19', text: '控股55%',lineShape: 2, },
    ],
  };
  const graphInstance = graphRef.value?.getInstance();
  if (graphInstance) {
    await graphInstance.setJsonData(__graph_json_data);
    const leftNodes: RGNode[] = [];
    for (const node of graphInstance.getNodes()) {
      if (node.lot && node.lot.level! < 0) {
        node.color = '#2091f6';
        leftNodes.push(node);
      }
    }
    const leftLines = graphInstance
      .getLinks()
      .filter((link) => leftNodes.includes(link.fromNode) || leftNodes.includes(link.toNode))
      .reduce((currentLines: RGLine[], link: RGLink) => currentLines.concat(...link.relations), []);
    for (const line of leftLines) {
      line.placeText = 'start';
    }
  }
};

onMounted(() => {
  resetGraph();
});
</script>

<style scoped></style>
