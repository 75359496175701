<template>
  <el-col :span="22" :offset="1" class="littleContainer">
    <el-empty description="暂无数据" />
  </el-col>
</template>

<script lang="ts" setup></script>

<style scoped>
.littleContainer {
  border: 0.1px solid;
  border-color: #d4e6f6;
  border-radius: 10px;
}
</style>