<template>
  <el-col :span="22" :offset="1" class="littleContainer" style="padding: 3px;">
    <el-descriptions  :column="2">
      <el-descriptions-item label="企业名称:">内蒙古圣圆氢能源科技有限公司 </el-descriptions-item>
      <el-descriptions-item label="统一社会编码:">91150627MA0R555M4Y</el-descriptions-item>
      <el-descriptions-item label="注册资本:">2000万人民币</el-descriptions-item>

      <el-descriptions-item label="归属企业:">内蒙古圣圆能源集团有限责任公司</el-descriptions-item>
      <el-descriptions-item label="企业类型:">有限公司 </el-descriptions-item>
      <el-descriptions-item label="成立日期:">2021-03-25</el-descriptions-item>
      <el-descriptions-item label="氢能产业环节:">无</el-descriptions-item>
      <el-descriptions-item label="氢能产业环节细分:">无</el-descriptions-item>
      <el-descriptions-item label="技术装备:">无</el-descriptions-item>
      <el-descriptions-item label="地址:"
      >内蒙古自治区鄂尔多斯市伊金霍洛旗阿镇水岸金钻大厦西塔12楼1219</el-descriptions-item
    >
    </el-descriptions>
    <el-descriptions >
      <el-descriptions-item label="基本信息:">
        内蒙古圣圆氢能源科技有限公司成立于2021年03月25日，注册地位于内蒙古自治区鄂尔多斯市伊金霍洛旗阿镇水岸金钻大厦西塔12楼1219，法定代表人为薛峰。
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions >
      <el-descriptions-item label="经营范围:">
        燃气经营；道路危险货物运输；道路货物运输（不含危险货物）；燃气汽车加气经营；酒类经营；移动式压力容器/气瓶充装。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）一般项目：新兴能源技术研发；太阳能发电技术服务；风电场相关系统研发；集中式快速充电站；洗车服务；日用化学产品销售；润滑油销售；站用加氢及储氢设施销售；食品销售（仅销售预包装食品）；储能技术服务。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）
      </el-descriptions-item>
    </el-descriptions>
  </el-col>
</template>

<script lang="ts" setup></script>

<style scoped>
.littleContainer {
  border: 0.1px solid;
  border-color: #d4e6f6;
  border-radius: 10px;
}

</style>
