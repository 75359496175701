<template>
  <el-col :span="22" :offset="1">
  <el-table stripe :data="newsList" empty-text="暂无数据" no-data border max-height="500" style="width: 100%; font-size: 11px;">
    <template slot="empty">
      <p class="emptyText"> 暂无数据 </p>
      <!-- <el-button type="primary" @click="handleAdd" style="margin-bottom: 35px">添加用户</el-button> -->
   </template>
    <el-table-column align="center" label="序号" prop="newsId" width="50" />
    <el-table-column align="center" label="资讯标题"  prop="newsTitle">
      <template #default="{ row }">
        <span class="blue-text">{{ row.newsTitle }}</span>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      label="发布时间"
      prop="newsPublishAt"
    />
    <el-table-column align="center" label="资讯概要" prop="newsSummary" />
  </el-table>
  </el-col>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';

const newsList = ref([
  {
    newsId: '1',
    newsTitle: '锚定新航向 山西孝义文旅融合发展 氢能引领新质生产力蓬勃发展',
    newsPublishAt: '2024年8月7日',
    newsSummary: '锚定新航向 山西孝义文旅融合发展 氢能引领新质生产力蓬勃发展',
  },
  {
    newsId: '2',
    newsTitle: '以“新”应变,山西工业绿色动能竞相迸发',
    newsPublishAt: '2024年8月13日',
    newsSummary: '以“新”应变,山西工业绿色动能竞相迸发',
  },
  {
    newsId: '3',
    newsTitle: '山西:能源大省的“绿色气象”',
    newsPublishAt: '2024年8月1日',
    newsSummary: '山西:能源大省的“绿色气象”',
  },
  {
    newsId: '4',
    newsTitle: '中国日报看山西|煤炭大省推动氢能产业发展',
    newsPublishAt: '2024年7月30日',
    newsSummary: '中国日报看山西|煤炭大省推动氢能产业发展',
  },
  {
    newsId: '5',
    newsTitle: '拓展氢能朋友圈,山西孝义氢能自行车投入“全国文明城市”创建',
    newsPublishAt: '2024年7月13日',
    newsSummary: '拓展氢能朋友圈,山西孝义氢能自行车投入“全国文明城市”创建',
  },
  {
    newsId: '6',
    newsTitle: '中央媒体关注山西 | 山西沁源打造“绿色氢城”',
    newsPublishAt: '2024年7月4日',
    newsSummary: '中央媒体关注山西 | 山西沁源打造“绿色氢城”',
  },
  {
    newsId: '7',
    newsTitle: '山西孝义首批氢能共享单车、氢能公交投运',
    newsPublishAt: '2024年4月30日',
    newsSummary: '山西孝义首批氢能共享单车、氢能公交投运',
  },
  {
    newsId: '8',
    newsTitle: '让氢能重卡驰向更加广阔的天地——山西鹏飞集团打造零碳物流先锋的实践',
    newsPublishAt: '2024年6月7日',
    newsSummary: '让氢能重卡驰向更加广阔的天地——山西鹏飞集团打造零碳物流先锋的实践',
  },
  {
    newsId: '9',
    newsTitle: '山西鹏飞集团获得500台49吨氢能重卡项目订单',
    newsPublishAt: '2024年4月25日',
    newsSummary: '山西鹏飞集团获得500台49吨氢能重卡项目订单',
  },
  {
    newsId: '10',
    newsTitle: '山西鹏飞集团首个氢能领域国家重点研发计划项目获批立项',
    newsPublishAt: '2024年1月31日',
    newsSummary: '山西鹏飞集团首个氢能领域国家重点研发计划项目获批立项',
  },
]);

const currentPage = ref(1);
const pageSize = ref(10);


const total = ref(newsList.value.length);


// 计算属性，用于分页显示的数据
const pagedNewsList = computed(() => {
  const startIndex = (currentPage.value - 1) * pageSize.value;
  const endIndex = startIndex + pageSize.value;
  return newsList.value.slice(startIndex, endIndex);
});

// 方法处理分页变化
const handleSizeChange = (val: number) => {
  pageSize.value = val;
  currentPage.value = 1; // 页数重置为1
};

const handleCurrentChange = (val: number) => {
  currentPage.value = val;
};

onMounted(() => {
});

</script>

<style scoped>
.blue-text {
  color: blue;
}
</style>
