<template>
  <el-row style="height: 10%;">
    <el-col :span="6">
      资讯标题：<el-input
        v-model="searchedNewsTitle"
        placeholder="请输入资讯标题"
        style="width: 180px"
      />
    </el-col>
    <el-col :span="14">
      爬取时间：<el-date-picker
        v-model="startScrapeAt"
        type="datetime"
        placeholder="开始时间"
        :shortcuts="shortcuts"
      ></el-date-picker>
      {{ ' 至  '
      }}<el-date-picker
        v-model="endScrapeAt"
        type="datetime"
        placeholder="结束时间"
        :shortcuts="shortcuts"
      ></el-date-picker>
    </el-col>
    <el-col :span="4">
      <el-button type="primary" @click="searchNews">筛选</el-button>
    </el-col>
  </el-row>
  <el-row style="height: 79%;">
    <el-col :span="24" style="height: 100%;">
      <el-table
        stripe
        :data="newsList"
        highlight-current-row
        border
        style="height: 100%;"
        size="small"
      >
        <el-table-column label="资讯ID" prop="newsId" width="50" />
        <el-table-column label="新闻标题" align="center" prop="title" width="180" />
        <el-table-column label="爬取时间" align="center" sortable prop="scrapeAt" width="120" />
        <el-table-column label="新闻来源" align="center" prop="source" width="150" />
        <el-table-column label="省份" align="center" prop="province" width="50" />
        <el-table-column label="审核状态" align="center" width="60">
          <template v-slot="{ row }">
            <!-- 使用三元运算符判断并显示不同的文本 -->
            {{ row.status === 0 ? '未审核' : '已审核' }}
          </template>
        </el-table-column>
        <el-table-column label="产业环节" align="center" width="60">
          <template v-slot="{ row }">
            <!-- 使用三元运算符判断并显示不同的文本 -->
            {{ row.type === '' ? '-' : row.type }}
          </template>
        </el-table-column>
        <el-table-column label="关联项目" align="center" width="160">
          <template v-slot="{ row }">
            <div>
              <!-- 显示第一个项目 -->
              <el-tooltip
                :content="
                  row.relatedProjects && row.relatedProjects[0]
                    ? row.relatedProjects[0].title
                    : '暂无'
                "
                placement="top"
              >
                <el-tag
                  style="
                    font-size: 10px;
                    max-width: 140px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  v-if="row.relatedProjects && row.relatedProjects.length > 0"
                  size="small"
                  effect="plain"
                  disable-transitions
                >
                  {{
                    row.relatedProjects && row.relatedProjects[0]
                      ? row.relatedProjects[0].title
                      : '暂无'
                  }}
                </el-tag>
              </el-tooltip>

              <!-- 如果项目多于1个，则显示展示全部的按钮 -->
            </div>
            <el-button
              type="primary"
              style="font-size: 10px; height: 12px; width: 50px"
              v-if="row.relatedProjects.length > 1"
              @click="showAllProjects(row)"
            >
              展示全部
            </el-button>
          </template>
        </el-table-column>

        <el-table-column label="关联企业" align="center" width="160">
          <template v-slot="{ row }">
            <div>
              <!-- 显示第一个企业 -->
              <el-tooltip
                :content="
                  row.relatedCompanies && row.relatedCompanies[0]
                    ? row.relatedCompanies[0].title
                    : '暂无'
                "
                placement="top"
              >
                <el-tag
                  style="font-size: 10px"
                  v-if="row.relatedCompanies && row.relatedCompanies.length > 0"
                  size="small"
                  effect="plain"
                  disable-transitions
                >
                  {{
                    row.relatedCompanies && row.relatedCompanies[0]
                      ? row.relatedCompanies[0].title
                      : '暂无'
                  }}
                </el-tag>
              </el-tooltip>
              <!-- 如果企业多于1个，则显示展示全部的按钮 -->
            </div>
            <el-button
              type="primary"
              style="font-size: 10px; height: 12px; width: 50px"
              v-if="row.relatedCompanies.length > 1"
              @click="showAllCompanies(row)"
            >
              展示全部
            </el-button>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" min-width="150">
          <template #default>
            <el-button
              type="primary"
              style="width: 30px; height: 15px; font-size: 10px"
              @click=""
              disabled
            >
              编辑
            </el-button>
            <el-button
              type="warning"
              style="width: 30px; height: 15px; font-size: 10px"
              @click=""
              disabled
            >
              删除
            </el-button>
            <el-button
              type="info"
              style="width: 30px; height: 15px; font-size: 10px"
              @click="publish"
            >
              入库
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
  <el-row style="height: 7%; margin-top: 2%">
    <el-col :span="10" :offset="7">
      <el-pagination
        background
        layout="prev, pager, next,"
        :total="totalNewsCount"
        v-model:current-page="currentPage"
        @update:current-page="handlePageChange"
      />
    </el-col>
  </el-row>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import { get } from '@/utils/request';
import { ElMessageBox } from 'element-plus';

const searchedNewsTitle = ref('');

const currentPage = ref(1);
const totalNewsCount = ref(0);

function publish() {
  // 弹窗没有设置企业类型不允许发布
  ElMessageBox.alert('请先设置产业环节，才能进行发布操作。', '提示', {
    confirmButtonText: '确定',
    callback: (action) => {
      console.log(`action: ${action}`);
    },
  });
}

function showAllProjects(row) {}

function showAllCompanies(row) {}

const shortcuts = [
  {
    text: '此刻',
    value: new Date(),
  },
  {
    text: '昨天此刻',
    value: () => {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      return date;
    },
  },
  {
    text: '一周前此刻',
    value: () => {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      return date;
    },
  },
];

const endScrapeAt = ref();
const startScrapeAt = ref();

const newsList = ref([]);

function handlePageChange() {
  getNewses();
}

function searchNews() {
  console.log(searchedNewsTitle.value);
  console.log(endScrapeAt.value);
  console.log(startScrapeAt.value);
  getNewses();
}

onMounted(() => {
  getNewses();
});

function getNewses() {
  get('/admin/news/getUnpublishedNewses', {
    newsTitle: searchedNewsTitle.value,
    startScrapeAt: formatDate(startScrapeAt.value),
    endScrapeAt: formatDate(endScrapeAt.value),
    currentPage: currentPage.value,
  }).then((res: any) => {
    newsList.value = res.data.newses;
    totalNewsCount.value = res.data.total;
  });
}

function formatDate(d) {
  if (!d) return '';
  return `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(
    d.getHours(),
  )}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
}

function pad(num) {
  return num.toString().padStart(2, '0');
}
</script>

<style scoped>
.el-table__body tr {
  height: auto; /* 允许行高自动调整 */
}

.el-table__row {
  line-height: normal; /* 清除默认的行高 */
}

/* 或者指定一个更小的行高 */
.el-table__row {
  line-height: 1.2em;
}

/* 如果你想进一步控制单元格内的文本对齐和间距 */
.el-table td,
.el-table th {
  padding: 4px 0; /* 减少内边距 */
  vertical-align: middle; /* 文本垂直居中 */
}

.el-table {
  font-size: 10px;
}
</style>
