<template>
    <el-container class="layout-container">
      <my-aside></my-aside>
      <el-main style="height: 100vh;">
        <el-row style="height: 10%;width: 100%;">
            <el-col :span="6" style="display: flex; flex-direction: row;justify-content: center; align-items: center;">
            <img src="../assets/basic-icons/rocket_blue.png" style="height: 35px;  margin-right: 5px;"/>
            <span style="font-size: 30px; color: #2091F6;"> 我的项目 </span>
            </el-col>
            <el-col :span="5" :offset="13" style=" display: flex; flex-direction: row; justify-content: center; align-items: center;">
            <el-button @click="createProject" style="height: 90%; font-size:150%; width: 100%; border: 2px solid #2091F6;">
                <img src="../assets/basic-icons/plus.png" style="height: 28px; margin-right: 5px;"/>
                <span style="color: #2091F6;"> 创建新项目</span>
                </el-button>
            </el-col>
        </el-row>
        <el-divider style="height: 2%;"/>
        <div style="display: flex; flex-direction: column; justify-content: space-around; align-items: center;">
            <el-table :data="projectsTable" @row-click="handleRowClick" style="width: 100%; height: 80%;">
            <el-table-column fixed label="项目名称" prop="projectName" width="180" />
            <el-table-column label="省份" sortable prop="projectProvince" width="180" />
            <el-table-column label="优化目标" sortable prop="target" width="180" />
            <el-table-column label="下游产品" sortable prop="product" width="180" />
            <el-table-column label="创建时间" sortable prop="createAt" width="180" />
            <el-table-column fixed="right" label="操作" width="380">
              <template #default="{ row }">
                <!-- 将查看曲线的点击事件更改为行点击处理 -->
                <el-button size="small" @click="() =>editProject(row.projectUuid)" >
                  编辑
                </el-button>
                <el-button size="small" disabled >
                    复制
                </el-button>
                <el-button size="small" disabled>
                    协作
                </el-button>
                <el-button size="small" disabled>
                    历史
                </el-button>
                <el-button size="small" disabled>
                  导出
                </el-button>
                <el-button size="small" type="danger" @click="() => deleteProject(row.projectUuid)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background layout="prev, pager, next" :total="10" style="margin-top: 20px;" />
        </div>
      </el-main>
    </el-container>
  </template>
  
  <script lang="ts" setup>
    import { ElButton, ElMessage } from 'element-plus'
    import {ref, onMounted} from 'vue'
    import { get, post } from '@/utils/request'
    import MyAside from '../components/Aside.vue'

    onMounted(() => {
      get('/projects', {
      }).then((res: any) => {
        projectsTable.value = res.data
        console.log(res.data);
      });
    })

    const projectsTable = ref([]);

    const handleRowClick = (row: { curveId: string; }) => {

    };

    function editProject(projectUuid: string) {
      window.location.href = '/project/edit/tp/' + projectUuid;
    }


    function deleteProject(projectUuid: string) {
        post('/project/delete', {
          projectUuid: projectUuid
        }).then((res: any) => {
            if (res.code === 200) {
              ElMessage.success('删除成功');
              get('/projects', {
              }).then((res: any) => {
                projectsTable.value = res.data
              });
            } else {
              ElMessage.error('删除失败');
            }
        });
    }

    function createProject() {
      post('/project/create', {
          
      }).then((res: any) => {
          if (res.code === 200) {
            ElMessage.success('创建成功');
            window.location.href = '/project/edit/np/' + res.data;
          } else {
            ElMessage.error('创建失败');
          }
      });
    }

  </script>
  <style scoped>

  .box-card {
    border: 1px solid #2091F6;
    border-radius: 30px;
    width: 30%;
    height: 300px;
  }
  </style>
    