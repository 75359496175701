
// 首先确保已经安装了axios：npm install axios

// 在你的项目中创建一个文件，例如：src/utils/request.js
import axios from 'axios';

// 创建并配置axios实例（可添加如 baseURL、timeout、headers 等全局配置）
const instance = axios.create({
  baseURL: 'https://hsnc.lxjwzl.cn', // 这里填写你的API基础地址
  // baseURL: 'http://localhost:6970',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 发送请求前拦截
instance.interceptors.request.use(
  config => {
    // 新增: 检查是否为外部URL请求,以http开头
    if (config.url.startsWith('http') || config.url == '/account/login' || config.url == '/account/send-code' || config.url == '/account/register' || config.url.startsWith('/admin')) {
      console.log('直接放行，无需登录')
    } else {
      const token = getToken();
      if (token) {
        config.headers.token = token;
      } else {
        window.location.href = '/login';
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export const getToken = () => {
  const token = localStorage.getItem('token')
  const expiresAt = parseInt(localStorage.getItem('tokenExpiresAt'))

  if (token && Date.now() < expiresAt) {
      return token
  }
  // 清除已过期的token
  localStorage.removeItem('token')
  localStorage.removeItem('tokenExpiresAt')
  return null
}

// 响应拦截器 - 处理错误或统一处理返回结果格式
instance.interceptors.response.use(
  (response) => response.data, // 如果后端返回的数据直接是data属性，则提取出来
  // (error) => {
  //   // 抛出一个自定义错误，以便在Vue Router守卫中捕获
  //   window.location.href = '/not-found'
  // },
);

// 封装GET请求
export async function get(url, params = {}, isExternal = false) {
  // 如果是外部请求，不添加token
  const config = isExternal ? {} : { headers: { token: getToken() } };
  return instance.get(url, { params, ...config });
}

// 封装POST请求
export async function post(url, data = {}, isExternal = false) {
  const config = isExternal ? {} : { headers: { token: getToken() } };
  return instance.post(url, data, config);
}
// 封装PUT请求
export async function put(url, data = {}) {
  return instance.put(url, data);
}

// 可选地，还可以封装一个通用请求方法
export async function request(method, url, data = {}, params = {}) {
  const config = method.toLowerCase() === 'get' ? { params } : { data };
  return instance.request({ method, url, ...config });
}