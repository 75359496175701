<template>
  <el-row class="littleContainer" style="width: 90%; margin-left:5%">
    <el-col :span="10" :offset="1" style="margin-top: 20px; margin-bottom: 20px">
      <div class="citySubTitle">专利类型</div>
    </el-col>
    <el-col :span="10" :offset="1" style="margin-top: 20px; margin-bottom: 20px">
      <div style="margin: 0; width: 8vw; font-size: medium; font-weight: 550">涉氢专利类型</div>
    </el-col>
    <el-col :span="10" :offset="2">
      <div class="pieChart" id="fourthChart" style="height: 350px; width: 350px"></div>
    </el-col>
    <el-col :span="10" :offset="2">
      <div class="pieChart" id="fifthChart" style="height: 350px; width: 350px"></div>
    </el-col>
    <el-col :span="23" :offset="1" style="margin-top: 20px; margin-bottom: 20px">
      <div class="citySubTitle">近5年专利申请情况</div>
    </el-col>
    <el-col :span="23" :offset="1">
      <div class="barChart" id="sixthChart" style="height: 250px; width: 95%"></div>
    </el-col>
    </el-row>
    <el-col :span="24" style="height: 20px"></el-col>
    <el-col :span="23" :offset="1">
      <div class="citySubTitle">专利列表</div>
    </el-col>

    <el-col :span="24" style="height: 20px"></el-col>
    <el-col
      :span="22"
      :offset="1"
    >
      <el-table stripe :data="patentList" border max-height="500" style="width: 100%; font-size: 11px;">
        <el-table-column align="center" label="序号" prop="patentId" width="70" />
        <el-table-column align="center" label="专利名称"  prop="patentName"    >
          <template #default="{ row }">
            <span class="blue-text">{{ row.patentName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="专利类型"  prop="patentKind" />
        <el-table-column align="center" label="专利号"  prop="patentUuid" />
        <el-table-column
          align="center"
          label="专利涉氢类型"
          
          prop="patentH2Kind"
        />
        <el-table-column
          align="center"
          label="细分领域"
          
          prop="patentDirection"
        />
        <el-table-column
          align="center"
          label="申请时间"
          
          prop="patentApplyAt"
        />
      </el-table>
    </el-col>
</template>

<script lang="ts" setup>
import { get } from '@/utils/request';
import { ref, onMounted, getCurrentInstance } from 'vue';
let internalInstance = getCurrentInstance();

let echarts = internalInstance.appContext.config.globalProperties.$echarts;

const patentList = ref([
  {
    patentId: 1,
    patentName: '专利名称1',
    patentKind: '发明新型专利',
    patentUuid: 'qnzl001',
    patentH2Kind: '氢能加注',
    patentDirection: '站控系统',
    patentApplyAt: '2023年2月',
  },
  {
    patentId: 2,
    patentName: '专利名称2',
    patentKind: '发明新型专利',
    patentUuid: 'qnzl002',
    patentH2Kind: '氢能加注',
    patentDirection: '站控系统',
    patentApplyAt: '2023年3月',
  },
  {
    patentId: 3,
    patentName: '专利名称3',
    patentKind: '发明新型专利',
    patentUuid: 'qnzl003',
    patentH2Kind: '氢能加注',
    patentDirection: '站控系统',
    patentApplyAt: '2023年4月',
  },
  {
    patentId: 4,
    patentName: '专利名称4',
    patentKind: '发明新型专利',
    patentUuid: 'qnzl004',
    patentH2Kind: '氢能加注',
    patentDirection: '站控系统',
    patentApplyAt: '2023年5月',
  },
  {
    patentId: 5,
    patentName: '专利名称5',
    patentKind: '发明新型专利',
    patentUuid: 'qnzl005',
    patentH2Kind: '氢能加注',
    patentDirection: '站控系统',
    patentApplyAt: '2023年6月',
  },
  {
    patentId: 6,
    patentName: '专利名称6',
    patentKind: '发明新型专利',
    patentUuid: 'qnzl006',
    patentH2Kind: '氢能加注',
    patentDirection: '站控系统',
    patentApplyAt: '2023年7月',
  },
  {
    patentId: 7,
    patentName: '专利名称7',
    patentKind: '发明新型专利',
    patentUuid: 'qnzl007',
    patentH2Kind: '氢能加注',
    patentDirection: '站控系统',
    patentApplyAt: '2023年8月',
  },
  {
    patentId: 8,
    patentName: '专利名称8',
    patentKind: '外观设计',
    patentUuid: 'qnzl008',
    patentH2Kind: '氢能应用',
    patentDirection: '站控系统',
    patentApplyAt: '2023年9月',
  },
  {
    patentId: 9,
    patentName: '专利名称9',
    patentKind: '外观设计',
    patentUuid: 'qnzl009',
    patentH2Kind: '氢能应用',
    patentDirection: '站控系统',
    patentApplyAt: '2023年10月',
  },
  {
    patentId: 10,
    patentName: '专利名称10',
    patentKind: '外观设计',
    patentUuid: 'qnzl010',
    patentH2Kind: '氢能应用',
    patentDirection: '站控系统',
    patentApplyAt: '2023年11月',
  },
]);

onMounted(() => {
  initCharts();
});

function initCharts() {
  var chartDom4 = document.getElementById('fourthChart');
  var chart4 = echarts.init(chartDom4);
  const option4 = {
    legend: {
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      left: 'center', // 可选，如果希望水平居中显示图例
      top: 'top', // 图例的位置，这里设置在底部
    },
    series: {
      name: '访问来源',
      type: 'pie',
      radius: '50%', // 半径大小
      data: [
        {
          value: 2,
          name: '发明新型专利',
          itemStyle: { color: '#87CEFA' }, // 轻蓝
        },
        {
          value: 5,
          name: '实用新型专利',
          itemStyle: { color: '#4682B4' }, // 中蓝
        },
        {
          value: 3,
          name: '外观设计',
          itemStyle: { color: '#1E90FF' }, // 宝石蓝
        },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'outside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  chart4.setOption(option4);

  var chartDom5 = document.getElementById('fifthChart');
  var chart5 = echarts.init(chartDom5);
  const option5 = {
    // 添加图例配置
    legend: {
      orient: 'horizontal', // 图例布局方向，这里是水平布局
      top: 'top', // 图例的位置，这里设置在底部
      left: 'center', // 可选，如果希望水平居中显示图例
    },
    series: {
      name: '访问来源',
      type: 'pie',
      radius: '50%', // 半径大小
      data: [
        {
          value: 0,
          name: '氢能制取',
          itemStyle: { color: '#87CEFA' }, // 轻蓝
        },
        {
          value: 0,
          name: '氢能存储',
          itemStyle: { color: '#4682B4' }, // 中蓝
        },
        {
          value: 0,
          name: '氢能运输',
          itemStyle: { color: '#1E90FF' }, // 宝石蓝
        },
        {
          value: 7,
          name: '氢能加注',
          itemStyle: { color: '#00BFFF' }, // 深天蓝
        },
        {
          value: 3,
          name: '氢能应用',
          itemStyle: { color: '#3272B4' }, // 中蓝
        },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}', // 格式化显示内容，{b}代表名称，{c}代表数值
        position: 'outside', // 设置标签位置为扇区内部，可选其他位置如'outside'
      },
    },
  };
  chart5.setOption(option5);
}

</script>

<style scoped>
.littleContainer {
  border: 0.1px solid;
  border-color: #d4e6f6;
  border-radius: 10px;
}
.blue-text {
  color: blue;
}
</style>