<template>
  <el-col :span="22" :offset="1">
  <el-table stripe :data="newsList" border max-height="500" style="width: 100%; font-size: 11px;">
    <el-table-column align="center" label="序号" prop="newsId" width="50" />
    <el-table-column align="center" label="资讯标题"  prop="newsTitle">
      <template #default="{ row }">
        <span class="blue-text">{{ row.newsTitle }}</span>
      </template>
    </el-table-column>
    <el-table-column
      align="center"
      label="发布时间"
      prop="newsPublishAt"
    />
    <el-table-column align="center" label="资讯概要" prop="newsSummary" />
  </el-table>
  </el-col>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';

const newsList = ref([
  {
    newsId: '1',
    newsTitle: '内蒙古财政厅发布《关于提前下达2024年节能减排补助资金预算的通知》',
    newsPublishAt: '2023年2月1日',
    newsSummary: '内蒙古财政厅发布《关于提前下达2024年节能减排补助资金预算的通知》',
  },
  {
    newsId: '2',
    newsTitle: '远景能源年产152万吨的零碳氢氨项目明年6月投产',
    newsPublishAt: '2023年3月1日',
    newsSummary: '远景能源年产152万吨的零碳氢氨项目明年6月投产',
  },
  {
    newsId: '3',
    newsTitle: '霍林河“源-网-荷-储-用”创新示范项目PEM制氢系统成功试运行',
    newsPublishAt: '2023年2月1日',
    newsSummary: '霍林河“源-网-荷-储-用”创新示范项目PEM制氢系统成功试运行',
  },
  {
    newsId: '4',
    newsTitle: '亿利350万千瓦立体风光氢治沙制取航空燃料一体化示范项目签约',
    newsPublishAt: '2023年3月1日',
    newsSummary: '亿利350万千瓦立体风光氢治沙制取航空燃料一体化示范项目签约',
  },
  {
    newsId: '5',
    newsTitle: '内蒙古印发《新能源倍增行动实施方案的通知》',
    newsPublishAt: '2023年2月1日',
    newsSummary: '内蒙古印发《新能源倍增行动实施方案的通知》',
  },
  {
    newsId: '6',
    newsTitle: '全国首个芦竹生物质零碳产业园签约',
    newsPublishAt: '2023年3月1日',
    newsSummary: '全国首个芦竹生物质零碳产业园签约',
  },
  {
    newsId: '7',
    newsTitle: '内蒙古发布《内蒙古自治区风光制氢一体化项目实施细则2023年修订版（试行）》',
    newsPublishAt: '2023年2月1日',
    newsSummary: '内蒙古发布《内蒙古自治区风光制氢一体化项目实施细则2023年修订版（试行）》',
  },
  {
    newsId: '8',
    newsTitle: '大唐集团首个绿氢重点示范项目开工',
    newsPublishAt: '2023年3月1日',
    newsSummary: '大唐集团首个绿氢重点示范项目开工',
  },
]);

const currentPage = ref(1);
const pageSize = ref(10);


const total = ref(newsList.value.length);


// 计算属性，用于分页显示的数据
const pagedNewsList = computed(() => {
  const startIndex = (currentPage.value - 1) * pageSize.value;
  const endIndex = startIndex + pageSize.value;
  return newsList.value.slice(startIndex, endIndex);
});

// 方法处理分页变化
const handleSizeChange = (val: number) => {
  pageSize.value = val;
  currentPage.value = 1; // 页数重置为1
};

const handleCurrentChange = (val: number) => {
  currentPage.value = val;
};

onMounted(() => {
});

</script>

<style scoped>
.blue-text {
  color: blue;
}
</style>
