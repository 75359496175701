<template>
    <el-header class="home-header" style="width: 100%; background-color: #316CCB;">
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          background-color="#316CCB"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
        <el-menu-item index="0">
            <img :src="require('@/assets/subject/title.png')" style="height: 60px; margin-left: 50px;"/>
        </el-menu-item>
        <el-menu-item index="1" @click="" style="width: 110px; font-weight: 600">首页</el-menu-item>
        <el-menu-item index="2" @click="" style="width: 110px; font-weight: 600">资讯</el-menu-item>
        <el-menu-item index="3" @click="" style="width: 110px; font-weight: 600">产业</el-menu-item>
        <el-menu-item index="4" @click="" style="width: 110px; font-weight: 600">数据</el-menu-item>
        <el-menu-item index="5" @click="" style="width: 110px; font-weight: 600">城市</el-menu-item>
        <el-menu-item index="6" @click="" style="width: 110px; font-weight: 600">专题</el-menu-item>
        <el-divider direction="vertical" style="height: 30px; margin-top:15px" />
        <el-menu-item ><el-button class="contact-btn" style="border-radius: 5px; height: 28px;" @click="$router.push('/login')">登录</el-button></el-menu-item>
    </el-menu>
    </el-header>
    <div style="width: 100%; background-color: rgb(99, 158, 253); height: 300px; display: flex; flex-direction: row; justify-content: center; align-items: center;">
        <!-- <h1 style="font-size: 60px; color: #fff; margin: 0">氢 能 产 业 研 究 报 告</h1> -->
        <div style="width: 75%; text-align:left; align-items:left; margin-left:5%; height:300px">
            <h1 style="color: white; font-size:50px;margin-top:100px">中国2030年“可再生氢100”发展路线图</h1>
        </div>
        <div style="width: 20%;height:300px">
        </div>
    </div>
    <el-row style="width:100%; padding: 20px;">
       
        <el-divider style="width: 90%; margin-left: 5%"/>
        <div style="width: 95%; text-align: left; margin-left:5%">
            <h3>整体摘要</h3>
        </div>
        <el-col :span="11" :offset="1" style="margin-top: 0px; height:500px">
            <el-text line-clamp="" style="text-align: left;font-size:16px" v-html="formattedText">
            </el-text>
        </el-col>
        <el-col :span="12" style="height:380px">
            <img :src="require('@/assets/subject/first.png')" style="height: 100%;"/>
        </el-col>
        <el-divider style="width: 90%; margin-left: 5%"/>


        <div style="width: 95%; text-align: left; margin-left:5%">
            <h3>主要结论</h3>
        </div>
        <el-col :span="11" :offset="1" style="margin-top: 40px; height:250px">
            <el-text line-clamp="" style="text-align: left;font-size:16px" v-html="firstText">
            </el-text>
        </el-col>
        <el-col :span="12" style="height:390px">
            <img :src="require('@/assets/subject/second.png')" style="height: 100%;"/>
        </el-col>
        <el-divider style="width: 90%; margin-left: 5%"/>

        
        <div style="width: 95%; text-align: left; margin-left:5%">
            <h3>可再生氢100倡议</h3>
        </div>
        <el-col :span="11" :offset="1" style="margin-top: 20px; height:180px">
            <el-text line-clamp="" style="text-align: left;font-size:16px" v-html="secondText">
            </el-text>
        </el-col>
        <el-col :span="12" style="height:200px">
            <img :src="require('@/assets/subject/third.png')" style="height: 150px;"/>
        </el-col>
        <el-divider style="width: 90%; margin-left: 5%"/>
        <el-row style="width:90%;margin-left:5%; background-color: rgb(179, 228, 255);border-radius: 10px">
            <el-col :span="7" :offset="5" style=""> 
                <div style="width: 45%; margin:0; height:400px">
                    <img :src="require('@/assets/subject/RH100.png')" style="height: 90%;margin-top: 10%;"/>
                </div>
            </el-col>
            <el-col :span="10" > 
                <h2 style="margin-top:30%;" >中国2030年“可再生氢100”发展路线图</h2>
                <el-button style="margin-top:10%" type="primary" @click="jumpComplete">
                    查看完整内容
                </el-button>
            </el-col>
            <el-col :span="2" >
            </el-col>
        </el-row>
        <el-divider style="width: 90%; margin-left: 5%"/>
        <div style="width: 95%; text-align: left; margin-left:5%">
            <h3>其他推荐</h3>
        </div>
        <el-col :span="7" :offset="1" style="margin-top: 10px; height:320px">
            <a href="https://www.chinah2data.com/file/bigdata-docs/8c6141e9f81b1a73e906dc3bbed845de.pdf" target="_blank">
                <img :src="require('@/assets/subject/report1.png')" style="height: 100%;" />
              </a>
              <div>投融资报告2023</div>
        </el-col>
        <el-col :span="7" :offset="1" style="margin-top: 10px; height:320px">
            <a href="https://www.chinah2data.com/file/bigdata-docs/%E4%B8%96%E7%95%8C%E6%96%B0%E5%85%B4%E6%B0%A2%E7%BB%8F%E6%B5%8E%E6%B4%9E%E5%AF%9F%E6%8A%A5%E5%91%8A.pdf_9a3e7836485b4fceb8fe8b986c755571.pdf" target="_blank">
                <img :src="require('@/assets/subject/report2.png')" style="height: 100%;" />
            </a>
            <div>氢谷</div>
        </el-col>
        <el-col :span="7" :offset="1" style="margin-top: 10px; height:320px">
            <a href="https://www.chinah2data.com/file/group1/M00/00/00/wKgADmB2ZSaAGqS1AhH6Nq1varE474.pdf" target="_blank">
                <img :src="require('@/assets/subject/report3.png')" style="height: 100%;" />
            </a>
            <div>氢能平价之路</div>
        </el-col>
        <el-col :span="3" :offset="21" style="margin-top: 50px; height:20px">
            <a href="https://www.chinah2data.com/#/client/database/ReportBaseList" target="_blank" style="color: #409EFF; cursor: pointer;">查看更多报告 >></a>
        </el-col>
        <el-divider style="width: 90%; margin-left: 5%"/>
    </el-row>

</template>


<script setup>
import { ref, computed } from 'vue';

function jumpComplete() {
    const url = 'https://www.chinah2data.com/file/bigdata-docs/%E4%B8%AD%E5%9B%BD2030%E5%B9%B4%E5%8F%AF%E5%86%8D%E7%94%9F%E6%B0%A2100%E5%8F%91%E5%B1%95%E8%B7%AF%E7%BA%BF%E5%9B%BE.pdf_fac262aa1ddb41e3ad87d0a391ab64d6.pdf';
    window.open(url, '_blank'); // _blank 表示在新标签页中打开
}

// 计算属性处理文本
const formattedText = 
"&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp在能源转型和“双碳”的大背景下，中国已经全面启动了能源供给结构和消费需求向清洁化、低碳化和安全化的深度转型行动，并开始着力构建以可再生能源为核心的新型零碳能源体系。其中，除了可再生电力的大规模应用之外，以可再生氢为代表的新型清洁能源同样将在石油化工、钢铁、重型道路交通和船运航空等难以进行电气化转型行业的脱碳路径中发挥不可替代的作用。从2018年开始,多个国家政策部门逐步明确了氢能在产业转型中的重要地位，各地方也陆续将氢能及其产业链作为布局发展的重点纳入了阶段性规划。2022 年 3 月，《氢能产业发展中长期规划（2021-2035）》的正式发布，更是进一步强调了以可再生能源制氢为核心的氢能发展方向，为氢能产业的长远发展奠定了坚实的基础。<br />" +
"&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp在此基础上，为了更好地推动《氢能产业发展中长期规划（2021-2035）》的落实，本研究立足于中国当前氢能产业发展现状，以2060年碳中和情景下氢能和可再生氢的需求和供给规模为基础，充分结合产业发展规律，提出了2030年可再生氢装机达到100GW的目标，并根据“区域为主”统筹发展、“大基地”规模化开发和“先立后破”逐步替代等近中期可再生氢发展的主要特征，对化工、钢铁和交通这三大氢能重点消费行业2030年可再生氢消费量以及东北、华北、西北、华中、华东、华南和西南等七大区域可再生氢的生产进行了分析和梳理，提出了分行业和区域的引导性目标。<br />" +
"&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp在搭建模型进行分析的过程中，本研究充分考量了各行业2030年之前的产能需求变化、可再生氢的技术和成本以及不同区域可再生资源禀赋等条件，对各行业可再生氢的消费量需求以及各区域的产量、装机量、重点发展行业和可再生氢的来源进行了研判，并以此为基础，结合《氢能产业发展中长期规划（2021-2035）》提出了推动可再生氢产业发展的相关建议。"

const firstText = 
"<li/>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp中国各行业和区域具备2030年可再生氢装机至少达到100GW的潜力，且2030年100GW可再生氢装机是实现2060年碳中和目标的重要基石；<br>"
+
"<li/>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp2030年之前，中国可再生氢的消费需求增长主要存在于化工行业对传统化石能源制氢的替代以及钢铁和交通行业新技术突破创造的新增需求；<br>"
+
"<li/>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp从区域的角度看，2030年可再生氢的生产将主要集中在可再生资源禀赋较好的西北地区和行业用氢需求较大的华北和华东地区；<br>"
+
"<li/>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp在完善分行业、分区域目标的基础上，通过强化“大基地”规模化示范降低产业链成本，同时完善各地方适合自身发展条件的可再生氢产业规划至关重要；<br>"
+
"<li/>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp中国整体氢能发展的战略布局，需要分阶段、分步骤，利用不同来源氢能的互补性，最大化氢能的减排效果，并为可再生氢打造更充分的发展基础。<br>"

const secondText = 
"&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp实现碳达峰、碳中和是中国向世界做出的庄严承诺，是以习近平同志为核心的党中央统筹国内国际两个大局和经济社会发展全局，推动生态文明建设和经济高质量发展，建设社会主义现代化强国做出的重大战略决策。在这场广泛而深刻的经济社会变革中，为统筹推进我国氢能产业绿色发展，助力以新能源为主体的新型电力系统建设，促进工业、交通等领域深度脱碳，2021年9月22日，中国氢能联盟发起《可再生氢100行动倡议》，力争到2030年实现国内可再生能源制氢装机规模达到100吉瓦。<br>"

const thirdText = 
"&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX、XXXX、XXXXXX、XXXXXXXXXXXXXXXXX、XXXXXXX、XXXXXXXXXX、XXXXXXXXXX、XXXXXXXX、XXXXXXX"

</script>

<style scoped>

.blur-overlay {
    width: 100%;
    height: 200px;
    margin-bottom: -300px;
    background: linear-gradient(rgba(255, 255, 255, 0.2) 30%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 1) 100%);
    /* 可以调整渐变比例 */
    z-index: 1;
}
</style>