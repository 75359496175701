<template>
  <el-container class="layout-container">
    <my-aside></my-aside>
    <el-main style="height: 100vh;display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <el-row style="height: 10%;width: 100%;">
        <el-col :span="6" style="display: flex; flex-direction: row;justify-content: center; align-items: center;">
          <img src="../assets/basic-icons/data-inside.png" style="height: 35px;  margin-right: 5px;" />
          <span style="font-size: 30px; color: #2091F6;">我的数据</span>
        </el-col>
        <el-col :span="5" :offset="13"
          style=" display: flex; flex-direction: row; justify-content: center; align-items: center;">
          <el-button style="height: 90%; font-size:150%; width: 100%; border: 2px solid #2091F6;">
            <img src="../assets/basic-icons/plus.png" style="height: 28px; margin-right: 5px;" />
            <span style="color: #2091F6;" @click="$router.push('/data/import')"> 导入新数据 </span>
          </el-button>
        </el-col>
      </el-row>
      <el-tabs style="height: 90%; width: 90%;">
        <el-tab-pane label="风光曲线"
          style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <el-table :data="curvesTable" @row-click="handleRowClick" style="width: 100%; height: 80%;">
            <el-table-column fixed label="曲线名称" prop="curveName" width="180" />
            <el-table-column label="类别" sortable prop="energyType" width="180" />
            <el-table-column label="类型" sortable prop="hoursType" width="180" />
            <el-table-column label="地区" sortable prop="province" width="180" />
            <el-table-column label="年利用小时数" sortable prop="utilizationHours" width="180" />
            <el-table-column label="创建时间" sortable prop="createAt" width="180" />
            <el-table-column fixed="right" label="操作" width="300">
              <template #default="{ row }">
                <!-- 将查看曲线的点击事件更改为行点击处理 -->
                <el-button size="small" @click="() => getCurveData(row.curveId, row.curveName)">
                  查看曲线
                </el-button>
                <el-button size="small" type="danger">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background layout="prev, pager, next" :total="10" style="margin-top: 20px;" />
        </el-tab-pane>
        <el-tab-pane label="土地价格" disabled>
        </el-tab-pane>
        <el-tab-pane label="峰谷电价" disabled>
          开发中...
        </el-tab-pane>
      </el-tabs>
    </el-main>
    <el-dialog v-model="curveDialogVisible" :title="curveDialogName" width="500" center>
      <div class="chart" id="curveChart" style="height: 300px; width: 100%;"></div>
      <template #footer>
      </template>
    </el-dialog>
  </el-container>
</template>

<script lang="ts" setup>
import MyAside from '../components/Aside.vue'
import { onMounted, getCurrentInstance, ref } from 'vue'
let internalInstance = getCurrentInstance();
let echarts = internalInstance.appContext.config.globalProperties.$echarts;
import { get } from '@/utils/request'

const curveDialogVisible = ref(false)

const curveDialogName = ref('')

function showCurve(curveData: any) {
  curveDialogVisible.value = true
  setTimeout(() => { updateChart(curveData) }, 500)
}

const handleRowClick = (row: { curveId: string; }) => {

};

onMounted(() => {
  get('/data/curves', {
  }).then((res: any) => {
    curvesTable.value = res.data
  });
})

function getCurveData(curveId: string, name: string) {
  get('/data/curveData', {
    curveId: curveId,
  }).then((res: any) => {
    var curveData = JSON.parse(res.data)
    curveDialogName.value = name;
    showCurve(curveData);
  });
}

function updateChart(curveData: any) {
  var chartDom = document.getElementById("curveChart");
  var chart = echarts.init(chartDom);
  const option = {
    xAxis: {
      type: 'category',
      name: '日内时间',
      data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
      nameLocation: 'center',
      nameGap: 30
    },
    yAxis: {
      type: 'value',
      name: '风机满发比例',
      nameGap: 30
    },
    series: [
      {
        type: 'line',
        name: '',
        data: curveData,
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 2,
        },
      },
    ]
  };
  chart.setOption(option);
}

const curvesTable = ref([]);
</script>
